import React, { Component } from 'react';
import Layout from './components/Layout'
import './css/index.css';

class App extends Component {
  render() {
    return (
        <Layout/>
    );
  }
}

export default App;
