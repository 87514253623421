import React, { useEffect, useState } from 'react';
import io from 'socket.io-client'
import { USER_CONNECTED, LOGOUT } from '../Events'
import { esADMINISTRADOR } from '../Funciones';
import LoginForm from './login/LoginForm'
import MainContainer from './MainContainer'

const SocketUrl = "https://www.latinko.com"


export default function Layout(props){

	const [socket, setsocket ] = useState(null)
	const [user, setuser ] = useState(null)
	const [esADMIN, setEsADMIN ] = useState(false)

	useEffect(() =>{
		console.log('Esperando servidor...')
		IniciarSocket()
	},[])

	/*
	*	aqui nos conectamos e iniciamos el socket
	*/
	const IniciarSocket = ()=>{
		const socket = io(SocketUrl)

		socket.on('connect', ()=>{
			console.log("Servidor conectado!");
		})
		setsocket(socket)
	}
	const setUser = (user)=>{
		socket.emit(USER_CONNECTED, user);
		const esADM = esADMINISTRADOR(user)
		if(esADM == true){
			setEsADMIN(true)
		}else{
			setEsADMIN(false)
		}
	    setuser(user)
	}

	const logout = ()=>{
		socket.emit(LOGOUT)
		setuser(null)
		setEsADMIN(false)
	}

	const HandleEditarPasswordPerfilInfo = (data) => {
		const newperfilinfo = Object.assign([{}], [user])
		const PerfilinfoEditado = newperfilinfo.map((perfil) => {
			if (perfil.user === user.user) {
				perfil.password = data[0].password
			}
			return perfil
		})
		setuser(PerfilinfoEditado[0])
	}

	const BloquearDesbloquearUsuario = (data) => {
		const newperfilinfo = Object.assign([{}], [user])
		const PerfilinfoEditado = newperfilinfo.map((perfil) => {
			if (perfil.id === user.id && data.tipo === 'Bloquear') {
				perfil.state = 0
			}else if(perfil.id === user.id && data.tipo === 'Desbloquear'){
				perfil.state = 1
			}
			return perfil
		})
		setuser(PerfilinfoEditado[0])
	}

	const HandleEditarBusinessPerfilInfo = (data) => {
		const newperfilinfo = Object.assign([{}], [user])
		const PerfilinfoEditado = newperfilinfo.map((perfil) => {
			if (perfil.user === user.user) {
				perfil.businessName = data[0].businessName
			}
			return perfil
		})
		setuser(PerfilinfoEditado[0])
	}

	const EditarUsuario = (data) => {
		const newperfilinfo = Object.assign([{}], [user])
		const UsuarioInfoEditado = newperfilinfo.map((perfil) => {
			if (perfil.id === user.id) {
				perfil.businessName = data.businessName
				perfil.password = data.password
				perfil.autority = data.autority
				perfil.expiration = data.expiration
			}
			return perfil
		})
		if(data.autority == 5){
			setEsADMIN(true)
		}else{
			setEsADMIN(false)
		}
		setuser(UsuarioInfoEditado[0])
	}

	const HandleCambiarImagenPerfil = (newimage) => {
		const newperfilinfo = Object.assign([{}], [user])
		const UsuarioInfoEditado = newperfilinfo.map((perfil) => {
			if (perfil.id === user.id) {
				perfil.image = newimage
			}
			return perfil
		})
		setuser(UsuarioInfoEditado[0])
	}

		return (
			<div className="all_container">
				
				{
					!user ?
					<LoginForm 
					socket={socket} 
					setUser={setUser} 
					/>
					:
					<MainContainer 
					socket={socket}
					user={user}
					esADMIN={esADMIN}
					logout={logout}
					p_editarpasswordperfilinfo={HandleEditarPasswordPerfilInfo}
					p_editarbusinessperfilinfo={HandleEditarBusinessPerfilInfo}
					p_BloquearDesbloquearUsuario={BloquearDesbloquearUsuario}
					p_EditarUsuario={EditarUsuario}
					p_CambiarImagenPerfil={HandleCambiarImagenPerfil}
					/>
				}
			</div>
		);
}
