const { toString } = require('lodash');
const { v4: uuidv4 } = require('uuid');

const createUser = ({ user = "", socketId = null,  autority = "", businessName = "", state = ""} = {}) => (
	{
		id: uuidv4(),
		user,
		socketId,
		autority,
		businessName,
		state
	}
)

const createMessage = ({ message = "", sender = "" } = {}) => (
	{
		id: uuidv4(),
		time: Date.now(),
		message,
		sender
	}

)

const createChat = ({ messages = [], name = "Grupal", users = [] } = {}) => (
	{
		id: uuidv4(),
		name,
		messages,
		users,
		typingUsers: []
	}
)

const createChatGroup = ({ id = "", messages = [], name = "Grupal", users = [] } = {}) => (
	{
		id,
		name,
		messages,
		users,
		typingUsers: []
	}
)

const setUnixToTimeFormat = (type, dateunix) => {

	let unix_timestamp = dateunix;
	let today = Date.now();
	var date = new Date(unix_timestamp - 1000);
	var datetoday = new Date(today - 1000);
	let date_db_unix = (unix_timestamp / 1000)
	let date_today_unix = (today / 1000)

	/*** Obtiene mes actual */

	var month = new Array();
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";
	var n_Month = month[datetoday.getMonth()];

	/*** End obtiene mes actual */

	/*** Obtiene mes actual desde la DB */

	var month_db = new Array(12);
	month_db[0] = "1";
	month_db[1] = "2";
	month_db[2] = "3";
	month_db[3] = "4";
	month_db[4] = "5";
	month_db[5] = "6";
	month_db[6] = "7";
	month_db[7] = "8";
	month_db[8] = "9";
	month_db[9] = "10";
	month_db[10] = "11";
	month_db[11] = "12";
	var n_Month_db = month_db[date.getMonth()];

	/*** End obtiene mes actual */

	if (type === "messages") {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'p.m.' : 'a.m.';
		hours = hours % 12;
		hours = hours ? hours : 12;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var hora = hours + ':' + minutes + ' ' + ampm;

		return hora
	}
	else if (type === "LastSeen") {

		var result = ''

		if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& date.getDate() === datetoday.getDate()) {
			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = 'Ult. vez hoy a las ' + hora_lm
		}
		else if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& (date.getDate() === datetoday.getDate() - 1 || datetoday.getDate() - 1 === 0) && date_today_unix - date_db_unix <= 86400) {

			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;
			result = 'Ult. vez ayer a las ' + hora_lm
		}
		else if (date_today_unix - date_db_unix <= 604800) {
			/**** Obtiene el dia de la semana */
			var weekday = new Array(7);
			weekday[0] = "domingo";
			weekday[1] = "lunes";
			weekday[2] = "martes";
			weekday[3] = "miércoles";
			weekday[4] = "jueves";
			weekday[5] = "viernes";
			weekday[6] = "sábado";

			var n_Day = weekday[date.getDay()];

			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = 'Ult. vez el ' + n_Day + ' a las ' + hora_lm

			/**** End Obtiene el dia de la semana */
		}
		else {
			var datecomplete = date.getDate() + "/" + n_Month_db + "/" + date.getFullYear()
			result = 'Ult. vez el ' + datecomplete
		}

		return result
	}
	else if (type === "side_bar_last_message") {

		var result = ''

		if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& date.getDate() === datetoday.getDate()) {
			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = hora_lm
		}
		else if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& (date.getDate() === datetoday.getDate() - 1 || datetoday.getDate() - 1 === 0) && date_today_unix - date_db_unix <= 86400) {
			result = 'ayer'
		}
		else if (date_today_unix - date_db_unix <= 604800) {
			/**** Obtiene el dia de la semana */
			var weekday = new Array(7);
			weekday[0] = "domingo";
			weekday[1] = "lunes";
			weekday[2] = "martes";
			weekday[3] = "miércoles";
			weekday[4] = "jueves";
			weekday[5] = "viernes";
			weekday[6] = "sábado";

			var n_Day = weekday[date.getDay()];

			result = n_Day

			/**** End Obtiene el dia de la semana */
		}
		else {
			var datecomplete = date.getDate() + "/" + n_Month_db + "/" + date.getFullYear()
			result = datecomplete
		}

		return result

	} else if (type === "info_contacto") {

		var result = ''

		if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& date.getDate() === datetoday.getDate()) {
			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = 'Se creó hoy a las ' + hora_lm
		}
		else if (date.getFullYear() === datetoday.getFullYear()
			&& n_Month_db === n_Month
			&& (date.getDate() === datetoday.getDate() - 1 || datetoday.getDate() - 1 === 0) && date_today_unix - date_db_unix <= 86400) {
			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = 'Se creó ayer a las ' + hora_lm
		}
		else if (date_today_unix - date_db_unix <= 604800) {
			/**** Obtiene el dia de la semana */
			var weekday = new Array(7);
			weekday[0] = "domingo";
			weekday[1] = "lunes";
			weekday[2] = "martes";
			weekday[3] = "miércoles";
			weekday[4] = "jueves";
			weekday[5] = "viernes";
			weekday[6] = "sábado";

			var n_Day = weekday[date.getDay()];

			var hours_lm = date.getHours();
			var minutes_lm = date.getMinutes();
			var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
			hours_lm = hours_lm % 12;
			hours_lm = hours_lm ? hours_lm : 12;
			minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
			var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

			result = 'Se creó el ' + n_Day + ' a las ' + hora_lm

			/**** End Obtiene el dia de la semana */
		}
		else {
			var n_mont_nombre = new Array(12);
			n_mont_nombre[0] = "Enero";
			n_mont_nombre[1] = "Frebreo";
			n_mont_nombre[2] = "Marzo";
			n_mont_nombre[3] = "Abril";
			n_mont_nombre[4] = "Mayo";
			n_mont_nombre[5] = "Junio";
			n_mont_nombre[6] = "Julio";
			n_mont_nombre[7] = "Agosto";
			n_mont_nombre[8] = "Septiembre";
			n_mont_nombre[9] = "Octubre";
			n_mont_nombre[10] = "Noviembre";
			n_mont_nombre[11] = "Diciembre";

			var n_Month_name = n_mont_nombre[date.getMonth()];
			var datecomplete = date.getDate() + " de " + n_Month_name + " del " + date.getFullYear()
			result = 'Se creó el ' + datecomplete
		}

		return result

	}else if(type === "fecha_completa_alerta"){
		if(date.getFullYear() === datetoday.getFullYear()
		&& n_Month_db === n_Month
		&& date.getDate() === datetoday.getDate()) {
		var hours_lm = date.getHours();
		var minutes_lm = date.getMinutes();
		var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
		hours_lm = hours_lm % 12;
		hours_lm = hours_lm ? hours_lm : 12;
		minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
		var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

		result = 'venció hoy a las ' + hora_lm
		return result
		}else if(date.getFullYear() === datetoday.getFullYear()
		&& n_Month_db === n_Month
		&& (date.getDate() === datetoday.getDate() - 1 || datetoday.getDate() - 1 === 0)) {
		var hours_lm = date.getHours();
		var minutes_lm = date.getMinutes();
		var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
		hours_lm = hours_lm % 12;
		hours_lm = hours_lm ? hours_lm : 12;
		minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
		var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

		result = 'venció ayer a las ' + hora_lm
		return result
		}else{
		var Day_lm = date.getDate()
		var Month_lm = date.getMonth() + 1
		var year_lm = date.getFullYear()
		var hours_lm = date.getHours();
		var minutes_lm = date.getMinutes();
		var ampm_lm = hours_lm >= 12 ? 'p.m.' : 'a.m.';
		hours_lm = hours_lm % 12;
		hours_lm = hours_lm ? hours_lm : 12;
		minutes_lm = minutes_lm < 10 ? '0' + minutes_lm : minutes_lm;
		var hora_lm = hours_lm + ':' + minutes_lm + ' ' + ampm_lm;

		result = 'venció el  ' + Day_lm + "/" + Month_lm + "/" + year_lm + " a las " + hora_lm
		return result
		}


	}
}

const CalcularExpiracion = (fin) =>{
	let Hoy = new Date()
	let FechaFin = new Date(fin)
	let GetHour = new Date(fin).getHours() * 3600
	let GetMinute = new Date(fin).getMinutes() * 60
	let GetSecond =  new Date(fin).getSeconds()

	let GetHourHoy = Hoy.getHours() * 3600
	let GetMinutesHoy = Hoy.getMinutes() * 60
	let GetSecondHoy = Hoy.getSeconds()

	let NewHourMinuteSecondFin = GetHour + GetMinute + GetSecond
	let NewHourMinuteSecondHoy = GetHourHoy + GetMinutesHoy + GetSecondHoy

	let segundos = Math.round((FechaFin.getTime() - Date.now()) / 1000)
	let diasfaltan = Math.floor(segundos / 60 / 60 / 24)
	let NewSeconds = NewHourMinuteSecondFin - NewHourMinuteSecondHoy


	if(NewHourMinuteSecondHoy > NewHourMinuteSecondFin)
	{
		
	 var hour = 24 + Math.floor(NewSeconds/3600);
	}else
	{
		hour = Math.floor(NewSeconds/3600);
		}
		var HourReal = Math.floor(NewSeconds/3600);
	    var resultado = NewSeconds-(HourReal*3600);
	    var minute = Math.floor(resultado/60);
		var second = resultado-(minute*60);


    hour = (hour < 10)? '0' + hour : hour;
    minute = (minute < 10)? '0' + minute : minute;
    second = (second < 10)? '0' + second : second;
	var formatdias = diasfaltan === 1 ? diasfaltan + ' día ':diasfaltan + ' dias '

	var retorno = ""

    if(diasfaltan < 0)
	{
		retorno = 'Caducado'
	}else{
		retorno = formatdias + hour + ':' + minute + ':' + second
	}

	return (retorno)
    
}

const CalcularExpiracionUser = (fin) =>{
	let Hoy = new Date()
	let FechaFin = new Date(fin)
	let GetHour = new Date(fin).getHours() * 3600
	let GetMinute = new Date(fin).getMinutes() * 60
	let GetSecond =  new Date(fin).getSeconds()

	let GetHourHoy = Hoy.getHours() * 3600
	let GetMinutesHoy = Hoy.getMinutes() * 60
	let GetSecondHoy = Hoy.getSeconds()

	let NewHourMinuteSecondFin = GetHour + GetMinute + GetSecond
	let NewHourMinuteSecondHoy = GetHourHoy + GetMinutesHoy + GetSecondHoy

	let segundos = Math.round((FechaFin.getTime() - Date.now()) / 1000)
	let diasfaltan = Math.floor(segundos / 60 / 60 / 24)
	let NewSeconds = NewHourMinuteSecondFin - NewHourMinuteSecondHoy


	if(NewHourMinuteSecondHoy > NewHourMinuteSecondFin)
	{
		
	 var hour = 24 + Math.floor(NewSeconds/3600);
	}else
	{
		hour = Math.floor(NewSeconds/3600);
		}
		var HourReal = Math.floor(NewSeconds/3600);
	    var resultado = NewSeconds-(HourReal*3600);
	    var minute = Math.floor(resultado/60);
		var second = resultado-(minute*60);


    hour = (hour < 10)? '0' + hour : hour;
    minute = (minute < 10)? '0' + minute : minute;
    second = (second < 10)? '0' + second : second;

    
	if(diasfaltan < 0)
	{
		return 'Caducado'
	} else{
		return diasfaltan + ' dias ' + hour + ':' + minute + ':' + second
	}
    
}

const NombrePlataforma = (codigo) => {
	if(codigo === 0){
		return  'Netflix'
	}else if(codigo === 1){
		return 'Movistar Play'
	}else if(codigo === 2){
		return 'Disney Plus'
	}else if(codigo === 3){
		return 'HBO Max'
	}else if(codigo === 4){
		return 'Prime Video'
	}else if(codigo === 5){
		return 'Paramount'
	}else if(codigo === 6){
		return 'Youtube Premium'
	}else if(codigo === 7){
		return 'PornHub Premium'
	}else if(codigo === 8){
		return 'Crunchyroll'
	}
}

const CodigoPlataforma = (nombre) => {
	if(nombre === 'Netflix'){
		return 0
	}else if(nombre === 'Movistar Play'){
		return 1
	}else if(nombre === 'Disney Plus'){
		return 2
	}else if(nombre === 'Hbo Max'){
		return 3
	}else if(nombre === 'Prime Video'){
		return 4
	}else if(nombre === 'Paramount'){
		return 5
	}else if(nombre === 'Youtube Premiun'){
		return 6
	}else if(nombre === 'PornHub Premiun'){
		return 7
	}else if(nombre === 'Crunchyroll'){
		return 8
	}
}

const AgruparCantidadPerfiles = (Obj, prop) => {
	return Obj.reduce((groups, item) => {
		var val = item[prop];
		groups[val] = groups[val] || {TipoCuentaCodigo: item.TipoCuentaCodigo, CantidadPerfiles: 0};
		groups[val].CantidadPerfiles += item.CantidadPerfiles;
		return groups;
	}, {});
}

const Top3Perfiles = (listaPlataformas, Obj, callback) =>{
	const Topes = []
	const TopesFiltrados = []

	listaPlataformas.map((items)=>{
		return(Topes.push(Obj[items.Identificador]))
	})

	const TopesFiltrados0 = Topes.sort((a, b)=> b.CantidadPerfiles - a.CantidadPerfiles)

	for(var i=0;i<listaPlataformas.length;i++){
		if(TopesFiltrados0[i] != null){
			TopesFiltrados.push(TopesFiltrados0[i])
		}
	}

	var NombreTop1 = ""
	var NombreTop2 = ""
	var NombreTop3 = ""

	var CantidadPerfiles1 = 0
	var CantidadPerfiles2 = 0
	var CantidadPerfiles3 = 0

	

	if(TopesFiltrados.length === 0)
    {
		const ReturnTopes0 = {Top1 : {Tipo : NombreTop1, CantidadPerfiles : CantidadPerfiles1},
	Top2 : {Tipo : NombreTop2, CantidadPerfiles : CantidadPerfiles2},
	Top3 : {Tipo : NombreTop3, CantidadPerfiles : CantidadPerfiles3}}

	callback(ReturnTopes0)
	}
	else if(TopesFiltrados.length === 1)
    {
		CantidadPerfiles1 = TopesFiltrados[0].CantidadPerfiles

		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[0].TipoCuentaCodigo === p__.Identificador){
				NombreTop1 = p__.NombrePlataforma
			}
		})

		const ReturnTopes1 = {Top1 : {Tipo : NombreTop1, CantidadPerfiles : CantidadPerfiles1},
	Top2 : {Tipo : NombreTop2, CantidadPerfiles : CantidadPerfiles2},
	Top3 : {Tipo : NombreTop3, CantidadPerfiles : CantidadPerfiles3}}

	callback(ReturnTopes1)
	}else if(TopesFiltrados.length === 2){
		CantidadPerfiles1 = TopesFiltrados[0].CantidadPerfiles
		CantidadPerfiles2 = TopesFiltrados[1].CantidadPerfiles

		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[0].TipoCuentaCodigo === p__.Identificador){
				NombreTop1 = p__.NombrePlataforma
			}
		})

		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[1].TipoCuentaCodigo === p__.Identificador){
				NombreTop2 = p__.NombrePlataforma
			}
		})

		const ReturnTopes2 = {Top1 : {Tipo : NombreTop1, CantidadPerfiles : CantidadPerfiles1},
	Top2 : {Tipo : NombreTop2, CantidadPerfiles : CantidadPerfiles2},
	Top3 : {Tipo : NombreTop3, CantidadPerfiles : CantidadPerfiles3}}

	callback(ReturnTopes2)

	}else if (TopesFiltrados.length >= 3){
		CantidadPerfiles1 = TopesFiltrados[0].CantidadPerfiles
		CantidadPerfiles2 = TopesFiltrados[1].CantidadPerfiles
		CantidadPerfiles3 = TopesFiltrados[2].CantidadPerfiles


		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[0].TipoCuentaCodigo === p__.Identificador){
				NombreTop1 = p__.NombrePlataforma
			}
		})

		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[1].TipoCuentaCodigo === p__.Identificador){
				NombreTop2 = p__.NombrePlataforma
			}
		})
	
		listaPlataformas.find((p__)=>{
			if(TopesFiltrados[2].TipoCuentaCodigo === p__.Identificador){
				NombreTop3 = p__.NombrePlataforma
			}
		})

		const ReturnTopes3 = {Top1 : {Tipo : NombreTop1, CantidadPerfiles : CantidadPerfiles1},
	Top2 : {Tipo : NombreTop2, CantidadPerfiles : CantidadPerfiles2},
	Top3 : {Tipo : NombreTop3, CantidadPerfiles : CantidadPerfiles3}}

	callback(ReturnTopes3)
	}
	}

const GenerarAlertasSistmaPerfiles = (Obj, Obj1) => {
	const GenerarAlertasDelSistemaPerfiles = Obj.map(item => {
		if(Date.now() >= item.FechaFinPerfil){
			const Perfil = {
				Tipo : 'perfil',
				Identificador : item.Identificador,
				IdContacto : item.IdContacto,
				TipoCuentaCodigo : item.TipoCuentaCodigo,
				FechaExpiracion : item.FechaFinPerfil,
				Correo : item.CorreoPerfil
			}
			return Perfil
		}})

	const GenerarAlertasDelSistemaCuentas = Obj1.map(item => {
			if(Date.now() >= item.FechaFinCuenta){
				const Cuenta = {
					Tipo : 'cuenta',
					Identificador : item.Identificador,
					IdContacto : '',
					TipoCuentaCodigo : item.TipoCuentaCodigo,
					FechaExpiracion : item.FechaFinCuenta,
					Correo : item.CorreoCuenta
				}
				return Cuenta
		}})

	const Ordenar = []
		for(var i=0;i<GenerarAlertasDelSistemaPerfiles.length;i++){
			if(GenerarAlertasDelSistemaPerfiles[i] != null){
				Ordenar.push(GenerarAlertasDelSistemaPerfiles[i])
			}
		}

		for(var i=0;i<GenerarAlertasDelSistemaCuentas.length;i++){
			if(GenerarAlertasDelSistemaCuentas[i] != null){
				Ordenar.push(GenerarAlertasDelSistemaCuentas[i])
			}
		}

		return Ordenar.sort((a,b) => a.FechaExpiracion - b.FechaExpiracion)
}

const ActualizarMensajesServidorVencidos = (Obj) => {
	var Hoy = Date.now()

    const NewMensajes = Obj.filter((__c) => __c.TiempoExpiracion > Hoy);

		return NewMensajes
}

const esSUPERADMINISTRADOR = (user) =>{
	if(user.autority === 10){
	   return true
	}else{
	   return false
	}
}


const esADMINISTRADOR = (user) =>{
     if(user.autority === 5 || user.autority === 10){
		return true
	 }else{
		return false
	 }
}

const autoColor = (fc0, fc1) => {
	var colorfinal = ''
	if (fc0 >= 0 && fc0 <= 50 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#E94343"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#E9D743"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#BCE943"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#A85ED5"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#fc09c5"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#f8c8c9"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#B4C459"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#ffcc44"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#70B444"
	}
	else if (fc0 >= 0 && fc0 <= 50 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#f7aa44"
	}



	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#63DD7D"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#4EB21D"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#A96D76"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#FFAA00"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#AE6BED"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#43DBBF"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#A03FCD"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#20D6CB"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#44B7E9"
	}
	else if (fc0 >= 51 && fc0 <= 100 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#439BDB"
	}



	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#C44F44"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#EAD200"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#59EA00"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#005DFF"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#EDE96B"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#6583D1"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#c155c9"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#6312AB"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#aac744"
	}
	else if (fc0 >= 101 && fc0 <= 200 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#f7bb44"
	}



	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#E37200"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#008AE3"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#43C1A3"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#6D9EF2"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#DF52BF"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#A3C3EE"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#7846A4"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#37AB12"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#aac744"
	}
	else if (fc0 >= 201 && fc0 <= 300 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#f7bb44"
	}


	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#FF4848"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#85FF48"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#C844D0"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#6DF2E0"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#C88EBB"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#65B8D1"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#5A94BC"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#126AAB"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#aac744"
	}
	else if (fc0 >= 301 && fc0 <= 600 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#f7bb44"
	}


	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 0 && fc1 <= 10) {
		colorfinal = "#0469AF"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 11 && fc1 <= 20) {
		colorfinal = "#94059D"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 21 && fc1 <= 30) {
		colorfinal = "#f8f5cc"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 31 && fc1 <= 40) {
		colorfinal = "#C4B82A"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 41 && fc1 <= 50) {
		colorfinal = "#5CB8C2"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 51 && fc1 <= 60) {
		colorfinal = "#657AD1"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 61 && fc1 <= 70) {
		colorfinal = "#5AB9BC"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 71 && fc1 <= 80) {
		colorfinal = "#1DB4B9"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 81 && fc1 <= 90) {
		colorfinal = "#aac744"
	}
	else if (fc0 >= 601 && fc0 <= 9999 && fc1 >= 91 && fc1 <= 99) {
		colorfinal = "#f7bb44"
	}

	return colorfinal
}

const PermiteSoloNumeros = (evt) => {
	var key = window.event ? evt.which : evt.keyCode;
	if ((key < 48 || key > 57) && key !== 13) {
		evt.preventDefault();
	}
}

const PermiteSoloLetras = (evt) => {
	var regex = new RegExp("^[a-zA-Z ]+$");
  var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
  if (!regex.test(key)) {
    evt.preventDefault();
    return false;
  }
}

const currencyFormat = (num) => {
	return 'S/. ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

const ObtenerDiaMesYearActual = (tipo) =>{
	var Dia = new Date(Date.now()).getDate()
	var Mes = new Date(Date.now()).getMonth() + 1
	var Year = new Date(Date.now()).getFullYear()
	var Retorno = 'Dato incorrecto'
	if(tipo === 'dia'){
		Retorno = Dia
	}else if(tipo === 'mes'){
		Retorno = Mes
	}else if(tipo === 'year'){
		Retorno = Year
	}
    return Retorno
 }

const ObtenerNombreDelMesActual = () =>{
	var Mes = new Date(Date.now()).getMonth() + 1
	var NombreMes = ''
	if(Mes === 1){
		NombreMes = 'Enero'
	}else if(Mes === 2){
		NombreMes = 'Febrero'
	}else if(Mes === 3){
		NombreMes = 'Marzo'
	}else if(Mes === 4){
		NombreMes = 'Abril'
	}else if(Mes === 5){
		NombreMes = 'Mayo'
	}else if(Mes === 6){
		NombreMes = 'Junio'
	}else if(Mes === 7){
		NombreMes = 'Julio'
	}else if(Mes === 8){
		NombreMes = 'Agosto'
	}else if(Mes === 9){
		NombreMes = 'Septiembre'
	}else if(Mes === 10){
		NombreMes = 'Octubre'
	}else if(Mes === 11){
		NombreMes = 'Noviembre'
	}else if(Mes === 12){
		NombreMes = 'Diciembre'
	}
    return NombreMes
 }

module.exports = {
	createMessage,
	createChat,
	createChatGroup,
	createUser,
	setUnixToTimeFormat,
	autoColor,
	PermiteSoloNumeros,
	PermiteSoloLetras,
	CalcularExpiracion,
	CalcularExpiracionUser,
	AgruparCantidadPerfiles,
	Top3Perfiles,
	currencyFormat,
	ObtenerDiaMesYearActual,
	ObtenerNombreDelMesActual,
	GenerarAlertasSistmaPerfiles,
	esADMINISTRADOR,
	esSUPERADMINISTRADOR,
	ActualizarMensajesServidorVencidos,
	NombrePlataforma,
	CodigoPlataforma
}

