import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { IoBarChartSharp, IoCloseSharp, IoFootsteps, IoSaveSharp, IoTv } from 'react-icons/io5';
import { MdAccountBalance, MdAddAlert, MdCheckCircle, MdDelete, MdEdit, MdEditOff, MdError, MdGroup, MdInfo, MdLooks3, MdLooksOne, MdLooksTwo, MdModeEdit, MdPersonAdd, MdSave, MdTimer, MdUpdate, MdWarning } from 'react-icons/md';
import UserDataModel from '../models/UserDataModel';
import Backup from './pages/configuracion/Backup';
import MisUsuarios from './pages/configuracion/MisUsuarios';
import Contactos from './pages/contactos/Contactos';
import MisCuentas from './pages/cuentas/MisCuentas';
import MisPerfiles from './pages/perfiles/MisPerfiles';
import PinesNetflix from './pages/pines-netflix/PinesNetflix';
import Swal from 'sweetalert2'
import { CalcularExpiracion, CodigoPlataforma, currencyFormat, ObtenerNombreDelMesActual, PermiteSoloLetras, PermiteSoloNumeros, setUnixToTimeFormat } from '../Funciones';
const { v4: uuidv4 } = require('uuid')
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { IoMdCamera, IoMdEye, IoMdEyeOff, IoMdLock, IoMdSave, IoMdSettings } from 'react-icons/io';
import { AlertTitle, Autocomplete, IconButton, Tooltip } from '@mui/material';
import { Alert } from '@mui/material';
import MisCodigos from './pages/configuracion/MisCodigos';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import FileUpPersonal from './fileup/FileUpPersonal';
import axios from "axios";
import { FaFolderPlus } from 'react-icons/fa';

export default function MainRight(props) {

	const { user,
		esADMIN,
		socket,
		p_listausuarios,
		p_usuarioinfo,
		p_listacontactos,
		p_listacuentas,
		p_listaplataformas,
		p_listaperfiles,
		p_listamensajesservidor,
		p_borrarcontacto,
		p_editarcontacto,
		p_borrarcuenta,
		p_EditarCuenta,
		p_EditarCuentaEstadoUso,
		p_borrarperfil,
		p_EditarPerfil,
		p_listacodigos,
		p_borrarkey,
		p_plataformas,
		p_NuevoCombo,
		p_handleabrirnuevocontacto,
		
		p_miscontactos, 
		p_nuevocontacto, 
		p_nuevopinnetflix, 
		p_mispinesnetflix,
		p_handlecerrarmispinesnetflix,
		p_miscuentas,
		p_nuevacuenta,
		p_handlecerrarmiscuentas,
		p_misperfiles,
		p_nuevoperfil,
		p_handlecerrarmisperfiles,
		p_backup,
		p_nuevocodigo,
		p_handlecerrarnuevocodigo,
		p_misusuarios,
		p_handlecerrarbackup,
		p_handlecerrarmisusuarios,
		p_abrirperfilinfo,
		p_miskeydeactivacion,

		s_MaxPerfilesNetflix,
		s_MaxPerfilesMovPlay,
		s_MaxPerfilesDisney,
		s_MaxPerfilesHbo,
		s_MaxPerfilesPrimeV,
		s_MaxPerfilesParamount,
		s_MaxPerfilesPornHub,
		s_MaxPerfilesYoutube,
		s_MaxPerfilesCrunchyroll,

		p_HandleChangeMaxPerfilesNetflix,
		p_HandleChangeMaxPerfilesMovPlay,
		p_HandleChangeMaxPerfilesDisney,
		p_HandleChangeMaxPerfilesHbo,
		p_HandleChangeMaxPerfilesPrimeV,
		p_HandleChangeMaxPerfilesParamount,
	    p_HandleChangeMaxPerfilesPornHub,
		p_HandleChangeMaxPerfilesYoutube,
		p_HandleChangeMaxPerfilesCrunchyroll,
		p_editarplataformas,
		p_editarpasswordperfilinfo,
		p_editarbusinessperfilinfo,

	    s_PasswordPerfilInfo,
	    s_BusinessNamePerfilInfo,
		s_CurrentMaxCharBusinessPerfil,
		s_CurrentMaxCharPasswordPerfilInfo,

		s_portal,

	    s_HandleChangePasswordPerfilInfo,
	    s_HandleChangeBusinessNamePerfilInfo,

		s_Top1,
		s_Top2,
		s_Top3,

		p_HandleActualizarTop3Perfiles,
		s_gananciamesactual,
		s_cantidadperfiles,
		s_PerfilesVencidos,
		ActualizarPerfilesVencidos,
		ActualizarMensajesServidorVencidos,
		p_mensajedelservidor,
		p_reportar,
		s_cerrarmensajedelservidor,
		s_cerrarreportar,
		p_VerDetallesDeAlertaSistema,
		s_detallesalertasistema,
		s_isloadingalertasservidor,
		s_isloadingcontactos,
		s_isloadingcuentas,
		s_isloadingperfiles,
		s_vaciardetallesalertasistema,
		p_closenuevocombo,
		p_CambiarImagenPerfil,
		p_listaplataformascombo,
		s_SetPlataformasCombo
	} = props
	/**CONTACTO INICIO */
	const [s_phonenumber, Setphonenumber] = useState("")
    const [s_nombrecontacto, Setnombrecontacto] = useState("")
	/**CONTACTO FIN */
    /**CUENTA INICIO */
	const [s_CorreoCuenta, SetCorreoCuenta] = useState("")
	const [s_PasswordCorreo, SetPasswordCorreo] = useState("")
	const [s_PasswordCuenta, SetPasswordCuenta] = useState("")
	const [s_TipoCuentaCodigo, SetTipoCuentaCodigo] = useState("")
	const [s_FechaInicioCuenta, SetFechaInicioCuenta] = useState("")
	const [s_FechaFinCuenta, SetFechaFinCuenta] = useState("")
	const [s_ExpiracionOpcionalCuenta, SetExpiracionOpcionalCuenta] = useState("")
    /**CUENTA FIN */
	/**PERFIL INICIO */
	const [s_ContactoPerfil, SetContactoPerfil] = useState("")
	const [s_TipoCuentaCodigoPerfil, SetTipoCuentaCodigoPerfil] = useState("")
	const [s_CorreoPerfil, SetCorreoPerfil] = useState("")
	const [s_CantidadPerfiles, SetCantidadPerfiles] = useState("")
	const [s_DescripcionPerfil, SetDescripcionPerfil] = useState("")
	const [s_CostoPerfil, SetCostoPerfil] = useState("")
	const [s_FechaInicioPerfil, SetFechaInicioPerfil] = useState("")
	const [s_FechaFinPerfil, SetFechaFinPerfil] = useState("")
	const [s_ExpiracionOpcionalPerfil, SetExpiracionOpcionalPerfil] = useState("")
	
	/**PERFIL FIN */

	/**COMBO INICIO*/
	const [s_ContactoCombo, SetContactoCombo] = useState("")
	const [s_CurrentPlataformasCombo, SetCurrentPlataformasCombo] = useState([])
	const [s_FechaFinPerfilCombo, SetFechaFinPerfilCombo] = useState("")
	const [s_ExpiracionOpcionalPerfilCombo, SetExpiracionOpcionalPerfilCombo] = useState("")
	const [s_CostoCombo, SetCostoCombo] = useState("")
	const [s_CurrentSelected, SetCurrentSelected] = useState("")

	/**COMBO FIN */

	const [CurrentMaxCharNombre, setCurrentMaxCharNombre] = useState("35")
	const [CurrentMaxCharCelular, setCurrentMaxCharCelular] = useState("9")

	const [CurrentMaxCharTituloMensajeServidor, setCurrentMaxCharTituloMensajeServidor] = useState("30")
	const [CurrentMaxCharMensajeServidor, setCurrentMaxCharMensajeServidor] = useState("150")

	const [CurrentMaxCharTituloReporte, setCurrentMaxCharTituloReporte] = useState("30")
	const [CurrentMaxCharReporte, setCurrentMaxCharReporte] = useState("250")
	/** CREAR CODIGO INICIO */
	const [s_TiempoExpCodigo, SetTiempoExpCodigo] = useState("")
	const [s_KeyGenerado, SetKeyGenerado] = useState("")
	/** CREAR CODIGO FIN */

	const [s_AbrirEditarPasswordPerfil, SetAbrirEditarPasswordPerfil] = useState(true)
	const [s_AbrirEditarBusinessPerfil, SetAbrirEditarBusinessPerfil] = useState(true)

	const [VerPasswordPerfil, SetVerPasswordPerfil] = useState(false)
	const [VerificarMiPassword, SetVerificarMiPassword] = useState(false)
	const [curTime, SetCurTime] = useState(null)

	const [VerPasswordCorreo, SetVerPasswordCorreo] = useState(false)
    const [VerPasswordCuenta, SetVerPasswordCuenta] = useState(false)

	const [PasswordAleatorio, SetPasswordAleatorio] = useState("")

	const [s_TituloMensajeServidor, SetTituloMensajeServidor] = useState("")
	const [s_MensajeServidor, SetMensajeServidor] = useState("")
	const [s_CategoriaMensajeServidor, SetCategoriaMensajeServidor] = useState("")

	const [s_TituloReporte, SetTituloReporte] = useState("")
	const [s_Reporte, SetReporte] = useState("")

	const [s_AbrirNuevaPlataforma, SetAbrirNuevaPlataforma] = useState(false)
	const [s_NombreNuevaPlataforma, SetNombreNuevaPlataforma] = useState("")
	const [s_MaxPerfilesNuevaPlataforma, SetMaxPerfilesNuevaPlataforma] = useState("")
	const [s_AbrirEditarPlataforma, SetAbrirEditarPlataforma] = useState(false)
	const [s_IdentificadorEditarPlataforma, SetIdentificadorEditarPlataforma] = useState("")
	const [s_NombreEditarPlataforma, SetNombreEditarPlataforma] = useState("")
	const [s_MaxPerfilesEditarPlataforma, SetMaxPerfilesEditarPlataforma] = useState("")

	const [file, SetFile] = useState('')
	const [uploadedFile, setUploadedFile] = useState({});
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [showPercent, SetShowPercent] = useState(false)
	const [isImageLoaded, SetisImageLoaded] = useState(false)
	const [PreViewCambiarFotoDePerfil, setPreViewCambiarFotoDePerfil] = useState(false);
	
	
	var userPhoto 
	useEffect( async () => {
		setInterval(function(){
            SetCurTime(new  Date().toLocaleString());
			ActualizarPerfilesVencidos()
			ActualizarMensajesServidorVencidos()
        }.bind(this), 1000);
			var text = "";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		  
			for (var i = 0; i < 9; i++)
			  text += possible.charAt(Math.floor(Math.random() * possible.length));
			  SetPasswordAleatorio(text)
    }, [])

    const alertaAceptar = (titulo, texto, icon, boton) => {
		  Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }

	const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }

	const HandleVerPasswordPerfil = (e)=>{
		SetVerPasswordPerfil(!VerPasswordPerfil)
  }


  const HandleVerPasswordCorreo = (e)=>{
	SetVerPasswordCorreo(!VerPasswordCorreo)
}

const HandleVerPasswordCuenta = (e)=>{
  SetVerPasswordCuenta(!VerPasswordCuenta)
}
    
	const HandleSubmitNuevoContacto = (e) => {
        e.preventDefault()
		let IdUnico = uuidv4()

        if (s_phonenumber.length < 1 || s_nombrecontacto.length < 1) {
			Swal.fire({
				html: "Uno o más de los campos requeridos están vacios!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
        }
        else {
            UserDataModel.NuevoContacto(user.user, IdUnico, s_phonenumber, s_nombrecontacto, (res) => {
                if (res === true) {
                    const NewConctacto = {
						Identificador : IdUnico,
                        NumeroCelular: s_phonenumber,
                        NombreCompleto: s_nombrecontacto,
                        created_at: Date.now()
                    }
                    p_listacontactos.push(NewConctacto)
                    Setphonenumber("")
                    Setnombrecontacto("")
                    alertaAceptar('Registro Completo', 'Los datos se guardaron correctamente!', 'success', 'Aceptar')

                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
                }

                if (res === 2) {
                    alertaAceptar('Alerta de CastleAPP', 'El numero de contacto ya existe!', 'warning', 'Aceptar')
                }

            })
        }

    }

	const HandleSubmitNuevaPlataforma = (e) => {
        e.preventDefault()
		let IdUnico = uuidv4()

        if (s_NombreNuevaPlataforma.length < 1 || s_MaxPerfilesNuevaPlataforma.length < 1) {
			Swal.fire({
				html: "Uno o más de los campos requeridos están vacios!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
        }
		else if(p_listaplataformas.length >= 12){
			Swal.fire({
				html: "No puedes agregar más de 12 plataformas.",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
		}else {
            UserDataModel.NuevaPlataforma(user.user, IdUnico, s_NombreNuevaPlataforma, s_MaxPerfilesNuevaPlataforma, (res) => {
                if (res === true) {
                    const NuevaPlataforma = {
						Identificador : IdUnico,
                        NombrePlataforma: s_NombreNuevaPlataforma,
                        MaximoPerfiles: s_MaxPerfilesNuevaPlataforma,
                        Logo : 'unknow',
                        created_at: Date.now()
                    }
                    p_listaplataformas.push(NuevaPlataforma)
                    SetNombreNuevaPlataforma("")
                    SetMaxPerfilesNuevaPlataforma("")
                    alertaAceptar('Registro Completo', 'Los datos se guardaron correctamente!', 'success', 'Aceptar')

                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
                }

                if (res === 2) {
                    alertaAceptar('Alerta de CastleAPP', 'Ya existe una plataforma registrada con el mismo nombre!', 'warning', 'Aceptar')
                }

            })
        }

    }


	const HandleSubmitNuevaCuenta = (e) => {
		e.preventDefault()
		let IdUnico = uuidv4()
		var FormatHoy = Date.now()
		var FormatFechaInicio = new Date(s_FechaInicioCuenta).getTime()
		var FormatFechaFin = new Date(s_FechaFinCuenta).getTime()

        if (s_CorreoCuenta.length < 1 || 
			s_PasswordCorreo.length < 1 ||
            s_PasswordCuenta.length < 1 ||
			s_FechaInicioCuenta.length < 1 ||
			s_FechaFinCuenta.length < 1 || 
			s_TipoCuentaCodigo.length < 1
			) {
				Swal.fire({
					html: "Uno o más de los campos requeridos están vacios!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
        }else if(FormatFechaFin < FormatHoy){
			Swal.fire({
				html: "La fecha de fin no puede ser menor a hoy!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
		}
        else {
            UserDataModel.NuevaCuenta(user.user, 
				s_CorreoCuenta,
				s_PasswordCorreo,
				s_PasswordCuenta,
				s_TipoCuentaCodigo,
				s_FechaInicioCuenta,
				s_FechaFinCuenta,
				IdUnico, (res) => {
                if (res === true) {
                    const NuevaCuenta = {
						Identificador : IdUnico,
                        CorreoCuenta: s_CorreoCuenta,
                        PasswordCorreo: s_PasswordCorreo,
                        PasswordCuenta: s_PasswordCuenta,
						TipoCuentaCodigo: s_TipoCuentaCodigo,
						EstadoUso: 0,
                        FechaInicioCuenta: FormatFechaInicio,
                        FechaFinCuenta: FormatFechaFin,
                        created_at: Date.now()
                    }
					p_listacuentas.push(NuevaCuenta)
					props.p_handlecerrarnuevacuenta(false)
					SetCorreoCuenta("")
					SetPasswordCorreo("")
					SetPasswordCuenta("")
					SetTipoCuentaCodigo("")
					SetFechaInicioCuenta("")
					SetFechaFinCuenta("")
					SetExpiracionOpcionalCuenta('')
                    alertaAceptar('Registro Completo', 'Los datos se guardaron correctamente!', 'success', 'Aceptar')

                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
                }

                if (res === 2) {
					{
						var Nombre = ""
						p_listaplataformas.find((_p)=>{
							if(s_TipoCuentaCodigo === _p.Identificador)
							{
								Nombre = _p.NombrePlataforma
							}
						})
					}
                    alertaAceptar('Alerta de CastleAPP', 'El correo <b>'+ s_CorreoCuenta + '</b> ya existe para el tipo de cuenta <b>' + Nombre +'</b>', 'warning', 'Aceptar')
                }

            })
        }

    }


	const HandleSubmitNuevoPerfil = (e) => {
		e.preventDefault()
		let IdUnico = uuidv4()
		var IdContacto = ""
		var Tipo = 'normal'
		var ExpiracionParaInfoperfil = moment(s_FechaFinPerfil).format("DD-MM-yyyy HH:mm:ss")
		p_listacontactos.find((_c)=>{
               if(_c.NombreCompleto === s_ContactoPerfil){
				return IdContacto = _c.Identificador
			   }
		})

		var FormatHoy = Date.now()
		var FormatFechaInicio = new Date(s_FechaInicioPerfil).getTime()
		var FormatFechaFin = new Date(s_FechaFinPerfil).getTime()

        if (IdContacto.length < 1 || 
			s_CorreoPerfil.length < 1 ||
            s_TipoCuentaCodigoPerfil.length < 1 ||
			s_CantidadPerfiles.length < 1 ||
			s_DescripcionPerfil.length < 1 ||
			s_CostoPerfil.length < 1 || 
			s_FechaInicioPerfil.length < 1 ||
			s_FechaFinPerfil.length < 1
			) {
				Swal.fire({
					html: "Uno o más de los campos requeridos están vacios!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
        }else if(FormatFechaFin < FormatHoy){
			Swal.fire({
				html: "La fecha de expiración no puede ser menor a hoy!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
		}
        else {
            UserDataModel.NuevoPerfil(user.user, 
				IdContacto,
				s_TipoCuentaCodigoPerfil,
			    s_CorreoPerfil,
			    s_CantidadPerfiles,
		     	s_DescripcionPerfil,
			    s_CostoPerfil,
			    s_FechaInicioPerfil,
			    s_FechaFinPerfil,
				IdUnico,
				Tipo, (res) => {
                if (res === true) {
                    const NuevoPerfil = {
						Identificador: IdUnico,
                        IdContacto : IdContacto,
                        TipoCuentaCodigo: s_TipoCuentaCodigoPerfil,
                        CorreoPerfil: s_CorreoPerfil,
                        CantidadPerfiles: s_CantidadPerfiles,
                        DescripcionPerfil: s_DescripcionPerfil,
                        CostoPerfil: s_CostoPerfil,
                        FechaInicioPerfil: FormatFechaInicio,
                        FechaFinPerfil: FormatFechaFin,
						Tipo : Tipo,
                        created_at: Date.now()
                    }

					const NuevoEstadoUso = {
                        TipoCuentaCodigo: s_TipoCuentaCodigoPerfil,
                        CorreoPerfil: s_CorreoPerfil,
						CantidadPerfiles : s_CantidadPerfiles,
						Tipo : "Agregar"
                    }
						p_EditarCuentaEstadoUso(NuevoEstadoUso)
						p_listaperfiles.push(NuevoPerfil)
						handleclosenuevoperfil()
						SetContactoPerfil("")
						SetTipoCuentaCodigoPerfil("")
						SetCorreoPerfil("")
						SetCantidadPerfiles("")
						SetDescripcionPerfil("")
						SetCostoPerfil("")
						SetFechaInicioPerfil("")
						SetFechaFinPerfil("")
						SetExpiracionOpcionalPerfil("")
						p_HandleActualizarTop3Perfiles()
                        var password = ''
						var CuentaNombre = ''
						p_listacuentas.find((____c)=>{
                           if(____c.CorreoCuenta === s_CorreoPerfil & ____c.TipoCuentaCodigo === s_TipoCuentaCodigoPerfil){
							password = ____c.PasswordCuenta
						   }
						})
						p_listaplataformas.find((p____)=>{
                           if(p____.Identificador === s_TipoCuentaCodigoPerfil){
							CuentaNombre = p____.NombrePlataforma
						   }
						})

						Swal.fire({
							title : 'Registro Completo',
							html: "<p style='text-align: left; margin-bottom: -8px'><b>Correo</b> : "+ s_CorreoPerfil +"</p><p style='text-align: left; margin-bottom: -8px'><b>Password</b> : " + password + "</p><p style='text-align: left; margin-bottom: -8px'><b>Plataforma</b> : " + CuentaNombre + "</p><p style='text-align: left'><b>Expiración</b> : " + ExpiracionParaInfoperfil + "</p>",
							icon: "success",
							confirmButtonColor : '#2C3E50',
						})
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
                }

                if (res === 2) {
                    alertaAceptar('Alerta de CastleAPP', 'El perfil ya está registrado en la cuenta <b>' + s_CorreoPerfil +'</b>', 'warning', 'Aceptar')
                }

            })
        }
    }

	const HandleSubmitNuevoCodigo = (e) => {
        e.preventDefault()
		let IdUnico = uuidv4()
        if (s_TiempoExpCodigo.length < 1 || s_KeyGenerado.length < 1) {
			Swal.fire({
				html: "Uno o más de los campos requeridos están vacios!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
        }
        else {
            UserDataModel.NuevoCodigo(s_TiempoExpCodigo, IdUnico, s_KeyGenerado, (res) => {
                if (res === true) {
                    const NuevoCodigo = {
						TiempoExpiracion: s_TiempoExpCodigo,
                        Codigo: s_KeyGenerado,
                        EstadoUso: 0,
						Identificador : IdUnico,
                        created_at: Date.now()
                    }
                    p_listacodigos.push(NuevoCodigo)
                    SetTiempoExpCodigo("")
                    SetKeyGenerado("")
					p_handlecerrarnuevocodigo()
                    alertaAceptar('Registro Completo', 'Los datos se guardaron correctamente!', 'success', 'Aceptar')

                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
                }

                if (res === 2) {
                    alertaAceptar('Alerta de CastleAPP', 'El numero de contacto ya existe!', 'warning', 'Aceptar')
                }

            })
        }

    }

	const HandleSubmitNuevoMensajeServidor = (e) => {
        e.preventDefault()
		let IdUnico = uuidv4()
		var TiempoExpiracion = (Math.floor(Number(Date.now()/1000) + Number(5 * 24 * 3600)))*1000

        if (s_TituloMensajeServidor.length < 1 || s_MensajeServidor.length < 1 || s_CategoriaMensajeServidor.length < 1) {
			Swal.fire({
				html: "Uno o más de los campos requeridos están vacios!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
        }else if(!socket.connected){
			Swal.fire({
				html: "<font color='#F8C471'>Ups! ah ocurrido un problema con el servidor, al parecer el servidor está fuera de línea en estos momentos o intenta iniciar sesión nuevamente.</font>",
				icon: "warning",
				timer: 13000,
				confirmButtonColor : '#2C3E50',
			})
		}else{
			UserDataModel.NuevoMensajeServidor(
			user.user, 
			TiempoExpiracion, 
			IdUnico, 
			s_TituloMensajeServidor, 
			s_CategoriaMensajeServidor,
			s_MensajeServidor, 
			(res) => {
			if (res === true) {
				const NuevoMensajeServidor = {
					Identificador : IdUnico,
					Remitente: user.user,
					TituloMensajeServidor: s_TituloMensajeServidor,
					CategoriaMensajeServidor : s_CategoriaMensajeServidor,
					MensajeServidor : s_MensajeServidor,
					TiempoExpiracion : TiempoExpiracion,
					created_at: Date.now()
				}
				socket.emit('NUEVOMENSAJESERVIDOR', NuevoMensajeServidor)
				SetTituloMensajeServidor("")
		        SetCategoriaMensajeServidor("")
		        SetMensajeServidor("")
		        s_cerrarmensajedelservidor()
				alertaAceptar('Registro Completo', 'Los datos se guardaron correctamente!', 'success', 'Aceptar')
			}else{
				alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
			}
		})}

    }


	const HandleSubmitEditarPlataforma = (e) => {
        e.preventDefault()

        if (s_MaxPerfilesEditarPlataforma < 1) {
				Swal.fire({
					html: "El máximo de perfiles no puede ser igual a cero",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
        }else if(s_NombreEditarPlataforma.length < 1 || 
			s_MaxPerfilesEditarPlataforma.length < 1 ){
				Swal.fire({
					html: "Uno o más de los campos requeridos estan vacios!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
		}
        else {
            UserDataModel.ActualizarPlataformas(user.user, 
				s_IdentificadorEditarPlataforma, 
				s_NombreEditarPlataforma,
				s_MaxPerfilesEditarPlataforma,
				 (res) => {
                if (res === true) {
                
					const NewData = {
						Identificador : s_IdentificadorEditarPlataforma,
						NombrePlataforma : s_NombreEditarPlataforma,
						MaxPerfilesPlataforma : s_MaxPerfilesEditarPlataforma
					}

                    p_editarplataformas(NewData)
					SetAbrirEditarPlataforma(false)

                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos' + res, 'error', 'Aceptar')
                }
            })
        }
    }


	const HandleSubmitEditarPasswordPerfilInfo = (e, callback) => {
        e.preventDefault()
        if (s_PasswordPerfilInfo.length < 1) {
			Swal.fire({
					html: "La contraseña es un campo requerido y esta vacio!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
				callback(false)
        }else if(s_PasswordPerfilInfo.length < 8) {
			Swal.fire({
				html: "La contraseña debe tener como mínimo 8 caracteres!",
				icon: "warning",
				timer: 5500,
				confirmButtonColor : '#2C3E50',
			})
			callback(false)
		}else {
			
            UserDataModel.ActualizarPasswordPerfilInfo(user.user,
				s_PasswordPerfilInfo,
				 (res) => {
                if (res === true) {
					const newperfilinfo = Object.assign([{}], [user])
                    newperfilinfo.map((perfil, index)=>{
                       if(perfil.user === user.user){
						perfil.password = s_PasswordPerfilInfo
					   }
					   return perfil
					})
					p_editarpasswordperfilinfo(newperfilinfo)
                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
					callback(true)
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos ' + res, 'error', 'Aceptar')
                }
            })
        }
    }


	const HandleSubmitEditarBusinessPerfilInfo = (e, callback) => {
        e.preventDefault()
        if (s_BusinessNamePerfilInfo.length < 1) {
			Swal.fire({
				    html: "El nombre del negocio es un campo requerido y esta vacio!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
				callback(false)
        }else {
			
            UserDataModel.ActualizarBusinessPerfilInfo(user.user,
				s_BusinessNamePerfilInfo,
				 (res) => {
                if (res === true) {
					const newperfilinfo = Object.assign([{}], [user])
                    newperfilinfo.map((perfil, index)=>{
                       if(perfil.user === user.user){
						perfil.businessName = s_BusinessNamePerfilInfo
					   }
					   return perfil
					})
					p_editarbusinessperfilinfo(newperfilinfo)
                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
					callback(true)
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos ' + res, 'error', 'Aceptar')
                }
            })
        }
    }

	const HandleEliminarMensajeServidor = (Identificador, TituloMensajeServidor) => {
        event.preventDefault();
        alertaConfirmar('Eliminar Mensaje', 'Estas seguro que quieres eliminar este mensaje?', 'question', (res_alert) => {
            if (res_alert === true) {
				if(!socket.connected){
					Swal.fire({
						html: "<font color='#F8C471'>Ups! ah ocurrido un problema con el servidor, al parecer el servidor está fuera de línea en estos momentos o intenta iniciar sesión nuevamente.</font>",
						icon: "warning",
						timer: 13000,
						confirmButtonColor : '#2C3E50',
					})
				}else{
					UserDataModel.BorrarMensajeServidor(Identificador,  (res) => {
						if (res === true) {
							const data = {
								Identificador : Identificador,
								TituloMensajeServidor : TituloMensajeServidor
							}
							socket.emit('ELIMINARMENSAJESERVIDOR', data)
							alertaAceptar('Eliminar Mensaje', 'El mensaje se eliminó correctamente!', 'success', 'Aceptar')
						}
						else {
							alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el mensaje!' + res, 'error', 'Aceptar')
						}

					})
				}
            }})
            
    }

   /**HANDLE CHANGE INICIO */

   const HandleChangeNombreNuevaPlataforma = (e) => {
		SetNombreNuevaPlataforma(e.target.value)
    }

	const HandleChangeMaxPerfilesNuevaPlataforma = (e) => {
		SetMaxPerfilesNuevaPlataforma(e.target.value)
    }

    const HandleChangeNombre = (e) => {
		setCurrentMaxCharNombre(35 - e.target.value.length)
		if (e.target.value.length <= 35) {
			Setnombrecontacto(e.target.value)

		} else {
			setCurrentMaxCharNombre(0)
		}
    }

	const HandleChangeTelefono = (e) => {
		setCurrentMaxCharCelular(9 - e.target.value.length)
		if (e.target.value.length <= 9) {
			Setphonenumber(e.target.value)

		} else {
			setCurrentMaxCharCelular(0)
		}
    }

	const HandleChangeCorreoCuenta = (e) => {
			SetCorreoCuenta(e.target.value)
    }

	const HandleChangePasswordCorreo = (e) => {
		SetPasswordCorreo(e.target.value)
    }

	const HandleChangePasswordCuenta = (e) => {
		SetPasswordCuenta(e.target.value)
    }

	const HandleChangeTipoCuenta = (e) => {
		SetTipoCuentaCodigo(e.target.value)
		SetFechaInicioCuenta(moment(Date.now()).format("yyyy-MM-DDTHH:mm"))
    }

	const HandleChangeFechaInicioCuenta = (e) => {
		SetFechaInicioCuenta(e.target.value)
    }

	const HandleChangeFechaFinCuenta = (e) => {
		SetFechaFinCuenta(e.target.value)
    }

	const HandleChangeExpiracionOpcionalCuenta = (e) => {
		SetExpiracionOpcionalCuenta(e.target.value)
		SetFechaFinCuenta(moment(Date.now() + 86400*e.target.value*1000).format("yyyy-MM-DDTHH:mm"))
    }

	const HandleChangeTipoCuentaPerfil = (e) => {
		SetCorreoPerfil('')
		SetTipoCuentaCodigoPerfil(e.target.value)
    }

	const HandleChangeCantidadPerfiles = (e) => {
		SetCantidadPerfiles(e.target.value)
    }

	const HandleChangeDescripcionPerfil = (e) => {
		SetDescripcionPerfil(e.target.value)
    }

	const HandleChangeCostoPerfil = (e) => {
		SetCostoPerfil(e.target.value)
		SetFechaInicioPerfil(moment(Date.now()).format("yyyy-MM-DDTHH:mm"))
    }

	const HandleChangeFechaInicioPerfil = (e) => {
		SetFechaInicioPerfil(e.target.value)
    }

	const HandleChangeFechaFinPerfil = (e) => {
	    SetFechaFinPerfil(e.target.value)
    }

	const HandleChangeExpiracionOpcionalPerfil = (e) => {
		SetExpiracionOpcionalPerfil(e.target.value)
		SetFechaFinPerfil(moment(Date.now() + 86400*e.target.value*1000).format("yyyy-MM-DDTHH:mm"))
    }

	const HandleChangeFechaFinCombo = (e) => {
	    SetFechaFinPerfilCombo(e.target.value)
    }
	
	const HandleChangeCostoCombo = (e) => {
		SetCostoCombo(e.target.value)
    } 

	const HandleChangeExpiracionOpcionalPerfilCombo = (e) => {
		SetExpiracionOpcionalPerfilCombo(e.target.value)
		SetFechaFinPerfilCombo(moment(Date.now() + 86400*e.target.value*1000).format("yyyy-MM-DDTHH:mm"))
    }

	const HandleChangeTiempoExpCodigo = (e) => {
		let KeyGenerado = uuidv4()
	    let TiempoExpCodigo = e.target.value
		var TiempoFormato = ""
		if(TiempoExpCodigo < 10){
			TiempoFormato = '00' + TiempoExpCodigo
		}else if(TiempoExpCodigo > 9 && TiempoExpCodigo < 100){
			TiempoFormato = '0' + TiempoExpCodigo
		}else{
			TiempoFormato = TiempoExpCodigo
		}
		if (Number(e.target.value) > 999) {
			SetTiempoExpCodigo(999);
		  }else if(Number(e.target.value) < 1){
			SetTiempoExpCodigo(1);
		  }{
			SetTiempoExpCodigo(e.target.value);
		  }
		SetKeyGenerado('COD-' + TiempoFormato + "-" + KeyGenerado)
    }

	/**HANDLE CHANGE FIN */

	const handleclosenuevocontacto = (e) => {
		Setphonenumber("")
		Setnombrecontacto("")
		setCurrentMaxCharNombre("35")
		setCurrentMaxCharCelular("9")
        props.handleclosenuevocontacto(false)
    }

	const handleclosenuevopinnetflix = (e) => {
        props.p_handlecerrarnuevopinnetflix(false)
    }

	const handlecloseplataformas = (e) => {
        props.p_handlecerrarplataformas(false)
    }

	const handleclosenuevacuenta = (e) => {
        props.p_handlecerrarnuevacuenta(false)
		SetCorreoCuenta('')
		SetPasswordCorreo('')
		SetPasswordCuenta('')
		SetTipoCuentaCodigo('')
		SetFechaInicioCuenta('')
		SetFechaFinCuenta('')
		SetExpiracionOpcionalCuenta('')
    }

	const handleclosenuevoperfil = (e) => {
		SetContactoPerfil('')
		SetCorreoPerfil('')
		SetTipoCuentaCodigoPerfil('')
		SetCantidadPerfiles('')
		SetDescripcionPerfil('')
		SetCostoPerfil('')
		SetFechaInicioPerfil('')
		SetFechaFinPerfil('')
		SetExpiracionOpcionalPerfil('')
        props.p_handlecerrarnuevoperfil(false)
    }

	const handlecloseperfilinfo = (e) => {
		SetVerPasswordPerfil(false)
		SetVerificarMiPassword(false)
		SetAbrirEditarPasswordPerfil(true)
		SetAbrirEditarBusinessPerfil(true)
		SetisImageLoaded(false)
        props.p_handlecerrarperfilinfo(false)
    }

    const handleFilterCuentas = p_listacuentas.filter(_c => {
            
			if(s_TipoCuentaCodigoPerfil === _c.TipoCuentaCodigo)
			{
				var MaxPerfiles = 0
					p_listaplataformas.find((p___) => {
						if(p___.Identificador === s_TipoCuentaCodigoPerfil){
							MaxPerfiles = p___.MaximoPerfiles
						}
						})
					if(_c.EstadoUso < MaxPerfiles){
						return _c.TipoCuentaCodigo.includes(s_TipoCuentaCodigoPerfil)
					}else return false
				
			}
        })

	const HandleAbrirEditarPasswordPerfil = (e) =>{
		if(VerificarMiPassword === false){
			Swal.fire({
				title: 'Verificar contraseña',
				input: 'password',
				inputAttributes: {
				  autocapitalize: 'off'
				},
				confirmButtonText: 'Verificar',
				showLoaderOnConfirm: true,
				confirmButtonColor : '#2C3E50',
			}).then((result) => {
			if(result.value === ""){

			} else if (result.value === s_PasswordPerfilInfo) {
				Swal.fire({
					html: "Contraseña verificada con éxito!",
					icon: "success",
					timer: 3500,
					confirmButtonColor : '#2C3E50',
				})
					SetAbrirEditarPasswordPerfil(false)
					SetVerificarMiPassword(true)
					if(s_AbrirEditarPasswordPerfil === false){
						HandleSubmitEditarPasswordPerfilInfo(e, (res)=>{
							if(res === true)
							SetAbrirEditarPasswordPerfil(!s_AbrirEditarPasswordPerfil)
						})
					}		
				}else{
					Swal.fire({
						text: "La contraseña es incorrecta",
						icon: "warning",
						timer: 5500,
						confirmButtonColor : '#2C3E50',
					})
				}
			  })
		}else{
			SetAbrirEditarPasswordPerfil(false)
					if(s_AbrirEditarPasswordPerfil === false){
						HandleSubmitEditarPasswordPerfilInfo(e, (res)=>{
							if(res === true)
							SetAbrirEditarPasswordPerfil(!s_AbrirEditarPasswordPerfil)
						})
					}
		}
		
	}

	const HandleAbrirEditarBusinessPerfil = (e) =>{
		SetAbrirEditarBusinessPerfil(false)
		if(s_AbrirEditarBusinessPerfil === false){
			HandleSubmitEditarBusinessPerfilInfo(e, (res)=>{
				if(res === true)
				SetAbrirEditarBusinessPerfil(!s_AbrirEditarBusinessPerfil)
			})
		}
	}

	const HandleChangeTituloMensajeServidor = (e) => {
		setCurrentMaxCharTituloMensajeServidor(30 - e.target.value.length)
		if (e.target.value.length <= 30) {
			SetTituloMensajeServidor(e.target.value)

		} else {
			setCurrentMaxCharTituloMensajeServidor(0)
		}
    }

	const HandleChangeMensajeServidor = (e) => {
		setCurrentMaxCharMensajeServidor(150 - e.target.value.length)
		if (e.target.value.length <= 150) {
			SetMensajeServidor(e.target.value)

		} else {
			setCurrentMaxCharMensajeServidor(0)
		}
    }

	const HandleChangeCategoriaMensajeServidor = (e) => {
		SetCategoriaMensajeServidor(e.target.value)
	}

	const HandleChangeTituloReporte = (e) => {
		setCurrentMaxCharTituloReporte(30 - e.target.value.length)
		if (e.target.value.length <= 30) {
			SetTituloReporte(e.target.value)

		} else {
			setCurrentMaxCharTituloReporte(0)
		}
    }

	const HandleChangeReporte = (e) => {
		setCurrentMaxCharReporte(250 - e.target.value.length)
		if (e.target.value.length <= 250) {
			SetReporte(e.target.value)

		} else {
			setCurrentMaxCharReporte(0)
		}
    }

	const HandleVerDetallesDeAlertaSistema = (TipoCuentaCodigo, NombreCompleto, Correo, Tipo) =>{
		p_VerDetallesDeAlertaSistema(TipoCuentaCodigo, NombreCompleto, Correo, Tipo)
	}


	const HandleAgregarPlataforma = (value) =>{
		const handleFilterCuentasCombo = p_listacuentas.filter(_c => {
		if(value === _c.TipoCuentaCodigo)
			{
				var MaxPerfiles = 0
					p_listaplataformas.find((p___) => {
						if(p___.Identificador === value){
							MaxPerfiles = p___.MaximoPerfiles
						}
						})
					if(_c.EstadoUso < MaxPerfiles){
						return _c.TipoCuentaCodigo.includes(value)
					}else return false
				
			}
	    })
		if(handleFilterCuentasCombo.length > 0){
			s_CurrentPlataformasCombo.push(handleFilterCuentasCombo[0])
			const NewArray = p_listaplataformascombo.filter(item=>{return item.Identificador !== value})
			s_SetPlataformasCombo(NewArray)
		}else{
			Swal.fire({
				text: "Esta plataforma no tiene cuentas disponibles!",
				icon: "warning",
				timer: 5000,
				confirmButtonColor : '#2C3E50',
			})
		}
	}

	const HandleQuitarPlataforma = (value) =>{
		let CodigoPlataforma = p_listaplataformas.find((__p)=>{
            if(__p.Identificador === value){
				return __p
			}
		})

		p_listaplataformascombo.push(CodigoPlataforma)
		const NewArray = s_CurrentPlataformasCombo.filter(item=>{return item.TipoCuentaCodigo !== value})
		SetCurrentPlataformasCombo(NewArray)
    }

	const HandleSubmitNuevoCombo = (e) => {
		e.preventDefault()
		var CantidadPerfiles = 1
		var DescripcionPerfil = 'Combo'
		var CostoPerfil = Math.round(s_CostoCombo/s_CurrentPlataformasCombo.length)
		var Hoyes = Date.now()
		var FormatFechaFinCombo = new Date(s_FechaFinPerfilCombo).getTime()
		var ExpiracionParaInfoperfilCombo = moment(s_FechaFinPerfilCombo).format("DD-MM-yyyy HH:mm:ss")
		

		if(s_CostoCombo.length < 1 || 
			s_FechaFinPerfilCombo.length < 1 ||
			s_ContactoCombo.length < 1
			){
				Swal.fire({
					text: "Uno o más de los campos requeridos están vacios!",
					icon: "warning",
					timer: 5000,
					confirmButtonColor : '#2C3E50',
				})
			}else if(s_CurrentPlataformasCombo.length < 2){
				Swal.fire({
					text: "El combo debe tener como mínimo 2 plataformas!",
					icon: "warning",
					timer: 5000,
					confirmButtonColor : '#2C3E50',
				})
			}else if(FormatFechaFinCombo < Hoyes){
				Swal.fire({
					html: "La fecha de fin no puede ser menor a hoy!",
					icon: "warning",
					timer: 5500,
					confirmButtonColor : '#2C3E50',
				})
			}else{
				const DataComboInfo = []
				var CorreoPerfil = false
				var Contactoid = ''
				var enPlataforma = ''

				p_listacontactos.find((_c)=>{
					if(_c.NombreCompleto === s_ContactoCombo){
					 return Contactoid = _c.Identificador
					}
				 })

				s_CurrentPlataformasCombo.map((item_pc)=>{
                    p_listaperfiles.find((item)=>{
						if(item.CorreoPerfil === item_pc.CorreoCuenta & item.IdContacto === Contactoid & item_pc.TipoCuentaCodigo === item.TipoCuentaCodigo){
							CorreoPerfil = true
							p_listaplataformas.find((item__)=>{
                                if(item.TipoCuentaCodigo === item__.Identificador){
									enPlataforma = item__.NombrePlataforma
								}
							})
						}
						
				   })
				})

						if(CorreoPerfil === false){
							s_CurrentPlataformasCombo.map((item)=>{
								var TipoCuentaCodigo = ""
								var TipoCuentaNombre = ""
								p_listaplataformas.find((p_____)=>{
									if(p_____.Identificador === item.TipoCuentaCodigo){
										TipoCuentaCodigo = p_____.Identificador
										TipoCuentaNombre = p_____.NombrePlataforma
									}
								})
			
								const CorreoPassword = {
									CorreoCuenta : item.CorreoCuenta,
									PasswordCuenta : item.PasswordCuenta,
									TipoCuentaNombre : TipoCuentaNombre
								}
								DataComboInfo.push(CorreoPassword)
			
								let IdUnico = uuidv4()
								var IdContacto = ""
								var Tipo = 'combo'
								
								p_listacontactos.find((_c)=>{
								   if(_c.NombreCompleto === s_ContactoCombo){
									return IdContacto = _c.Identificador
								   }
								})
								return(
									UserDataModel.NuevoCombo(user.user, 
										IdContacto,
										TipoCuentaCodigo,
										item.CorreoCuenta,
										CantidadPerfiles,
										DescripcionPerfil,
										CostoPerfil,
										Hoyes,
										s_FechaFinPerfilCombo,
										IdUnico,
										Tipo, (res) => {
										if (res === true) {
											const NuevoPerfil = {
												Identificador: IdUnico,
												IdContacto : IdContacto,
												TipoCuentaCodigo: TipoCuentaCodigo,
												CorreoPerfil: item.CorreoCuenta,
												CantidadPerfiles: CantidadPerfiles,
												DescripcionPerfil: DescripcionPerfil,
												CostoPerfil: CostoPerfil,
												FechaInicioPerfil: Hoyes,
												FechaFinPerfil: FormatFechaFinCombo,
												Tipo : Tipo,
												created_at: Date.now()
											}
							
											const NuevoEstadoUso = {
												TipoCuentaCodigo: TipoCuentaCodigo,
												CorreoPerfil: item.CorreoCuenta,
												CantidadPerfiles : CantidadPerfiles,
												Tipo : "Agregar"
											}
											
												p_EditarCuentaEstadoUso(NuevoEstadoUso)
												p_listaperfiles.push(NuevoPerfil)
												p_HandleActualizarTop3Perfiles()
												handleclosenuevocombo()
											
										}else {
											alertaAceptar('Alerta de CastleAPP', 'Hubo un error durante el proceso de guardar los datos!', 'error', 'Aceptar')
										}
						
										if (res === 2) {
											alertaAceptar('Alerta de CastleAPP', 'El contacto <b>' + s_ContactoCombo +'</b> ya está registrado en un combo igual.', 'warning', 'Aceptar')
										}
									
									})
								)
							})
							
							if(DataComboInfo.length === s_CurrentPlataformasCombo.length){
								var CorreosPasswords = []
									for(var i=0;i<=DataComboInfo.length - 1;i++){
										CorreosPasswords.push("<p style='text-align: left; margin-bottom: -8px'><b>Correo " + DataComboInfo[i].TipoCuentaNombre + " :</b> " + DataComboInfo[i].CorreoCuenta + "</p><p style='text-align: left; margin-bottom: -8px'><b>Password " + DataComboInfo[i].TipoCuentaNombre + " :</b> " + DataComboInfo[i].PasswordCuenta) + "</p>"
										}
									Swal.fire({
										title: 'Combo registrado correctamente',
										html: (CorreosPasswords.toString()).replace(/[,]+/g, '') + "<p style='text-align: left'><b>Expiración</b> : " + ExpiracionParaInfoperfilCombo + "</p>",
										icon: "success",
										confirmButtonColor : '#2C3E50'
										 })
							}
						}else{
							Swal.fire({
								title: 'Alerta de CastleAPP',
								html: 'El contacto <b>' + s_ContactoCombo +'</b> ya está registrado en la plataforma <b>' + enPlataforma + '</b> con el mismo correo.',
								icon: "warning",
								confirmButtonColor : '#2C3E50'
								 })

						}
			}
	}

	const handleclosenuevocombo = (e) =>{
		SetContactoCombo("")
		SetCostoCombo("")
		SetFechaFinPerfilCombo("")
		SetExpiracionOpcionalPerfilCombo("")
		SetCurrentPlataformasCombo([])
		p_closenuevocombo(false)
	}


	const handleSetFile = (file) => {
		event.preventDefault();
		SetFile(file)
	}

	const handleSetisImageLoaded = (trueorfalse)=>{
		SetisImageLoaded(trueorfalse)
	}

	const handleChangePerfilImage = async ()=>{
		let NewImageID = uuidv4()
		event.preventDefault();
		SetShowPercent(true)
		SetisImageLoaded(false)
		const formData = new FormData();
		formData.append('file', file);
		formData.append('string', NewImageID);
		try {
			const res = await axios.post('/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					setUploadPercentage(
						parseInt(
							Math.round((progressEvent.loaded * 100) / progressEvent.total)
						)
					);
				}
			});

			// Clear percentage
			setTimeout(() => {
				setUploadPercentage(0)
				SetShowPercent(false)
			}, 3000);

			const { fileName, filePath } = res.data;
			setUploadedFile({ fileName, filePath });
		    p_CambiarImagenPerfil(NewImageID)
			UserDataModel.UpdateUserInfo('update_image_perfil', user.user, NewImageID)
			SetFile('')
		} catch (err) {
			if (err.response.status === 500) {
				alertaAceptar('Alera de Hola APP', 'There was a problem with the server', 'error', 'Aceptar')
			} else {
				alertaAceptar('Alera de Hola APP', `Error ${err.response.status}, es posible que no haya conexion con el servidor.`, 'error', 'Aceptar')
			}
			setUploadPercentage(0)
		}
	}

	const HandleAbrirNuevaPlataforma = () => {
		SetAbrirNuevaPlataforma(true)
	}

	const HandleCerrarNuevaPlataforma = () => {
		SetAbrirNuevaPlataforma(false)
	}

	const HandleOpenEditarPlataformas = (identificador, nombre, maximoperfiles) => {
		SetAbrirEditarPlataforma(true)
		SetIdentificadorEditarPlataforma(identificador)
		SetNombreEditarPlataforma(nombre)
		SetMaxPerfilesEditarPlataforma(maximoperfiles)
	}

	const HandleCerrarEditarPlataformas = () => {
		SetAbrirEditarPlataforma(false)
	}

	const HandleChangeNombreEditarPlataforma = (e) => {
		SetNombreEditarPlataforma(e.target.value)
	}

	const HandleChangeMaxPerfilesEditarPlataforma = (e) => {
		SetMaxPerfilesEditarPlataforma(e.target.value)
	}

	return (

		<>
        {p_miscontactos && (
										
											<Contactos
											p_listacontactos={p_listacontactos}
											p_borrarcontacto={p_borrarcontacto}
											p_editarcontacto={p_editarcontacto}
											user={user.user}
											handleclosenuevocontacto={props.handleclosenuevocontacto}
											handleclosemiscontactos={props.handleclosemiscontactos}
											/>
								)}

		{p_nuevocontacto && (
										
			<div className="modal-contacto">
                <div className="modal-contenido-perfiles">
                    <div className="title-pages-modal">
                        <div className="title_ico_pages_modal">
							<IconButton onClick={handleclosenuevocontacto} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                        </div>
                        <div className="div_title_pages_modal">Nuevo contacto</div>
                    </div>
					<form onSubmit={HandleSubmitNuevoContacto} className='form-modal'>
					<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Nombre Completo" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharNombre}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_nombrecontacto}
						onChange={HandleChangeNombre}
						onKeyPress={PermiteSoloLetras}
						/>

					<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Telefono celular" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharCelular}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_phonenumber}
						onChange={HandleChangeTelefono}
						onKeyPress={PermiteSoloNumeros}
						/>

                    <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
                     
					</form>
                    
                </div>
            </div>
								)}

        {p_mispinesnetflix && (
			<PinesNetflix 
			p_handleclosepinesnetflix={p_handlecerrarmispinesnetflix}
			/>
								)}
		
		{p_nuevopinnetflix && (
			<div className="modal">
			<div className="modal-contenido">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<button className="btn-atras" onClick={handleclosenuevopinnetflix}><IoCloseSharp color="#B4C0C8" size="32" /></button>
					</div>
					<div className="div_title_pages_modal">Nuevo pin netflix</div>
				</div>
				<form className='form-modal'>
				<div className='form-input-container'>
				<label>PIN ID*</label>
				<input name='nombre' className='form-input'></input>
				</div>

				<div className='form-input-container'>
				<label>Pin Code</label>
				<input name='distrito' className='form-input'></input>
				</div>

				<div className='form-input-container'>
				<label>Tipo Pin</label>
				<input name='direccion' className='form-input'></input>
				</div>

				<div className='form-input-container'>
				<Button type="submit" style={{width:150, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdSave />}>Registrar</Button>
				</div>
				 
				</form>
				
			</div>
		</div>
								)}

        {p_miscuentas && (
			<MisCuentas
			user={user.user}
			p_listacuentas={p_listacuentas}
			p_handleclosemiscuentas={p_handlecerrarmiscuentas}
			p_borrarcuenta={p_borrarcuenta}
			p_EditarCuenta={p_EditarCuenta}
			p_usuarioinfo={p_usuarioinfo}
			s_detallesalertasistema={s_detallesalertasistema}
			s_vaciardetallesalertasistema={s_vaciardetallesalertasistema}
			p_listaPlataformas={p_listaplataformas}
			/>
								)}

        {p_nuevacuenta && (
			<div className="modal">
			<div className="modal-contenido">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<IconButton onClick={handleclosenuevacuenta} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
					</div>
					<div className="div_title_pages_modal">Nueva Cuenta</div>
				</div>
				<form  onSubmit={HandleSubmitNuevaCuenta} className='form-modal'>
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Correo" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CorreoCuenta}
						onChange={HandleChangeCorreoCuenta}
						type="email"
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}}
						InputProps={{endAdornment:<IconButton onClick={HandleVerPasswordCorreo}>
                            {VerPasswordCorreo ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
                        </IconButton>}}
						sid="outlined-basic" label="Contraseña del correo" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_PasswordCorreo}
						onChange={HandleChangePasswordCorreo}
						type={VerPasswordCorreo ? 'text':'password'}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}}
						InputProps={{endAdornment:<IconButton onClick={HandleVerPasswordCuenta}>
                            {VerPasswordCuenta ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
                        </IconButton>}}
						sid="outlined-basic" label="Contraseña de la cuenta" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_PasswordCuenta}
						onChange={HandleChangePasswordCuenta}
						type={VerPasswordCuenta ? 'text':'password'}
						/>
                
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tipo de cuenta" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TipoCuentaCodigo}
						onChange={HandleChangeTipoCuenta}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							{p_listaplataformas.map((__p)=>{
								return <MenuItem key={__p.Identificador} value={__p.Identificador}>{__p.NombrePlataforma}</MenuItem>
							})}
								
				</TextField> 

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de inicio" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaInicioCuenta}
						onChange={HandleChangeFechaInicioCuenta}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaFinCuenta}
						onChange={HandleChangeFechaFinCuenta}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración (opcional)" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_ExpiracionOpcionalCuenta}
						onChange={HandleChangeExpiracionOpcionalCuenta}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							<MenuItem key={30} value={30}>1 mes</MenuItem>
							<MenuItem key={60} value={60}>2 meses</MenuItem>
							<MenuItem key={90} value={90}>3 meses</MenuItem>
							<MenuItem key={120} value={120}>4 meses</MenuItem>
							<MenuItem key={150} value={150}>5 meses</MenuItem>
							<MenuItem key={180} value={180}>6 meses</MenuItem>
							<MenuItem key={210} value={210}>7 meses</MenuItem>
							<MenuItem key={240} value={240}>8 meses</MenuItem>
							<MenuItem key={270} value={270}>9 meses</MenuItem>
							<MenuItem key={300} value={300}>10 meses</MenuItem>
							<MenuItem key={330} value={330}>11 meses</MenuItem>
							<MenuItem key={360} value={360}>12 meses</MenuItem>
							
							
				</TextField> 

				<Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
				
				 
				</form>
				
			</div>
		</div>
								)}

        {p_misperfiles && (
			<MisPerfiles
			user={user.user} 
			p_handleclosemisperfiles={p_handlecerrarmisperfiles}
			p_listaperfiles={p_listaperfiles}
			p_listacontactos={p_listacontactos}
			p_EditarCuentaEstadoUso={p_EditarCuentaEstadoUso}
			p_borrarperfil={p_borrarperfil}
			p_listacuentas={p_listacuentas}
			p_EditarPerfil={p_EditarPerfil}
			s_cantidadperfiles={s_cantidadperfiles}
			s_detallesalertasistema={s_detallesalertasistema}
			s_vaciardetallesalertasistema={s_vaciardetallesalertasistema}
			p_listaPlataformas={p_listaplataformas}
			/>
								)}
		
		{p_nuevoperfil && (
			<div className="modal">
			<div className="modal-contenido-perfiles">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<IconButton color='primary' onClick={handleclosenuevoperfil} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
					</div>
					<div className="div_title_pages_modal">Nuevo perfil</div>
					<div className='icon-right'><IconButton onClick={p_handleabrirnuevocontacto} data-title='Agregar contacto' color='primary'
                     style={{background:'#051320', marginLeft: 15}}>
                    <MdPersonAdd color="#B4C0C8" size="24" />
                    </IconButton></div>
					
				</div>
				<form onSubmit={HandleSubmitNuevoPerfil} className='form-modal'>

				<Autocomplete
                    id="Contacto"
                    options={p_listacontactos.map((option)=>option.NombreCompleto)}
					freeSolo
                    renderInput={params => (
                         <TextField {...params} 
						 label="Contacto" 
						 variant="outlined"
						 color='primary'
						 InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						 fontFamily:"Maven Pro, sans-serif"}}}
						 style={{width:'100%', color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}}
						  />
                    )}
                    value={s_ContactoPerfil}
                    onChange={(_event, Identificador) => {
						if(typeof Identificador === 'string'){
							SetContactoPerfil(Identificador);
						}else{
							SetContactoPerfil('')
						}
                             }}
					style={{width:'100%',color:'#c2c2c2',
							 borderRadius:5, background: '#10212D', marginBottom: 10
							}} 
                />

				{
					s_ContactoPerfil != "" ?(
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 20, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tipo de cuenta" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TipoCuentaCodigoPerfil}
						onChange={HandleChangeTipoCuentaPerfil}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							{p_listaplataformas.map((__p)=>{
								return <MenuItem key={__p.Identificador} value={__p.Identificador}>{__p.NombrePlataforma}</MenuItem>
							})}
							
							
				</TextField> 
				):(
					<div className='div-info'>Seleccionar un contacto</div>
				)
			    }

				{
					s_TipoCuentaCodigoPerfil != "" ? (

				<Autocomplete
                    id="correo"
                    options={
						handleFilterCuentas.map((_cuenta) => _cuenta.CorreoCuenta)
					}
					freeSolo
                    renderInput={params => (
                         <TextField {...params} 
						 label="Correo" 
						 variant="outlined"
						 color='primary'
						 InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						 fontFamily:"Maven Pro, sans-serif"}}}
						 style={{width:'100%', color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}}
						  />
                    )}
                    value={s_CorreoPerfil}
                    onChange={(_event, Identificador) => {
						if(typeof Identificador === 'string'){
							SetCorreoPerfil(Identificador);
						}else{
							SetCorreoPerfil('')
						}
                             }}
					style={{width:'100%',color:'#c2c2c2',
							 borderRadius:5, background: '#10212D', marginBottom: 10
							}} 
                />
				):(
					<div className='div-info'>Seleccionar tipo de cuenta</div>
					)
				}
                {
					s_CorreoPerfil !="" ? (
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 20, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Cantidad de perfiles" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CantidadPerfiles}
						onChange={HandleChangeCantidadPerfiles}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
                    {
							   
							   p_listaplataformas.map((p___) => {
								   var MaxPerfiles = 0
								   if(p___.Identificador === s_TipoCuentaCodigoPerfil){
									MaxPerfiles = p___.MaximoPerfiles
								   }

								   var perfilesDisponibles = 0
								   handleFilterCuentas.map((_cuenta, index) => {
										if(s_CorreoPerfil === _cuenta.CorreoCuenta && s_TipoCuentaCodigoPerfil === _cuenta.TipoCuentaCodigo){
										   perfilesDisponibles = MaxPerfiles - _cuenta.EstadoUso
										}
								   })
								   const options = []
								   var i = 1
									 for(i; i<=perfilesDisponibles; i++){
									   options.push(<MenuItem 
										key={i} 
										value={i}>
										{i}
									</MenuItem>)
								   }
										return(
										   options
										 )
							   })
			        }

				</TextField>
				
				):(
					<div className='div-info'>Seleccionar un correo</div>
				)
			    }
                
				<TextField 
						style={{width:'100%',
						 marginTop:0, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Descripción" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_DescripcionPerfil}
						onChange={HandleChangeDescripcionPerfil}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Costo" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16, paddingLeft:3
					    }}}
						InputProps={{startAdornment:'S/.', style:{color:'#c2c2c2', 
						fontSize:17, fontFamily:"Maven Pro, sans-serif"}}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CostoPerfil}
						onChange={HandleChangeCostoPerfil}
						onKeyPress={PermiteSoloNumeros}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de inicio" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaInicioPerfil}
						onChange={HandleChangeFechaInicioPerfil}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaFinPerfil}
						onChange={HandleChangeFechaFinPerfil}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración (opcional)" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_ExpiracionOpcionalPerfil}
						onChange={HandleChangeExpiracionOpcionalPerfil}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							<MenuItem key={30} value={30}>1 mes</MenuItem>
							<MenuItem key={60} value={60}>2 meses</MenuItem>
							<MenuItem key={90} value={90}>3 meses</MenuItem>
							<MenuItem key={120} value={120}>4 meses</MenuItem>
							<MenuItem key={150} value={150}>5 meses</MenuItem>
							<MenuItem key={180} value={180}>6 meses</MenuItem>
							<MenuItem key={210} value={210}>7 meses</MenuItem>
							<MenuItem key={240} value={240}>8 meses</MenuItem>
							<MenuItem key={270} value={270}>9 meses</MenuItem>
							<MenuItem key={300} value={300}>10 meses</MenuItem>
							<MenuItem key={330} value={330}>11 meses</MenuItem>
							<MenuItem key={360} value={360}>12 meses</MenuItem>
							
							
				</TextField> 

				<Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
				 
				</form>
				
			</div>
		</div>
								)}

        {p_backup && (
			<Backup 
			p_handleclosebackup={p_handlecerrarbackup}
			/>
			                    )}
		
		{p_nuevocodigo && (

			<div className="modal">
			<div className="modal-contenido">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<IconButton color='primary' onClick={p_handlecerrarnuevocodigo} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
					</div>
					<div className="div_title_pages_modal">Nuevo código</div>
				</div>
				{ esADMIN ?
				<form onSubmit={HandleSubmitNuevoCodigo} className='form-modal'>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tiempo de expiración en días" variant="outlined"
						id="formatted-numberformat-input"
						autoComplete="off"
						color='primary'
						InputProps={{ inputProps: { min: "1", max: "999", step: "1" }, style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    } }}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='number'
						value={s_TiempoExpCodigo}
						onChange={HandleChangeTiempoExpCodigo}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Código generado" variant="outlined"
						id="formatted-numberformat-input"
						autoComplete="off"
						color='primary'
						InputProps={{ style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    } }}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						multiline
						rows={2}
						maxRows={2}
						minRows={2}
						value={s_KeyGenerado}
						/>

                         <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
				 
				</form>:<div className='sin-autoridad'>
				<Alert className='effect' 
				style={{marginBottom: 10}} 
				severity='warning'>
				<AlertTitle style={{fontWeight: 'bolder', fontSize: 18}}><b>Contenido no autorizado!</b></AlertTitle>
				<b>Alerta del sistema : </b>No tienes autoridad suficiente para poder ver este contenido!
				</Alert>
					</div>

				}
				
				
			</div>
		    </div>
	    )}

        {p_miskeydeactivacion && (
			<MisCodigos
			p_listakeys={p_listacodigos}
			p_borrarkey={p_borrarkey}
			handleclosemiskeys={props.handleclosemiskeys}
			esADMIN={esADMIN}
			/>
								)}

        {p_misusuarios && (
			<MisUsuarios 
			user={user}
			socket={socket}
			p_handleclosemisusuarios={p_handlecerrarmisusuarios}
			p_listausuarios={p_listausuarios}
			p_listacodigos={p_listacodigos}
			esADMIN={esADMIN}

			/>
			                    )}

        {p_abrirperfilinfo && (
			<div className="modal">
			<div className="modal-contenido">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<IconButton color='primary' onClick={handlecloseperfilinfo} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
					</div>
					<div className="div_title_pages_modal">Mi perfil</div>
				</div>
				<form className='form-modal'>
					<div className='perfil'>
				<div className="div_image_perfil">
					<FileUpPersonal
						file={handleSetFile}
						uploadPercentage={uploadPercentage}
						uploadedFile={uploadedFile}
						showPercent={showPercent}
						currentPhoto={user.image}
						handleSetisImageLoaded={handleSetisImageLoaded}
					/>
					<div className={PreViewCambiarFotoDePerfil ? "ver_preview" : "ver_preview_off"}>
						<p className="p_text_cambiar_foto"><i><IoMdCamera size="36" /></i> <br />CAMBIAR PERFIL</p>
					</div>
				</div>

				<div className="btn-upfile-div">
					<IconButton
					    color='primary'
						disabled={isImageLoaded ? false:true}
						onClick={handleChangePerfilImage}
						style={{background: '#1b2633'}}
					    className={!isImageLoaded && 'off'}
					>
						<IoSaveSharp color="#B4C0C8" size="24" />
					</IconButton>
				</div>
				</div>

				<Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Usuario no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Usuario" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={user.user}
						/>
				</Tooltip>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Contraseña" variant="outlined" 
						InputProps={{endAdornment:
						<>{VerificarMiPassword && <><span className='max-length'>{s_CurrentMaxCharPasswordPerfilInfo}</span><IconButton color='primary' onClick={HandleVerPasswordPerfil}>
						{VerPasswordPerfil ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
					    </IconButton></>}
						<IconButton color='primary' onClick={HandleAbrirEditarPasswordPerfil}>
							{s_AbrirEditarPasswordPerfil ? <MdModeEdit color='#c2c2c2' size={24}/>:<IoMdSave color='#c2c2c2' size={24}/>}
						</IconButton></>
						}}

						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly: s_AbrirEditarPasswordPerfil ? true:false}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={VerificarMiPassword ? s_PasswordPerfilInfo:PasswordAleatorio}
						onChange={s_HandleChangePasswordPerfilInfo}
						type={VerPasswordPerfil ? 'text':'password'}
						/>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Estado de la cuenta no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Estado de la cuenta" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={user.state === 1 ? 'ACTIVO':'INACTIVO'}
						/>
                </Tooltip>
				<Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Autoridad no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Autoridad" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={
							user.autority === 10 ? 'SUPER ADMIN':
							user.autority === 5 ? 'ADMINISTRADOR':
							user.autority === 1 && 'REGISTRADO'
						}
						/>
                </Tooltip>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Nombre de mi negocio" variant="outlined" 
						InputProps={{endAdornment:
							<><span className='max-length'>{s_CurrentMaxCharBusinessPerfil}</span>
							<IconButton color='primary' onClick={HandleAbrirEditarBusinessPerfil}>
								{s_AbrirEditarBusinessPerfil ? <MdModeEdit color='#c2c2c2' size={24}/>:<IoMdSave color='#c2c2c2' size={24}/>}
							</IconButton>
							</>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly: s_AbrirEditarBusinessPerfil ? true:false}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						onChange={s_HandleChangeBusinessNamePerfilInfo}
						value={s_BusinessNamePerfilInfo}
						/>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Expiración de la cuenta no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow  
				>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Expiración de la cuenta" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={CalcularExpiracion(user.expiration)}
						/>
				</Tooltip>
				</form>
				
			</div>
		</div>
								)}
		
		{s_AbrirNuevaPlataforma && (
										
										<div className="modal-contacto">
											<div className="modal-contenido-perfiles">
												<div className="title-pages-modal">
													<div className="title_ico_pages_modal">
														<IconButton onClick={HandleCerrarNuevaPlataforma} 
																					style={{background:'#051320', marginLeft: 15}}>
																						<IoCloseSharp color="#B4C0C8" size="24" />
																					</IconButton>
													</div>
													<div className="div_title_pages_modal">Nueva plataforma</div>
												</div>
												<form onSubmit={HandleSubmitNuevaPlataforma} className='form-modal'>
												<TextField 
													style={{width:'100%',
													 marginTop:10, marginBottom: 10, color:'#c2c2c2',
													 borderRadius:5, background: '#10212D'
													}} 
													sid="outlined-basic" label="Nombre de la plataforma" variant="outlined" 
													autoComplete="off"
													color='primary'
													inputProps={{style:{color:'#c2c2c2',
													fontFamily:"Maven Pro, sans-serif",
													fontSize:16
													}}}
													InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
													fontFamily:"Maven Pro, sans-serif"}}}
													value={s_NombreNuevaPlataforma}
													onChange={HandleChangeNombreNuevaPlataforma}
													/>
							
												<TextField 
													style={{width:'100%',
													 marginTop:10, marginBottom: 10, color:'#c2c2c2',
													 borderRadius:5, background: '#10212D'
													}} 
													sid="outlined-basic" label="Máximo de perfiles" variant="outlined" 
													autoComplete="off"
													color='primary'
													inputProps={{style:{color:'#c2c2c2',
													fontFamily:"Maven Pro, sans-serif",
													fontSize:16
													}}}
													InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
													fontFamily:"Maven Pro, sans-serif"}}}
													value={s_MaxPerfilesNuevaPlataforma}
													onChange={HandleChangeMaxPerfilesNuevaPlataforma}
													onKeyPress={PermiteSoloNumeros}
													/>
							
												<Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
												 
												</form>
												
											</div>
										</div>
		)}

        {s_AbrirEditarPlataforma && (
										
										<div className="modal-contacto">
											<div className="modal-contenido-perfiles">
												<div className="title-pages-modal">
													<div className="title_ico_pages_modal">
														<IconButton color='primary' onClick={HandleCerrarEditarPlataformas} 
																					style={{background:'#051320', marginLeft: 15}}>
																						<IoCloseSharp color="#B4C0C8" size="24" />
																					</IconButton>
													</div>
													<div className="div_title_pages_modal">Editar plataforma</div>
												</div>
												<form onSubmit={HandleSubmitEditarPlataforma} className='form-modal'>
												<TextField 
													style={{width:'100%',
													 marginTop:10, marginBottom: 10, color:'#c2c2c2',
													 borderRadius:5, background: '#10212D'
													}} 
													sid="outlined-basic" label="Nombre de la plataforma" variant="outlined" 
													autoComplete="off"
													color='primary'
													inputProps={{style:{color:'#c2c2c2',
													fontFamily:"Maven Pro, sans-serif",
													fontSize:16
													}}}
													InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
													fontFamily:"Maven Pro, sans-serif"}}}
													value={s_NombreEditarPlataforma}
													onChange={HandleChangeNombreEditarPlataforma}
													/>
							
												<TextField 
													style={{width:'100%',
													 marginTop:10, marginBottom: 10, color:'#c2c2c2',
													 borderRadius:5, background: '#10212D'
													}} 
													sid="outlined-basic" label="Máximo de perfiles" variant="outlined" 
													autoComplete="off"
													color='primary'
													inputProps={{style:{color:'#c2c2c2',
													fontFamily:"Maven Pro, sans-serif",
													fontSize:16
													}}}
													InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
													fontFamily:"Maven Pro, sans-serif"}}}
													value={s_MaxPerfilesEditarPlataforma}
													onChange={HandleChangeMaxPerfilesEditarPlataforma}
													onKeyPress={PermiteSoloNumeros}
													/>
							
												<Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdUpdate size={28} />}>Actualizar</Button>
												 
												</form>
												
											</div>
										</div>
		)}




        {p_plataformas && (

			<div className="modal">
			<div className="modal-contenido-plataformas">
				<div className="title-pages-modal">
					<div className="title_ico_pages_modal">
						<IconButton color='primary' onClick={handlecloseplataformas} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
					</div>
					<div className="div_title_pages_modal">Mis plataformas</div>
				</div>
				<form className='form-modal-plataformas'>
					<div className='container-plataformas'>

					{
						p_listaplataformas.map((item)=>{
							return(
								<div className='item-plataformas' key={item.Identificador}>
								<span style={{top: -12, 
									position: 'relative', 
									marginLeft: 0, 
									fontSize: 15, 
									paddingBottom: 8, 
									paddingTop: 8, 
									paddingLeft: 12, 
									paddingRight: 12, 
									background: '#091422', 
									borderRadius: 10 }}>
										{item.NombrePlataforma}
									</span>

									<IconButton
									style={{top: -21, 
										position: 'relative', 
										float: 'right', 
										background: '#091422'}}
									color='primary' 
									data-title={'Editar ' + item.NombrePlataforma +''}
									onClick={() => HandleOpenEditarPlataformas(item.Identificador, item.NombrePlataforma, item.MaximoPerfiles)}
									>
										<MdEdit color="#FFD75B" size={32} style={{cursor:'pointer'}}/>
									</IconButton>
                        <TextField
						style={{color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Nombre de la plataforma" variant="outlined" 
						InputProps={{startAdornment:<IoMdSettings size={32} style={{paddingRight:6, color:'#c2c2c2'}} />}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={item.NombrePlataforma}
						/>

						<TextField
						style={{
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Máximo de perfiles" variant="outlined" 
						InputProps={{startAdornment:<IoMdSettings size={32} style={{paddingRight:6, color:'#c2c2c2'}} />}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={item.MaximoPerfiles}
						onKeyPress={PermiteSoloNumeros}
						/>
						</div>
							)
						})
					}
				    
                {
					p_listaplataformas.length < 12 && 
					<Button disabled={p_listaplataformas.length >= 12 && true} onClick={HandleAbrirNuevaPlataforma} style={{ height:75, marginLeft: 10, marginTop: 28, marginRight: 'auto'}} size='small' variant="contained" color="info" startIcon={<FaFolderPlus size={28} />}>Agregar plataforma</Button>
				}
             
                
				</div>
				<Alert
				severity='info'
				variant={'outlined'}
				sx={[ 
				{
				'&.MuiAlert-outlinedInfo': {
				color: '#30A0EA',
				marginTop: 2
				},
				fontFamily: 'Maven Pro, sans-serif',
				float: 'left',
				marginTop: 15
				}
				]}>
					<AlertTitle style={{fontSize:20}}>Importante</AlertTitle>
				 *Edita la cantidad máxima de perfiles con criterio, esto se reflejará al momento de registrar un combo o perfil.<br></br>
                 *La cantidad máxima de plataformas es 12.<br></br>
				 *Las plataformas no se pueden eliminar, sólo se pueden editar.
				</Alert>
				
				</form>
			</div>
			
			
		</div>
								)}
		{
           p_mensajedelservidor && (
			<div className="modal">
                <div className="modal-contenido">
                    <div className="title-pages-modal">
                        <div className="title_ico_pages_modal">
							<IconButton color='primary' onClick={s_cerrarmensajedelservidor}
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                        </div>
                        <div className="div_title_pages_modal">Crear mensaje</div>
                    </div>
					{ esADMIN ?
					<form onSubmit={HandleSubmitNuevoMensajeServidor} className='form-modal'>
					<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Título del mensaje" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharTituloMensajeServidor}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TituloMensajeServidor}
						onChange={HandleChangeTituloMensajeServidor}
						/>

                    <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Categoria" variant="outlined"
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						value={s_CategoriaMensajeServidor}
						onChange={HandleChangeCategoriaMensajeServidor}
						select
						>
					<MenuItem value={'error'}>Error</MenuItem>
					<MenuItem value={'warning'}>Alerta</MenuItem>
					<MenuItem value={'info'}>Información</MenuItem>
					<MenuItem value={'success'}>Correcto</MenuItem>
					</TextField>
                    <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Mensaje" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharMensajeServidor}
						{s_CategoriaMensajeServidor === 'error' && <MdError size={24} color={'#E74C3C'} />}
						{s_CategoriaMensajeServidor === 'warning' && <MdWarning size={24} color={'#F5B041'} />}
						{s_CategoriaMensajeServidor === 'info' && <MdInfo size={24} color={'#5DADE2'} />}
						{s_CategoriaMensajeServidor === 'success' && <MdCheckCircle size={24} color={'#2ECC71'} />}
						</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						multiline
						maxRows={5}
						minRows={5}
						value={s_MensajeServidor}
						onChange={HandleChangeMensajeServidor}
						/>

					

                    <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Registrar</Button>
                     
					</form>:<div className='sin-autoridad'>
				<Alert className='effect' 
				style={{marginBottom: 10}} 
				severity='warning'>
				<AlertTitle style={{fontWeight: 'bolder', fontSize: 18}}><b>Contenido no autorizado!</b></AlertTitle>
				<b>Alerta del sistema : </b>No tienes autoridad suficiente para poder ver este contenido!
				</Alert>
					</div>
                    }
                    
                </div>
            </div>

		   )}

        {
           p_reportar && (
			<div className="modal">
                <div className="modal-contenido">
                    <div className="title-pages-modal">
                        <div className="title_ico_pages_modal">
							<IconButton color='primary' onClick={s_cerrarreportar}
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                        </div>
                        <div className="div_title_pages_modal">Crear reporte</div>
                    </div>
					<form className='form-modal'>
					<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Título del reporte" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharTituloReporte}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TituloReporte}
						onChange={HandleChangeTituloReporte}
						/>

                    <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Descripción" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharReporte}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						multiline
						maxRows={8}
						minRows={8}
						value={s_Reporte}
						onChange={HandleChangeReporte}
						/>

					

                    <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="error" startIcon={<MdSave size={28} />}>Reportar</Button>
                     
					</form>
                    
                </div>
            </div>

		   )}

		{
           p_NuevoCombo && (
			<div className="modal">
                <div className="modal-contenido-combo">
                    <div className="title-pages-modal-combo">
                        <div className="title_ico_pages_modal">
							<IconButton onClick={handleclosenuevocombo} color='primary'
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                        </div>
                        <div className="div_title_pages_modal">Crear combo {s_CurrentPlataformasCombo.length > 0 ? s_CurrentPlataformasCombo.length:''}</div>
						<div className='icon-right'><IconButton onClick={p_handleabrirnuevocontacto} data-title='Agregar contacto' color='primary'
                     style={{background:'#051320', marginLeft: 15}}>
                    <MdPersonAdd color="#B4C0C8" size="24" />
                    </IconButton></div>
                    </div>
				<div className='container-combo'>
				
				<div className='current_plataforms'>
					{
                         p_listaplataformascombo.map(p___ => {
							var EstadoUso = 0
							var NombrePlataforma = ""
							p_listacuentas.find((_c) => {
								if(p___.Identificador === _c.TipoCuentaCodigo){
									if(_c.EstadoUso < p___.MaximoPerfiles)
									{
										EstadoUso = _c.EstadoUso
										NombrePlataforma = p___.NombrePlataforma
									}
								}
								})
								if(EstadoUso < p___.MaximoPerfiles & NombrePlataforma != ""){
									return <div key={p___.Identificador} onClick={() => HandleAgregarPlataforma(p___.Identificador)} className='cuentas-container-div-item-combo'>{NombrePlataforma}</div>
								}else{
									return (<><Tooltip title={
										<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
											<MdEditOff size={18} style={{marginRight: 5}}/> 
											{p___.NombrePlataforma + " no tiene cuentas disponibles."}
											</div>}><div key={p___.Identificador} className='cuentas-container-div-item-combo-disabled'>{p___.NombrePlataforma}</div></Tooltip></>)
								}
								
						})
					}
				</div>
					<form onSubmit={HandleSubmitNuevoCombo} className='form-modal-combo'>

					<Autocomplete
                    id="Contacto"
                    options={p_listacontactos.map((option)=>option.NombreCompleto)}
					freeSolo
                    renderInput={params => (
                         <TextField {...params} 
						 label="Contacto"
						 variant="outlined"
						 color='primary'
						 InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						 fontFamily:"Maven Pro, sans-serif"}}}
						 style={{width:'100%', color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}}
						  />
                    )}
                    value={s_ContactoCombo}
                    onChange={(_event, Identificador) => {
						if(typeof Identificador === 'string'){
							SetContactoCombo(Identificador);
						}else{
							SetContactoCombo('')
						}
                             }}
					style={{width:'100%',color:'#c2c2c2',
							 borderRadius:5, background: '#10212D', marginBottom: 10
							}} 
                />
				<label className='label-plataformas'>Combo seleccionado</label>
				<div className='current_combo'>

				    {
                         s_CurrentPlataformasCombo.map((item_current)=>{
							var NombrePlataforma = ""
							p_listaplataformas.find((p___)=>{
								if(item_current.TipoCuentaCodigo === p___.Identificador){
									NombrePlataforma = p___.NombrePlataforma
								}
							})

							return (
								<div key={item_current.TipoCuentaCodigo} onClick={() => HandleQuitarPlataforma(item_current.TipoCuentaCodigo)} className='cuentas-container-div-item-combo-2'>{NombrePlataforma}</div>
							)
						 })
					}


				</div>
				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Costo del combo" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16, paddingLeft:3
					    }}}
						InputProps={{startAdornment:'S/.', style:{color:'#c2c2c2', 
						fontSize:17, fontFamily:"Maven Pro, sans-serif"}}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CostoCombo}
						onChange={HandleChangeCostoCombo}
						onKeyPress={PermiteSoloNumeros}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaFinPerfilCombo}
						onChange={HandleChangeFechaFinCombo}
						/>

				<TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración (opcional)" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_ExpiracionOpcionalPerfilCombo}
						onChange={HandleChangeExpiracionOpcionalPerfilCombo}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							<MenuItem key={30} value={30}>1 mes</MenuItem>
							<MenuItem key={60} value={60}>2 meses</MenuItem>
							<MenuItem key={90} value={90}>3 meses</MenuItem>
							<MenuItem key={120} value={120}>4 meses</MenuItem>
							<MenuItem key={150} value={150}>5 meses</MenuItem>
							<MenuItem key={180} value={180}>6 meses</MenuItem>
							<MenuItem key={210} value={210}>7 meses</MenuItem>
							<MenuItem key={240} value={240}>8 meses</MenuItem>
							<MenuItem key={270} value={270}>9 meses</MenuItem>
							<MenuItem key={300} value={300}>10 meses</MenuItem>
							<MenuItem key={330} value={330}>11 meses</MenuItem>
							<MenuItem key={360} value={360}>12 meses</MenuItem>
							
							
				</TextField> 
					
                    <Button type='submit' fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="primary" startIcon={<MdSave size={28} />}>Guardar</Button>
                     
					</form>
				    </div>
                </div>
            </div>

		   )}
								{
									s_portal && 
									<div className='main-right-portal'>
									<div className='portal-titulo'><IoFootsteps size={64} style={{marginRight: 10}}/> Castle APP</div>
									<div className='portal-c-c-p'>
                                        <div className='sub-portal-titulo'>Mis datos generales</div>
										<div className='portal-c-c-p-contactos'><MdGroup size={45}/> Contactos <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingcontactos ? <Box sx={{ display: 'flex' }}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:p_listacontactos.length}</span></div>
										<div className='portal-c-c-p-cuentas'><MdAccountBalance size={45}/> Cuentas <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingcuentas ? <Box sx={{ display: 'flex' }}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:p_listacuentas.length}</span></div>
										<div className='portal-c-c-p-perfiles'><IoTv size={45}/> Perfiles <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingperfiles ? <Box sx={{ display: 'flex' }}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:s_cantidadperfiles}</span></div>
									</div>
									<div className='portal-tops'>
									    <div className='sub-portal-titulo'>Plataformas TOP 3</div>
										<div className='portal-c-c-p-contactos'><MdLooksOne color='#EABE3F' size={45}/>{s_isloadingperfiles ? "":s_Top1[0].Tipo === '' ? 'Sin datos':s_Top1[0].Tipo} <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingperfiles ? "":s_Top1[0].CantidadPerfiles}</span><span style={{fontSize:14}}>{s_isloadingperfiles ? <Box sx={{ display: 'flex'}}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:s_Top1[0].CantidadPerfiles === 1 ? 'perfil':'perfiles'}</span></div>
										<div className='portal-c-c-p-cuentas'><MdLooksTwo color='#C0C0C0' size={45}/> {s_isloadingperfiles ? "":s_Top2[0].Tipo === '' ? 'Sin datos':s_Top2[0].Tipo} <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingperfiles ? "":s_Top2[0].CantidadPerfiles}</span><span style={{fontSize:14}}>{s_isloadingperfiles ? <Box sx={{ display: 'flex'}}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:s_Top2[0].CantidadPerfiles === 1 ? 'perfil':'perfiles'}</span></div>
										<div className='portal-c-c-p-perfiles'><MdLooks3 color='#CD7F32' size={45}/> {s_isloadingperfiles ? "":s_Top3[0].Tipo === '' ? 'Sin datos':s_Top3[0].Tipo} <span style={{marginTop: 5, fontSize: 26}} className='effect'>{s_isloadingperfiles ? "":s_Top3[0].CantidadPerfiles}</span><span style={{fontSize:14}}>{s_isloadingperfiles ? <Box sx={{ display: 'flex'}}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:s_Top3[0].CantidadPerfiles === 1 ? 'perfil':'perfiles'}</span></div>
									</div>
									<div className='portal-ganancias'>
									    <div className='sub-portal-titulo'>Ganancias del mes</div>
										<div className='portal-ganancias-icon'><IoBarChartSharp size={64}/></div>
										<div className='portal-ganancias-money'>
											<span>{ObtenerNombreDelMesActual()}</span>
											<span className='effect'>{s_isloadingperfiles ? <Box sx={{ display: 'flex' }}>
                                                                                     <CircularProgress style={{height:28, width:28}} />
                                                                          </Box>:currencyFormat(s_gananciamesactual)}</span>
										</div>
									</div>
									<div className='portal-alerta-sistema'>
									    <div className='sub-portal-titulo'>Alertas del sistema</div>
										<div className='alert-contain'>
											{s_isloadingalertasservidor ? <Box sx={{ width: 300 }}>
                                                                            <Skeleton style={{background:'#2C3E50', height:40}} width={40} variant={'circular'} animation="pulse" />
                                                                        <Skeleton style={{background:'#2C3E50', height:40}} animation="pulse" />
                                                                      <Skeleton style={{background:'#2C3E50', height:40}} animation="pulse" />
                                                                          </Box>:
												s_PerfilesVencidos.length > 0 ? 
												s_PerfilesVencidos.map((item, index)=>{
													var tipo = ''
													if(item.Tipo === 'perfil'){
														tipo = 'Perfil caducado'
													}else if(item.Tipo === 'cuenta'){
														tipo = 'Cuenta caducada'
													}
													var NombreContacto = ""
													var NombreTipoCuenta = ""
													p_listacontactos.find((__c)=>{
														if(__c.Identificador === item.IdContacto)
														NombreContacto = __c.NombreCompleto
													})

													p_listaplataformas.find((p__)=>{
														if(p__.Identificador === item.TipoCuentaCodigo){
															NombreTipoCuenta = p__.NombrePlataforma
														}

													})

													return (
														<>
                                                       <Alert
													   variant={'outlined'}
													   sx={[ 
														{
														  '&.MuiAlert-outlinedWarning': {
															color: '#FFAE52',
														  },
														  fontFamily: 'Maven Pro, sans-serif'
														}
													  ]}
													   action={
																  
																  <IconButton 
																  onClick={() => HandleVerDetallesDeAlertaSistema(item.TipoCuentaCodigo, NombreContacto, item.Correo, item.Tipo)} 
																  color='primary'  
																  data-title='Ver detalles'><IoMdEye size={24} color={'#FFAE52'}/></IconButton>
															  } 
													   className='effect' 
													   key={item.Identificador} 
													   style={{marginBottom: 10}}
													   severity="warning">
														<AlertTitle style={{fontWeight: 'bolder', fontSize: 17, fontFamily: 'Maven Pro, sans-serif'}}>{tipo}</AlertTitle>
														{
															item.Tipo === 'perfil' ? 
															<>El perfil <b>{NombreTipoCuenta}</b> de <b>{NombreContacto}</b> {setUnixToTimeFormat('fecha_completa_alerta', item.FechaExpiracion)}</>:
															<>La cuenta <b>{item.Correo}</b> de la plataforma de <b>{NombreTipoCuenta}</b> {setUnixToTimeFormat('fecha_completa_alerta', item.FechaExpiracion)}</>

														}
														 
														 </Alert>
														 
													   </>
													   )

												}):<Alert variant={'outlined'}
												sx={[ 
												   {
													 '&.MuiAlert-outlinedSuccess': {
													   color: '#2ECC71'
													 },
													 fontFamily: 'Maven Pro, sans-serif',
												   }
												 ]}
												className='effect' 
												style={{marginBottom: 10}} 
												severity="success"> <b>Todo está en orden,</b> aún no hay alertas del sistema. Aquí podrás ver cuentas y perfiles cuando hayan caducado.</Alert>
											}
										
										</div>
									</div>
									<div className='portal-alerta-servidor'>
									    <div className='sub-portal-titulo'>Alertas del servidor</div>
										<div className='alert-contain'>
											{s_isloadingalertasservidor ? <Box sx={{ width: 300 }}>
                                                                            <Skeleton style={{background:'#2C3E50', height:40}} width={40} variant={'circular'} animation="pulse" />
                                                                        <Skeleton style={{background:'#2C3E50', height:40}} animation="pulse" />
                                                                      <Skeleton style={{background:'#2C3E50', height:40}} animation="pulse" />
                                                                          </Box>:
											   p_listamensajesservidor.length > 0 ?
											    p_listamensajesservidor.sort((a,b) => a.TiempoExpiracion - b.TiempoExpiracion)
												.map((item)=>{
													var Identificador = item.Identificador
													var TituloMensajeServidor = item.TituloMensajeServidor
													var CategoriaMensajeServidor = item.CategoriaMensajeServidor
													var MensajeServidor = item.MensajeServidor
													var TiempoExpiracion = item.TiempoExpiracion
													var created_at = item.created_at
													return (
														<Alert
														variant={'outlined'}
														sx={[ 
															{
															  '&.MuiAlert-outlinedSuccess': {
																color: '#2ECC71'
															  },
															  '&.MuiAlert-outlinedWarning': {
																color: '#FFAE52'
															  },
															  '&.MuiAlert-outlinedError': {
																color: '#E74C3C'
															  },
															  '&.MuiAlert-outlinedInfo': {
																color: '#30A0EA'
															  },
															  fontFamily: 'Maven Pro, sans-serif',
															}
														  ]}
														className='effect' 
															style={{marginBottom: 10}} 
															key={Identificador} 
															severity={CategoriaMensajeServidor}
															action={
																  <><div className='expiration-alert'><span>expiración</span> <span>{CalcularExpiracion(TiempoExpiracion)}</span></div>
																  {esADMIN && <><IconButton color='primary'  data-title='Editar'><MdEdit color="#D4A71B"/></IconButton>
																  <IconButton onClick={() => HandleEliminarMensajeServidor(Identificador, TituloMensajeServidor)} color='primary'  data-title='Eliminar'><MdDelete color="#C64141"/></IconButton></>}
																  </>
															  }>
															<AlertTitle style={{fontWeight: 'bolder', fontSize: 17}}>{TituloMensajeServidor}</AlertTitle>
															{MensajeServidor}
															</Alert>
														    
													)
												}):<Alert variant={'outlined'}
												sx={[ 
												   {
													 '&.MuiAlert-outlinedSuccess': {
													   color: '#2ECC71'
													 },
													 fontFamily: 'Maven Pro, sans-serif',
												   }
												 ]}
												className='effect' 
												style={{marginBottom: 10}} 
												severity="success"> <b>Todo está en orden,</b> aún no hay alertas del servidor. Aquí podrás ver avisos importantes, promociones, actualizaciones.</Alert>
											}
										</div>
									</div>
								</div>
								}
								

		</>
	)
}