import { IconButton } from '@mui/material';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { IoMdContact } from 'react-icons/io';

import { MdAccountCircle, MdDelete, MdInfo, MdModeEdit, MdSupervisorAccount } from 'react-icons/md';
import { setUnixToTimeFormat } from '../../../Funciones';


export default function ListaContactos(props) {
    const { 
        listaContactos,
    } = props
    return (
        <div className="container_info_contactos">
        {props.listaContactos.length > 0 ? (
            <div className="group_items">
             
                    {
                        listaContactos.sort((a,b) => b.created_at - a.created_at)
                        .map((contacto, index) => {
                            var contact_number = contacto.NumeroCelular
                            var contact_name = contacto.NombreCompleto
                            var created_at = contacto.created_at
                            var Identificador = contacto.Identificador
                            return (
                                <div
                                    key={contact_number}
                                    className="item_container"
                                >
                                <div className="item-block">
                                    <div className='icon_left'>
                                            <MdAccountCircle size={45}/>
                                    </div>

                                    <div className='item-left'>
                                        <div className="item_list">
                                            {contact_name}
                                        </div>
                                        <div className="item_list_fecha">
                                            {setUnixToTimeFormat('info_contacto', created_at)}
                                        </div>
                                        <div className="sub_item_list">
                                            {contact_number}
                                        </div>
                                    </div>
                                        
                                    <div className='icon_right'>
                                        <IconButton onClick={() => props.handleOpenEditContact(index, Identificador, contact_number, contact_name)} color='primary' data-title='Editar'>
                                            <MdModeEdit
                                                color="#41C681"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Editar'
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => props.handleRemoveContact(index, Identificador, contact_name)} color='primary' data-title='Eliminar'>
                                            <MdDelete
                                                color="#C64141"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Eliminar'
                                            />
                                        </IconButton>
                                    </div>
                                </div>

                                </div>

                            )
                        })
                    }
            </div>
):(<div className='sin_datos'><div className='sin_datos_left'><MdInfo color='#F1C40F' size={64}/></div><div className='sin_datos_right'> No se ah encontrado ningún dato registrado!</div></div>)
}
        </div>
    )
}