import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import moment from 'moment'
import 'moment-timezone'
import UserDataModel from '../../../models/UserDataModel';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import { Grid, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material';
import { MdEditOff, MdLock, MdSave, MdSearch, MdUpdate } from 'react-icons/md';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import ListaMisPerfiles from './ListaMisPerfiles';
import { PermiteSoloNumeros } from '../../../Funciones';
import { IoMdLock } from 'react-icons/io';
import { upperCase } from 'lodash';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function MisPerfiles(props) {
    const { 
        user, 
        p_handleclosemisperfiles,
        p_listaperfiles,
        p_listacontactos,
        p_listacuentas,
        p_borrarperfil,
        p_EditarCuentaEstadoUso,
        p_EditarPerfil,
        s_cantidadperfiles,
        s_detallesalertasistema,
        s_vaciardetallesalertasistema,
        p_listaPlataformas
        /*p_borrarcuenta,
        p_EditarCuenta,*/
    } = props
    const [] = useState(false)
    const [loading, SeLoading] = useState(false)
    const [searchPerfil, SetSearchPerfil] = useState("")
    const [AbrirPerfilEditar, SetAbrirPerfilEditar] = useState(false)
    const [CurrentPerfilesSelectedCodigo, SetCurrentPerfilesSelectedCodigo] = useState("")
    const [CurrentPerfilesSelectedNombre, SetCurrentPerfilesSelectedNombre] = useState("")

    /**** NEW DATES */
    const [s_CorreoPerfilEditar, SetCorreoPerfilEditar] = useState("")
	const [s_IdContactoEditar, SetIdContactoEditar] = useState("")
    const [s_IdentificadorPerfilEditar, SetIdentificadorPerfilEditar] = useState("")
	const [s_TipoCuentaCodigoEditar, SetTipoCuentaCodigoEditar] = useState("")
	const [s_CantidadPerfilesEditar, SetCantidadPerfilesEditar] = useState("")
    const [s_TipoPerfilEditar, SetTipoPerfilEditar] = useState("")
	const [s_DescripcionPerfilEditar, SetDescripcionPerfilEditar] = useState("")
	const [s_CostoPerfilEditar, SetCostoPerfilEditar] = useState("")
    const [s_FechaInicioPerfilEditar, SetFechaInicioPerfilEditar] = useState("")
    const [s_FechaFinPerfilEditar, SetFechaFinPerfilEditar] = useState("")
    const [s_ExpiracionOpcionalPerfilEditar, SetExpiracionOpcionalPerfilEditar] = useState("")
    const [index, SetIndex] = useState("")



    useEffect(async () => {
        await SeLoading(true)
        
         if(s_detallesalertasistema.TipoCuentaCodigo != "" && 
         s_detallesalertasistema.NombreContacto != "" && 
         s_detallesalertasistema.Correo != ""
         ){
            SetCurrentPerfilesSelectedCodigo(s_detallesalertasistema.TipoCuentaCodigo)
            SetSearchPerfil(s_detallesalertasistema.NombreContacto)
         }
        
     }, [])

    

    const alertaAceptar = (titulo, texto, icon, boton) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }
     
    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }

    const HandleEliminarPerfil = (id, Identificador) => {
        event.preventDefault();
        alertaConfirmar('Eliminar Perfil', 'Estas seguro que quieres eliminar este perfil?', 'question', (res_alert) => {
            if (res_alert === true) {
                p_listaperfiles.map((value, index) => {
                    if (value.Identificador === Identificador) {
                        
                        UserDataModel.BorraPerfil(user, value.Identificador, value.CantidadPerfiles, value.CorreoPerfil, value.TipoCuentaCodigo,  (res) => {
                            if (res === true) {
                                const NuevaListaPerfiles = p_listaperfiles.filter((__c) => __c.Identificador !== Identificador);
                                p_borrarperfil(NuevaListaPerfiles)
                                const NuevoEstadoUso = {
                                    TipoCuentaCodigo: value.TipoCuentaCodigo,
                                    CorreoPerfil: value.CorreoPerfil,
                                    CantidadPerfiles : value.CantidadPerfiles,
                                    Tipo : "Quitar"
                                }
                                p_EditarCuentaEstadoUso(NuevoEstadoUso)
                                Swal.fire({
                                    text: "El perfil se eliminó correctamente",
                                    icon: "success",
                                    timer: 2500,
                                    confirmButtonColor : '#2C3E50',
                                })
                               
                            }
                            else {
                                alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el perfil!' + res, 'error', 'Aceptar')
                            }

                        })

                    }
                })
            }
            else {
                alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el perfil!', 'error', 'Aceptar')
            }
        })
    }

    const HandleCerrarEditarPerfil = (event) => {
        SetAbrirPerfilEditar(false)
    }

    const HandleSubmitEditarPerfil = (e) => {
        const MisPerfiles = p_listaperfiles
        e.preventDefault()
        var FormatHoy = Date.now()
        var FormatFechaInicio = new Date(s_FechaInicioPerfilEditar).getTime()
		var FormatFechaFin = new Date(s_FechaFinPerfilEditar).getTime()

        if (s_DescripcionPerfilEditar.length < 1 || 
            s_CostoPerfilEditar.length < 1 || 
            s_FechaInicioPerfilEditar.length < 1 || 
            s_FechaFinPerfilEditar.length < 1
            ) {
            Swal.fire({
                html: "Uno o más de los campos requeridos están vacios!",
                icon: "warning",
                timer: 5500,
                confirmButtonColor : '#2C3E50',
            })
        }else if(FormatFechaFin < FormatHoy){
			Swal.fire({
				html: "La fecha de expiración no puede ser menor a hoy!",
				icon: "warning",
				timer: 5500,
                confirmButtonColor : '#2C3E50',
			})
		}
        else {
            UserDataModel.ActualizarPerfil(user, 
                s_IdentificadorPerfilEditar,
                s_DescripcionPerfilEditar,
                s_CostoPerfilEditar,
                s_FechaInicioPerfilEditar,
                s_FechaFinPerfilEditar, (res) => {
                if (res === true) {
                    MisPerfiles.map((perfil) => {
                        if (perfil.Identificador === s_IdentificadorPerfilEditar) {
                            perfil.Identificador = s_IdentificadorPerfilEditar
                            perfil.DescripcionPerfil = s_DescripcionPerfilEditar
                            perfil.CostoPerfil = s_CostoPerfilEditar
                            perfil.FechaInicioPerfil = FormatFechaInicio
                            perfil.FechaFinPerfil = FormatFechaFin
                        }
                        return perfil
                    })

                    p_EditarPerfil(MisPerfiles)

                    SetIndex(index)
                    SetIdentificadorPerfilEditar("")
                    SetCorreoPerfilEditar("")
	                SetIdContactoEditar("")
	                SetTipoCuentaCodigoEditar("")
	                SetCantidadPerfilesEditar("")
                    SetTipoPerfilEditar("")
	                SetDescripcionPerfilEditar("")
	                SetCostoPerfilEditar("")
                    SetFechaInicioPerfilEditar("")
                    SetFechaFinPerfilEditar("")
                    SetAbrirPerfilEditar(false)
                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos', 'error', 'Aceptar')
                }
            })
        }
    }


    const HandleEditarPerfil = (
        index,
        Identificador,
        IdContacto,
        TipoCuentaCodigo,
        CorreoPerfil,
        CantidadPerfiles,
        TipoPerfil,
        Descripcion,
        CostoPerfil,
        FechaInicioPerfil,
        FechaFinPerfil) => {
        let FormatFechaInicio = moment(new Date(FechaInicioPerfil)).format("yyyy-MM-DDTHH:mm")
        let FormatFechaFin = moment(new Date(FechaFinPerfil)).format("yyyy-MM-DDTHH:mm")
        let NombreContacto = ""
        let TipoCuentaNombre = ""
        p_listacontactos.find((_c) => {
            if(_c.Identificador === IdContacto){
                NombreContacto = _c.NombreCompleto
            }
        })

        p_listaPlataformas.find((___c) => {
            if(___c.Identificador === TipoCuentaCodigo){
                TipoCuentaNombre = ___c.NombrePlataforma
            }
        })

        event.preventDefault();
        SetAbrirPerfilEditar(true)
        SetIndex(index)
        SetIdentificadorPerfilEditar(Identificador)
        SetCorreoPerfilEditar(CorreoPerfil)
	    SetIdContactoEditar(NombreContacto)
	    SetTipoCuentaCodigoEditar(TipoCuentaNombre)
	    SetCantidadPerfilesEditar(CantidadPerfiles)
        SetTipoPerfilEditar(upperCase(TipoPerfil))
	    SetDescripcionPerfilEditar(Descripcion)
	    SetCostoPerfilEditar(CostoPerfil)
        SetFechaInicioPerfilEditar(FormatFechaInicio)
        SetFechaFinPerfilEditar(FormatFechaFin)
    }

    const handleclosemisperfiles = (e) =>{
        if(s_detallesalertasistema.TipoCuentaCodigo != "" && 
         s_detallesalertasistema.NombreContacto != "" && 
         s_detallesalertasistema.Correo != ""
         ){
            s_vaciardetallesalertasistema()
        }
        p_handleclosemisperfiles(false)
        
    }

    /**Handles Editar Inicio*/

const HandleChangeDescripcionPerfilEditar = (e) => {
    SetDescripcionPerfilEditar(e.target.value)
}

const HandleChangeCostoPerfilEditar = (e) => {
    SetCostoPerfilEditar(e.target.value)
}


const HandleChangeFechaInicioPerfilEditar = (e) => {
    SetFechaInicioPerfilEditar(e.target.value)
}

const HandleChangeFechaFinPerfilEditar = (e) => {
    SetFechaFinPerfilEditar(e.target.value)
}
    /**Handles Editar Cierre */ 

    /**Handles Listar abre*/

    const HandleChangeListarPerfiles = (data, data1) => {
        SetCurrentPerfilesSelectedCodigo(data)
        SetCurrentPerfilesSelectedNombre(data1)
        SetSearchPerfil("")
    }

    /**Handles listar cierre */
    
    const handleFilterPerfiles = p_listaperfiles.filter(_c => {
        var NombreContacto = ""
        p_listacontactos.find((__c)=>{
            if(__c.Identificador ===  _c.IdContacto)
            return NombreContacto = __c.NombreCompleto
        })
            if(CurrentPerfilesSelectedCodigo.length > 0)
            {
                return _c.TipoCuentaCodigo === CurrentPerfilesSelectedCodigo && NombreContacto.toLowerCase().includes(searchPerfil.toLowerCase())
            }else if(CurrentPerfilesSelectedCodigo === ""){
                return _c.TipoCuentaCodigo && NombreContacto.toLowerCase().includes(searchPerfil.toLowerCase())
            }
        })
        
    var image = new Image();

	image.onload = function () {
			// image exists and is loaded
	document.body.appendChild(image);
	}
	image.onerror = function () {
     // image did not load
	var err = new Image();
	err.src = '/icons/unknow.jpg';
	document.body.appendChild(err);
		} 
        
const HandleChangeExpiracionOpcionalPerfilEditar = (e) => {
            SetExpiracionOpcionalPerfilEditar(e.target.value)
            SetFechaFinPerfilEditar(moment(Date.now() + 86400*e.target.value*1000).format("yyyy-MM-DDTHH:mm"))
        }
    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={handleclosemisperfiles} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis perfiles <div className='contador-div'>{s_cantidadperfiles}</div></div>
            </div>
            <div className='main-right-info-container-cuentas'>
            <div className='cuentas-container-div'>
                <div className='cuentas-sub-container-div'>
                {
                     p_listaPlataformas.map((___p)=>{
                        
                        return <div onClick={()=> HandleChangeListarPerfiles(___p.Identificador, ___p.NombrePlataforma)}
                        className={CurrentPerfilesSelectedCodigo == ___p.Identificador ? 'cuentas-container-div-item-hover':'cuentas-container-div-item'} 
                        key={___p.Identificador}>
                            {___p.NombrePlataforma/*.replace(/[A-Za-z]+/g, function(match){ return (match.trim()[0]);})*/}
                        </div>
                     })
                }
                </div>
            </div>

              <div className="search_cuentas_perfiles">
                <Grid container spacing={2}>
                    <MuiThemeProvider theme={theme}>
                        <TextField
                            type="text"
                            id="search_contact_contacts"
                            autoComplete="off"
                            variant='outlined'
                            required
                            fullWidth
                            placeholder={"Buscar perfil " + CurrentPerfilesSelectedNombre}
                            value={searchPerfil}
                            onChange={(e) => SetSearchPerfil(e.target.value)}
                            autoFocus
                            InputLabelProps={{ style: { color: '#ADB7BA', background: 'none', paddingLeft: 10 } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            /*onClick={this.handleSubmit}*/
                                            style={
                                                {
                                                    cursor: "pointer"
                                                }}
                                            color="rgb(132, 139, 144)"
                                            size="24"
                                        />

                                    </InputAdornment>
                                ),
                                style: ({
                                    border: 'none',
                                    outline: 'none',
                                    width: '98.5%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    background: '#061d33',
                                    borderRadius: 0,
                                    height: 55,
                                    position : 'relative'
                                })
                            }}
                            inputProps={{
                                style: {
                                    background: 'transparent',
                                    color: '#ADB7BA',
                                    fontSize: 15
                                },
                                maxLength: 50
                            }}
                            color='primary'
                        />
                    </MuiThemeProvider>
                </Grid>
              </div>
              
              { loading ? (
              <ListaMisPerfiles
              p_listaperfiles={handleFilterPerfiles}
              p_listacontactos={p_listacontactos}
              p_HandleEliminarPerfil={HandleEliminarPerfil}
              p_HandleEditarPerfil={HandleEditarPerfil}
              p_listaPlataformas={p_listaPlataformas}
              /*p_HandleEliminarCuenta={HandleEliminarCuenta}
              p_HandleEditarCuenta={HandleEditarCuenta}*/
              />
              ) :
                (
                    <div className="preloader" style={{ width: 75, height: 75, marginLeft: '41%', marginTop: 25 }}></div>
                )

            }
            </div>

            {AbrirPerfilEditar && (
										
                                        <div className="modal">
                                        <div className="modal-contenido">
                                                <div className="title-pages-modal">
                                                    <div className="title_ico_pages_modal">
                                                        <IconButton onClick={HandleCerrarEditarPerfil} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                                                    </div>
                                                    <div className="div_title_pages_modal">Editar perfil</div>
                                                </div>
                                                <form onSubmit={HandleSubmitEditarPerfil} className='form-modal'>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Contacto no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Contacto" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_IdContactoEditar}
						/>
                </Tooltip>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Tipo de cuenta no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tipo de cuenta" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TipoCuentaCodigoEditar}
						/>
                </Tooltip>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Correo no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Correo" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CorreoPerfilEditar}
						/>
                </Tooltip>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Perfiles usados no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Perfiles usados" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CantidadPerfilesEditar}
						/>
                </Tooltip>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Tipo de perfil no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tipo de perfil" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TipoPerfilEditar}
						/>
                </Tooltip>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Descripción" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_DescripcionPerfilEditar}
                        onChange={HandleChangeDescripcionPerfilEditar}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Costo" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16, paddingLeft:3
					    }}}
						InputProps={{startAdornment:'S/.', style:{color:'#c2c2c2', 
						fontSize:17, fontFamily:"Maven Pro, sans-serif"}}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CostoPerfilEditar}
						onChange={HandleChangeCostoPerfilEditar}
						onKeyPress={PermiteSoloNumeros}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de inicio" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaInicioPerfilEditar}
						onChange={HandleChangeFechaInicioPerfilEditar}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaFinPerfilEditar}
						onChange={HandleChangeFechaFinPerfilEditar}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración (opcional)" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_ExpiracionOpcionalPerfilEditar}
						onChange={HandleChangeExpiracionOpcionalPerfilEditar}
						select
						SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}}}
						>
							<MenuItem key={30} value={30}>1 mes</MenuItem>
							<MenuItem key={60} value={60}>2 meses</MenuItem>
							<MenuItem key={90} value={90}>3 meses</MenuItem>
							<MenuItem key={120} value={120}>4 meses</MenuItem>
							<MenuItem key={150} value={150}>5 meses</MenuItem>
							<MenuItem key={180} value={180}>6 meses</MenuItem>
							<MenuItem key={210} value={210}>7 meses</MenuItem>
							<MenuItem key={240} value={240}>8 meses</MenuItem>
							<MenuItem key={270} value={270}>9 meses</MenuItem>
							<MenuItem key={300} value={300}>10 meses</MenuItem>
							<MenuItem key={330} value={330}>11 meses</MenuItem>
							<MenuItem key={360} value={360}>12 meses</MenuItem>
							
							
				</TextField> 

<Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdUpdate size={28} />}>Actualizar</Button>
				 
				</form>
                                                
                                            </div>
                                        </div>
                                                            )}
            
       </div>
    )
}


