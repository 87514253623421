import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import swal from 'sweetalert'
import UserDataModel from '../../../models/UserDataModel';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function Backup(props) {
    const { 
        user, 
        p_handleclosebackup,
    } = props
    const [] = useState(false)

    const handleclosebackup = (e) =>{
        p_handleclosebackup(false)
    }

    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <button className="btn-atras" onClick={handleclosebackup}><IoArrowBack color="#B4C0C8" size="24" /></button>
                </div>
                <div className="div_title_pages">Backup</div>
            </div>
       </div>
    )
}


