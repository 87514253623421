module.exports = {
	COMMUNITY_CHAT:"COMMUNITY_CHAT",
	USER_CONNECTED:"USER_CONNECTED",
	ADD_NEW_USER:"ADD_NEW_USER",
	MESSAGE_RECIEVED:"MESSAGE_RECIEVED",
	MESSAGE_SENT:"MESSAGE_SENT",
	USER_DISCONNECTED:"USER_DISCONNECTED",
	TYPING:"TYPING",
	VERIFY_USER:"VERIFY_USER",
	LOGOUT:"LOGOUT",
	PRIVATE_MESSAGE:"PRIVATE_MESSAGE",
	GROUP_MESSAGE:"GROUP_MESSAGE",
	IS_ONLINE:"IS_ONLINE",
	SEND_IS_ONLINE:"SEND_IS_ONLINE",
	IS_OFFLINE:"IS_OFFLINE",
	SEND_IS_OFFLINE:"SEND_IS_OFFLINE",
	CHANGE_GROUP_NAME:"CHANGE_GROUP_NAME",
	CHANGE_PERFIL_INFO:"CHANGE_PERFIL_INFO",
	INICIAR_VIDEOLLAMADA:"INICIAR_VIDEOLLAMADA",
	RESPONDER_VIDEOLLAMADA:"RESPONDER_VIDEOLLAMADA",
	FINALIZAR_VIDEOLLAMADA:"FINALIZAR_VIDEOLLAMADA"
}