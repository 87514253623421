import React, { Fragment, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Progress from './Progress';
import swal from 'sweetalert'
import { IoMdCamera } from 'react-icons/io';
import { Collapse, Menu, MenuItem } from '@material-ui/core';

export default function FileUpPersonal(props) {
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose File');
    const [anchorElimage, setAnchorElimage] = useState(null);
    const openMenuImage = Boolean(anchorElimage);
    const [preview, SetPreview] = useState(false)


    const alertaAceptar = (titulo, texto, icon, boton) => {
        swal({
            title: titulo,
            text: texto,
            icon: icon,
            button: boton
        })
    }

    const onChange = e => {
        if (e.target.files[0]) {
            if (CheckFileSizeValid(e.target.files[0])) {
                if (CheckTypeFileValid(e.target.files[0])) {
                    props.file(e.target.files[0])
                    SetPreview(true)
                    setFile(e.target.files[0]);
                    setFilename(e.target.files[0].name);
                    props.handleSetisImageLoaded(true)

                    // Creamos el objeto de la clase FileReader
                    let reader = new FileReader();

                    // Leemos el archivo subido y se lo pasamos a nuestro fileReader
                    reader.readAsDataURL(e.target.files[0]);

                    // Le decimos que cuando este listo ejecute el código interno
                    reader.onload = function () {
                        let preview = document.getElementById('preview'),
                            image = document.createElement('img');
                        image.className = "image_perfil"
                        image.onclick = handleClickImage
                        image.src = reader.result;

                        preview.innerHTML = '';
                        preview.append(image);
                    };
                    //HandleSubmitfile()
                }
            }
        }
    };

    const CheckTypeFileValid = (file_) => {

        let files = file_
        let err = [] // create empty array
        const FormatosValidos = ['image/jpeg']
        if (FormatosValidos.every(type => files.type !== type)) {
            err[0] = files.type + ' no es un formato permitido (png, jpg, jpeg)';
            // assign message to array
            files = null
            alertaAceptar('Alera de Hola APP', `${err[0]}`, 'error', 'Aceptar')
            return false;
        } else {
            return true;
        }
    }

    const CheckFileSizeValid = (files_) => {
        let files = files_
        let size = 2000000 // tamaño en bytes example 1MB = 1,000.000 bytes
        let err = [];

        if (files.size > size) {
            err[0] = files.name + ' supera la capacidad máxima permitida (2MB)';
            files = null
            alertaAceptar('Alera de Hola APP', `${err[0]}`, 'error', 'Aceptar')
            return false;
        } else {
            return true;
        }
    }

    const handleClickImage = (event) => {
        event.preventDefault();

        setAnchorElimage(true)
        setAnchorElimage(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorElimage(null)
    };

    const handleUpFile = () => {
        document.getElementById("fileimage").click()
    };

    const HandleSubmitfile = () => {
        event.preventDefault();
        setTimeout(() => {
            document.getElementById("submitfileselected").click()
        }, 300)
    }

    return (

            <form>
                {preview ? (
                    <div id="preview">
                        <div className='div_image_default'>
                        </div>
                    </div>
                ) : (<div className='row mt-5'>
                    <div className='div_image_default'>
                        <img id="image_perfil" src={`/images_perfil/${props.currentPhoto}.jpg`} alt='No image found' onClick={handleUpFile} />
                    </div>
                </div>)}
                <div className={props.uploadedFile ? props.uploadedFile.filePath ? "ver_preview_off" : "ver_preview" : null}>
                    <p className="p_text_cambiar_foto_group" onClick={handleUpFile}><i><IoMdCamera size="36" /></i> <br />CAMBIAR PERFIL</p>
                </div>
               
                    <input accept="image/jpeg" style={{visibility: 'hidden'}} type="file" id='fileimage' onChange={onChange}></input>
                {/*props.showPercent ? <Progress percentage={props.uploadPercentage} /> : null*/}
                <input
                    type='submit'
                    value='Upload'
                    id="submitfileselected"
                    className='btn btn-primary btn-block mt-4'
                    style={{ visibility: 'hidden' }}
                />
            </form>

    );
};