import { IconButton } from '@mui/material';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { IoMdContact } from 'react-icons/io';

import { MdAccountBalanceWallet, MdAccountCircle, MdDelete, MdDiscFull, MdEmail, MdHourglassFull, MdInfo, MdModeEdit, MdPhoneAndroid, MdPlayArrow, MdSdStorage, MdSupervisorAccount, MdTimer, MdWatchLater } from 'react-icons/md';
import { CalcularExpiracion, setUnixToTimeFormat } from '../../../Funciones';


export default function ListaMisCuentas(props) {
    const { 
        p_ListaCuentas,
        p_HandleEliminarCuenta,
        p_HandleEditarCuenta,
        p_usuarioinfo,
        p_listaPlataformas
    } = props
    return (
        <div className="container_info">
{p_ListaCuentas.length > 0 ? (
            <div className="group_items">
             
                    {
                        p_ListaCuentas.sort((a,b) => a.FechaFinCuenta - b.FechaFinCuenta)
                        
                        .map((cuenta, index) => {
                            var MaxPerfiles = 0
                            var TipoCuentaNombre = ""
                            var CorreoCuenta = cuenta.CorreoCuenta
                            var Identificador = cuenta.Identificador
                            var TipoCuentaCodigo = cuenta.TipoCuentaCodigo
                            var EstadoUso = cuenta.EstadoUso
                            var FechaFinCuenta = cuenta.FechaFinCuenta
                            var FechaInicioCuenta = cuenta.FechaInicioCuenta
                            var created_at = cuenta.created_at
                            var PasswordCorreo = cuenta.PasswordCorreo
                            var PasswordCuenta = cuenta.PasswordCuenta

                            p_listaPlataformas.find((___p)=>{
                                if(___p.Identificador === TipoCuentaCodigo){
                                    MaxPerfiles = ___p.MaximoPerfiles
                                    TipoCuentaNombre = ___p.NombrePlataforma
                                }
                            })
                            
                            return (
                                <div
                                    key={created_at}
                                    className="item_container"
                                >
                                <div className="item-block-cuentas">
                                    <div className='icon_left'>
                                            <MdEmail size={45}/>
                                    </div>

                                    <div className='item-left-cuentas'>
                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-correo">{CorreoCuenta}</div>
                                            <div className="item_list-cuentas-tipo">{TipoCuentaNombre}</div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className={
                                                CalcularExpiracion(FechaFinCuenta).substring(0, 2).trim().length === 1 & 
                                                Number(CalcularExpiracion(FechaFinCuenta).substring(0, 1)) >= 0 & 
                                                Number(CalcularExpiracion(FechaFinCuenta).substring(0, 1)) < 3 ?
                                                'item_list-cuentas-proximo':
                                                CalcularExpiracion(FechaFinCuenta) == 'Caducado' ?
                                                 "item_list-cuentas-fechaexp-caducado":
                                                 "item_list-cuentas-fechaexp"}>
                                            <div className='icon-adorno'>
                                                <div><MdTimer size="24" /></div>
                                                <div className='text-exp'>{CalcularExpiracion(FechaFinCuenta)}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-created">{setUnixToTimeFormat('info_contacto', created_at)}</div>
                                            <div className="item_list-cuentas-estadouso">
                                                {EstadoUso >= MaxPerfiles && <div className='cuenta-full'>Cuenta Full <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                                {EstadoUso === 0 && <div className='cuenta-libre'>Ningún perfil usado <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                                {(EstadoUso === 1 && EstadoUso < MaxPerfiles) && <div className='cuenta-usado'>{EstadoUso + ' perfil en uso'} <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                                {(EstadoUso > 1 && EstadoUso < MaxPerfiles) && <div className='cuenta-usado'>{EstadoUso + ' perfiles en uso'} <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className='icon_right'>

                                        <IconButton onClick={() => p_HandleEditarCuenta(
                                                    index, 
                                                    Identificador, 
                                                    CorreoCuenta,
                                                    FechaInicioCuenta,
                                                    FechaFinCuenta,
                                                    TipoCuentaNombre,
                                                    PasswordCorreo,
                                                    PasswordCuenta,
                                                    EstadoUso,
                                                    created_at,
                                                    )} color='primary' data-title='Editar'>
                                            <MdModeEdit
                                                color="#FFD75B"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Editar'
                                            />
                                        </IconButton>
                                        
                                        <IconButton onClick={() => p_HandleEliminarCuenta(index, Identificador, EstadoUso)} color='primary' data-title='Eliminar'>
                                            <MdDelete
                                                color="#C64141"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Eliminar'
                                            />
                                        </IconButton>
                                    </div>
                                </div>

                                </div>

                            )
                        })
                    }
            </div>
):(<div className='sin_datos'><div className='sin_datos_left'><MdInfo color='#F1C40F' size={64}/></div><div className='sin_datos_right'> No se ah encontrado ningún dato registrado!</div></div>)
}
        </div>
    )
}