import React, { Component } from 'react';
import {  } from '../Events'
import MainLeft from './MainLeft';
import MainRight from './MainRight'
import UserDataModel from '../models/UserDataModel';
import { firebase } from '../database/firebase'
import { MdLock, MdMenu, MdOutlineAccessTimeFilled } from 'react-icons/md';
import { ActualizarMensajesServidorVencidos, AgruparCantidadPerfiles, GenerarAlertasSistmaPerfiles, NombrePlataforma, setUnixToTimeFormat, Top3Perfiles } from '../Funciones';
import Swal from 'sweetalert2'
import { Alert, IconButton } from '@mui/material';
import { upperCase } from 'lodash';
import moment from 'moment';
const db = firebase.firestore()

	export default class MainContainer extends Component {
		

		constructor(props) {
			
			super(props);
			this.state = {
	usuarioinfo :[],
	perfilinfo:[],
	listaContactos:[],
	listaUsuarios:[],
	listaCuentas:[],
	listaPlataformas:[],
	listaperfiles:[],
	listacodigos:[],
	listamensajeservidor:[],

	nuevocontacto : false,
	miscontactos:false,
	mispinesnetflix:false,
	nuevopinnetflix:false,
	miscuentas :false,
	nuevacuenta :false,
	nuevoperfil :false,
	misperfiles:false,
	backup:false,
	nuevocodigo:false,
	plataformas:false,
	misusuarios:false,
	miskeydeactivacion:false,
	abrirperfilinfo:false,
	usuarioexpirado:false,
	portal:true,
	mensajedelservidor:false,
	reportar:false,
	nuevocombo: false,
	listaplataformascombo : null,

	/**PLATAFORMAS INICIO */
	s_MaxPerfilesNetflix:"",
	s_MaxPerfilesMovPlay:"",
	s_MaxPerfilesDisney:"",
	s_MaxPerfilesHbo:"",
	s_MaxPerfilesPrimeV:"",
	s_MaxPerfilesParamount:"",
	s_MaxPerfilesPornHub:"",
	s_MaxPerfilesYoutube:"",
	s_MaxPerfilesCrunchyroll:"",
	/**PLATAFORMAS FIN */

	/**PERFIL INFO INICIO */
	s_PasswordPerfilInfo:"",
	s_BusinessNamePerfilInfo:"",

	s_agruparperfiles : null,

	s_gananciamesactual : 0,
	s_cantidadperfiles : 0,

	Top1 : [{Tipo: '', CantidadPerfiles : 0}],
	Top2 : [{Tipo: '', CantidadPerfiles : 0}],
	Top3 : [{Tipo: '', CantidadPerfiles : 0}],
	PerfilesVencidos : [],
	detallesalertasistema : {TipoCuentaCodigo: '', NombreContacto : '', Correo : ''},
	isloadingalertasservidor : true,
	isloadingcontactos : true,
	isloadingcuentas: true,
	isloadingperfiles : true,
	showmenu : false,
	CurrentMaxCharBusinessPerfil : 30,
	CurrentMaxCharPasswordPerfilInfo : 16,
	CurrentCantidadAlertas: 0,
	NuevaCantidadAlertas: 0,
			};
	
		}
	async componentDidMount() {
		const { socket, user, esADMIN } = this.props
		this.initSocket(socket)

		const MiListaDeContactos = []
		const MiListaDeCuentas = []
		const MiInfo = []
		const MiPerfil = []
		const MiListaDePerfiles = []
		const Codigos = []
		const usuarios = []
		const MensajesServidor = []
		const MiListaDePlataformas = []

		const Topes = []

        await UserDataModel.ListarMisContactos(user.user, function (data) {
            MiListaDeContactos.push(data)
        })

		this.setState({listaContactos: MiListaDeContactos})
		
		await UserDataModel.ListarMisCuentas(user.user, function (data) {
            MiListaDeCuentas.push(data)
        })

		this.setState({listaCuentas: MiListaDeCuentas})
		
		await UserDataModel.ListarUsuarioInfo(user.user, function (data) {
            MiInfo.push(data)
        })

		this.setState({usuarioinfo: MiInfo})

		await UserDataModel.ListarPlataformas(user.user, function (data) {
            MiListaDePlataformas.push(data)
        })

		this.setState({listaPlataformas: MiListaDePlataformas})
        
		await UserDataModel.ListarMisPerfiles(user.user, function (data) {
            MiListaDePerfiles.push(data)
			
        })
		this.setState({listaperfiles: MiListaDePerfiles})
		
        if(esADMIN){
			await UserDataModel.ListarCodigos(function (data) {
				Codigos.push(data)
			})

			this.setState({listacodigos: Codigos})

			await UserDataModel.ListarUsuarios(function (data) {
				usuarios.push(data)
			})

			this.setState({listaUsuarios: usuarios})
		}
		this.setState({s_agruparperfiles : AgruparCantidadPerfiles(this.state.listaperfiles, 'TipoCuentaCodigo')})
		
        if(Object.keys(this.state.s_agruparperfiles).length >= 0){
			Top3Perfiles(this.state.listaPlataformas, this.state.s_agruparperfiles, (resp)=>{
				this.setState({Top1:[resp.Top1]})
				this.setState({Top2:[resp.Top2]})
				this.setState({Top3:[resp.Top3]})
			})
		}
		const BalanceMesActual = this.state.listaperfiles.map(item => 
			new Date(item.FechaInicioPerfil).getMonth() + 1 === new Date(Date.now()).getMonth() + 1 & 
			new Date(item.FechaInicioPerfil).getFullYear() === new Date(Date.now()).getFullYear()
			 && Number(item.CostoPerfil)
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_gananciamesactual : BalanceMesActual})

		const CantidadDePerfiles = this.state.listaperfiles.map(item => 
			item.CantidadPerfiles
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_cantidadperfiles : CantidadDePerfiles})


		 await UserDataModel.ListarMensajesServidor(function (data) {
			MensajesServidor.push(data)
		})

		this.setState({isloadingcontactos: false})
		this.setState({isloadingcuentas: false})
		this.setState({isloadingperfiles: false})
		this.setState({listamensajeservidor: MensajesServidor})
		this.setState({isloadingalertasservidor: false})
	}

	alertaAceptar = (titulo, texto, icon, boton) => {
		Swal.fire({
		  title: titulo,
		  html: texto,
		  icon: icon,
		  confirmButtonColor : '#2C3E50',
	  })
    }

	alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }
	
    initSocket(socket){
		socket.on('BLOQUEARDESBLOQUEARUSUARIO', this.BloquearDesbloquearUsuario)
		socket.on('EDITARUSUARIO', this.EditarUsuario)
		socket.on('NUEVOUSUARIOREGISTRADO', this.AgregarNuevoUsuarioRegistrado)
		socket.on('NUEVOMENSAJESERVIDOR', this.AgregarNuevoMensajeServidor)
		socket.on('ELIMINARMENSAJESERVIDOR', this.EliminarMensajeServidor)
	}

	EliminarMensajeServidor = (data) => {
		const {listamensajeservidor} = this.state
        event.preventDefault();
        const NuevaListaMensajes = listamensajeservidor.filter((__c) => __c.Identificador !== data.Identificador)
		this.setState({listamensajeservidor:NuevaListaMensajes})
    }

	HandleActualizarPerfilesVencidos = () => {
		const {listaPlataformas} = this.state
		const PerfilesVencidos = GenerarAlertasSistmaPerfiles(this.state.listaperfiles, this.state.listaCuentas)
		this.setState({CurrentCantidadAlertas : PerfilesVencidos.length})
		if(this.state.CurrentCantidadAlertas > 0 & this.state.CurrentCantidadAlertas > this.state.NuevaCantidadAlertas){
			this.setState({NuevaCantidadAlertas : this.state.CurrentCantidadAlertas})
			this.setState({PerfilesVencidos: PerfilesVencidos})
			let UltimoDato = PerfilesVencidos[PerfilesVencidos.length - 1]
			var perfilocuenta = ""
			var mensaje = ""
			var nombrecontacto = ""
			var nombreplataforma = ""
			listaPlataformas.find((__p)=>{
				if(UltimoDato.TipoCuentaCodigo === __p.Identificador){
					nombreplataforma = __p.NombrePlataforma
				}
			})
			
			//NombrePlataforma(UltimoDato.TipoCuentaCodigo)
			this.state.listaContactos.find((___C)=>{
				if(___C.Identificador === UltimoDato.IdContacto){
					nombrecontacto = ___C.NombreCompleto
				}
			})
			perfilocuenta = UltimoDato.Tipo === 'perfil' ? 'PERFIL CADUCADO':'CUENTA CADUCADA'
			mensaje = UltimoDato.Tipo === 'perfil' ? 'El perfil <b>' + nombreplataforma + '</b> de <b>' + nombrecontacto + '</b> ' + setUnixToTimeFormat('fecha_completa_alerta', UltimoDato.FechaExpiracion):'La cuenta <b>' + UltimoDato.Correo + '</b> en la plataforma <b>' + nombreplataforma + '</b> ' + setUnixToTimeFormat('fecha_completa_alerta', UltimoDato.FechaExpiracion)
			

			Swal.fire({
				position: 'top-end',
				title : 'ALERTA ' + perfilocuenta,
				html: '<font color="#D5D8DC">' + mensaje +'</font>',
				confirmButtonText : 'Ver detalles',
				background: '#A54B00',
				confirmButtonColor : '#229954',
			}).then((result) => {
				if (result.isConfirmed) {
					this.VerDetallesDeAlertaSistema(UltimoDato.TipoCuentaCodigo, nombrecontacto, UltimoDato.Correo, UltimoDato.Tipo)
				}
			  })
		}

		if(this.state.CurrentCantidadAlertas < this.state.NuevaCantidadAlertas){
			this.setState({PerfilesVencidos: PerfilesVencidos})
			this.setState({NuevaCantidadAlertas : this.state.CurrentCantidadAlertas})
		}
	}
    

	HandleActualizarMensajesServidorVencidos = () => {
		const MensajesServidorVencidos = ActualizarMensajesServidorVencidos(this.state.listamensajeservidor)
	    this.setState({listamensajeservidor: MensajesServidorVencidos})
	}

	AgregarNuevoMensajeServidor = (data) => {
		const { user } = this.props
		const { listamensajeservidor } = this.state
				listamensajeservidor.push(data)
				if(data.Remitente != user.user){
					this.alertaAceptar('Alerta del servidor', data.MensajeServidor, data.CategoriaMensajeServidor, 'Aceptar')
				}
	}

	AgregarNuevoUsuarioRegistrado = (data) => {
		const { user, esADMIN } = this.props
		const { listaUsuarios, listacodigos } = this.state
		if(esADMIN){
			listaUsuarios.push(data)

			const CodigoUsado = listacodigos.map((cod) => {
				if (cod.Codigo === data.u_Key) {
					cod.UsuarioDelCodigo = data.u_quienusa,
					cod.EstadoUso = 1
				}
				return cod
			})
			this.setState({listacodigos: CodigoUsado})
		}
	}

	BloquearDesbloquearUsuario = (data) => {
		const { user, p_BloquearDesbloquearUsuario } = this.props
		const { listaUsuarios } = this.state

				const BloqueoDesbloqueo = listaUsuarios.map((usuario) => {
					if (usuario.u_id === data.id){
						if(data.tipo === "Bloquear"){
							usuario.u_state = 0
						}else if(data.tipo === "Desbloquear"){
							usuario.u_state = 1
						}
					}
					return usuario
				})

				if(data.id === user.id){
					p_BloquearDesbloquearUsuario(data)
				}
				this.setState({listaUsuarios: BloqueoDesbloqueo})
	}

	EditarContacto = (data) => {
		const { listaContactos } = this.state
		const ContactoEditado = listaContactos.map((contacto) => {
			if (contacto.NumeroCelular === data.NumeroCelular) {
				contacto.NumeroCelular = data.NumeroCelular,
				contacto.NombreCompleto = data.NombreCompleto
			}
			return contacto
		})
		this.setState({listaContactos: ContactoEditado})
	}

	EditarCuenta = (data) => {
		const { listaCuentas } = this.state
		const CuentaEditada = listaCuentas.map((cuenta) => {
			if (cuenta.Identificador === data.Identificador) {
				cuenta.PasswordCuenta = data.PasswordCuenta,
				cuenta.PasswordCorreo = data.PasswordCorreo,
				cuenta.FechaFinCuenta = data.FechaFinCuenta,
				cuenta.FechaInicioCuenta = data.FechaInicioCuenta
			}
			return cuenta
		})
		this.setState({listaCuentas: CuentaEditada})
	}

	EditarUsuario = (data, data1, data2) => {
		var hoy = Date.now()
		const { user, p_EditarUsuario, esADMIN } = this.props

		if(data.id === user.id){
			p_EditarUsuario(data)
			if(data.expiration > hoy){
				this.setState({usuarioexpirado: false})
			}

			if(data.autority === 5 && !esADMIN){
				this.setState({listacodigos: data1})
				this.setState({listaUsuarios: data2})
			}else{
				this.setState({listacodigos: []})
				this.setState({listaUsuarios: []})
			}
		}

		if(esADMIN){
			this.setState({listaUsuarios: data2})
		}
	}

	EditarPerfil = (data) => {
		const { listaperfiles } = this.state
		const PerfilEditado = listaperfiles.map((perfil) => {
			if (perfil.Identificador === data.Identificador) {
				perfil.DescripcionPerfil = data.DescripcionPerfil
                perfil.CostoPerfil = data.CostoPerfil
                perfil.FechaInicioPerfil = data.FechaInicioPerfil
                perfil.FechaFinPerfil = data.FechaFinPerfil
			}
			return perfil
		})
		this.setState({listaperfiles: PerfilEditado})
	}

	EditarCuentaEstadoUso = (data) => {
		const { listaCuentas } = this.state
		const CuentaEditada = listaCuentas.map((cuenta) => {
			if (cuenta.CorreoCuenta === data.CorreoPerfil && 
				cuenta.TipoCuentaCodigo === data.TipoCuentaCodigo) {
				var NewStadoUsoAgregar = Number(cuenta.EstadoUso)  + Number(data.CantidadPerfiles)
				var NewStadoUsoQuitar = Number(cuenta.EstadoUso)  - Number(data.CantidadPerfiles)
				if(data.Tipo === "Agregar"){
					cuenta.EstadoUso = NewStadoUsoAgregar
				}else if(data.Tipo === "Quitar"){
					cuenta.EstadoUso = NewStadoUsoQuitar
				}
				
			}
			return cuenta
		})
		this.setState({listaCuentas: CuentaEditada})
	}
	
	EditarPlataformas = (data) => {
		const { listaPlataformas } = this.state
		const PlaformasEditada = listaPlataformas.map((plataforma) => {
			if (plataforma.Identificador === data.Identificador) {
				plataforma.NombrePlataforma = data.NombrePlataforma
				plataforma.MaximoPerfiles = data.MaxPerfilesPlataforma
			}
			return plataforma
		})
		this.setState({listaPlataformas: PlaformasEditada})
	}

	
	HandleActualizarTop3Perfiles = () => {
		
		this.setState({s_agruparperfiles : AgruparCantidadPerfiles(this.state.listaperfiles, 'TipoCuentaCodigo')})

		if(Object.keys(this.state.s_agruparperfiles).length >= 0){
			Top3Perfiles(this.state.listaPlataformas, this.state.s_agruparperfiles, (resp)=>{
				this.setState({Top1:[resp.Top1]})
				this.setState({Top2:[resp.Top2]})
				this.setState({Top3:[resp.Top3]})
			})
		}


		const sumall = this.state.listaperfiles.map(item => 
			new Date(item.FechaInicioPerfil).getMonth() + 1 === new Date(Date.now()).getMonth() + 1 & 
			new Date(item.FechaInicioPerfil).getFullYear() === new Date(Date.now()).getFullYear() 
             && Number(item.CostoPerfil)
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_gananciamesactual : sumall})

		const CantidadDePerfiles = this.state.listaperfiles.map(item => 
			item.CantidadPerfiles
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_cantidadperfiles : CantidadDePerfiles})
	}

	HandleUsuarioExpirado = (data) =>{
		this.setState({usuarioexpirado: data})
	}

	EliminarContacto = (newlist) => {
		this.setState({listaContactos: newlist})
	}

	EliminarCuenta = (NuevaListaCuentas) => {
		this.setState({listaCuentas: NuevaListaCuentas})
	}


	EliminarPerfil = (NuevaListaPerfiles) => {
		this.setState({listaperfiles: NuevaListaPerfiles})
		this.setState({s_agruparperfiles : AgruparCantidadPerfiles(this.state.listaperfiles, 'TipoCuentaCodigo')})

		if(Object.keys(this.state.s_agruparperfiles).length >= 0){
			Top3Perfiles(this.state.listaPlataformas, this.state.s_agruparperfiles, (resp)=>{
				this.setState({Top1:[resp.Top1]})
				this.setState({Top2:[resp.Top2]})
				this.setState({Top3:[resp.Top3]})
			})
		}

		const sumall = this.state.listaperfiles.map(item => 
			new Date(item.FechaInicioPerfil).getMonth() + 1 === new Date(Date.now()).getMonth() + 1 & 
			new Date(item.FechaInicioPerfil).getFullYear() === new Date(Date.now()).getFullYear() 
			 && Number(item.CostoPerfil) 
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_gananciamesactual : sumall})

		const CantidadDePerfiles = this.state.listaperfiles.map(item => 
			item.CantidadPerfiles
		).reduce((prev, curr) => prev + curr, 0);
		 this.setState({s_cantidadperfiles : CantidadDePerfiles})
	}


	EliminarKey = (NuevaListaKeys) => {
		this.setState({listacodigos: NuevaListaKeys})
	}
	/**INICIA PORTAL */
	HandlePortal = (event) => {
		this.setState({portal: true})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	}
	     
	/**CIERRA PORTAL */
	/*INICIA CONTACTOS*/

	HandleNuevoContacto = (event) => {
		this.setState({nuevocontacto: true})
	};

	HandleCerrarNuevoContacto = (event) => {
		this.setState({nuevocontacto: false})
	};

	HandleMisContactos = (event) => {
		this.setState({miscontactos: true})
		this.setState({portal: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleCerrarMiscontactos = (event) => {
		this.setState({miscontactos: false})
		this.setState({portal: true})
	};

	HandleCerrarMisKeys = (event) => {
		this.setState({miskeydeactivacion: false})
		this.setState({portal: true})
	};

	/*CIERRA CONCTACTOS*/
    
	/*INICIA PINES NETFLIX*/

	HandleMisPinesNetflix = (event) => {
		this.setState({mispinesnetflix: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleCerrarMisPinesNetflix = (event) => {
		this.setState({mispinesnetflix: false})
		this.setState({portal: true})
	};

	HandleNuevoPinNetflix = (event) => {
		this.setState({nuevopinnetflix: true})
	};

	HandleCerrarNuevoPinNetflix = (event) => {
		this.setState({nuevopinnetflix: false})
	};

	/*CIERRA PINES NETFLIX*/

	/*ABRE CUENTAS */
	HandleNuevaCuenta = (event) => {
		this.setState({nuevacuenta: true})
	};

	HandleCerrarNuevaCuenta = (event) => {
		this.setState({nuevacuenta: false})
	};

	HandleMisCuentas = (event) => {
		this.setState({miscuentas: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleCerrarMisCuentas = (event) => {
		this.setState({portal: true})
		this.setState({miscuentas: false})
	};

	/*CIERRA CUENTAS */

	/*INICIA PERFILES*/

	HandleNuevoPerfil = (event) => {
		this.setState({nuevoperfil: true})
	};

	HandleMisPerfiles = (event) => {
		this.setState({misperfiles: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleCerrarMisPerfiles = (event) => {
		this.setState({misperfiles: false})
		this.setState({portal: true})
	};

	HandleCerrarNuevoPerfil = (event) => {
		this.setState({nuevoperfil: false})
	};
	/*CIERRA PERFILES*/

	/*INICIA CONFIGURACION */

	HandleBackup = (event) => {
		this.setState({backup: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({misusuarios: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleNuevoCodigo = (event) => {
		this.setState({nuevocodigo: true})
	};

	HandleMisUsuarios = (event) => {
		this.setState({misusuarios: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({miskeydeactivacion: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandlePlataformas = (event) => {
		const { usuarioinfo } = this.state
		this.setState({plataformas: true})
		this.setState({s_MaxPerfilesNetflix: usuarioinfo[0].u_MaxPerfilesNetflix})
		this.setState({s_MaxPerfilesMovPlay: usuarioinfo[0].u_MaxPerfilesMovPlay})
		this.setState({s_MaxPerfilesDisney: usuarioinfo[0].u_MaxPerfilesDisney})
		this.setState({s_MaxPerfilesHbo: usuarioinfo[0].u_MaxPerfilesHbo})
		this.setState({s_MaxPerfilesPrimeV: usuarioinfo[0].u_MaxPerfilesPrimeVideo})
		this.setState({s_MaxPerfilesParamount: usuarioinfo[0].u_MaxPerfilesParamount})
		this.setState({s_MaxPerfilesPornHub: usuarioinfo[0].u_MaxPerfilesPornHubPremium})
		this.setState({s_MaxPerfilesYoutube: usuarioinfo[0].u_MaxPerfilesYoutubepremium})
		this.setState({s_MaxPerfilesCrunchyroll: usuarioinfo[0].u_MaxPerfilesCrunchyroll})
	};

	HandleMensajeDelServidor = (event) => {
		this.setState({mensajedelservidor: true})
	};

	HandleReportar = (event) => {
		this.setState({reportar: true})
	};

	HandleNuevoCombo = (event) => {
		const { listaPlataformas } = this.state
		this.setState({listaplataformascombo : listaPlataformas})
		this.setState({nuevocombo: true})
	};

	HandleCerrarMensajeDelServidor = (event) => {
		this.setState({mensajedelservidor: false})
	};

	HandleCerrarReportar = (event) => {
		this.setState({reportar: false})
	};

	HandleMisKeyDeActivacion = (event) => {
		this.setState({miskeydeactivacion: true})
		this.setState({portal: false})
		this.setState({miscontactos: false})
		this.setState({mispinesnetflix: false})
		this.setState({miscuentas: false})
		this.setState({misperfiles: false})
		this.setState({backup: false})
		this.setState({misusuarios: false})
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	};

	HandleCerrarBackup = (event) => {
		this.setState({backup: false})
		this.setState({portal: true})
	};
    
	HandleCerrarNuevoCodigo = (event) => {
		this.setState({nuevocodigo: false})
	};

	HandleCerrarMisUsuarios = (event) => {
		this.setState({misusuarios: false})
		this.setState({portal: true})
	};

	HandleCerrarPlataformas = (event) => {
		this.setState({plataformas: false})
	};
	/*CIERRA CONFIGURACION */

	/*INICIA PERFIL */

	HandleAbrirPerfilInfo = (event) => {
		const { user } = this.props
		this.setState({abrirperfilinfo: true})
		this.setState({s_PasswordPerfilInfo: user.password})
		this.setState({s_BusinessNamePerfilInfo: user.businessName})
		this.setState({CurrentMaxCharBusinessPerfil: 30 - user.businessName.length})
		this.setState({CurrentMaxCharPasswordPerfilInfo: 16 - user.password.length})
	};

	HandleCerrarPerfilInfo = (event) => {
		this.setState({abrirperfilinfo: false})
	};
	/*CIERRA PERFIL */

	HandleChangeMaxPerfilesNetflix =(e)=>{
		this.setState({s_MaxPerfilesNetflix: e.target.value})
	}

	HandleChangeMaxPerfilesMovPlay =(e)=>{
		this.setState({s_MaxPerfilesMovPlay: e.target.value})
	}

	HandleChangeMaxPerfilesDisney =(e)=>{
		this.setState({s_MaxPerfilesDisney: e.target.value})
	}

	HandleChangeMaxPerfilesHbo =(e)=>{
		this.setState({s_MaxPerfilesHbo: e.target.value})
	}

	HandleChangeMaxPerfilesPrimeV =(e)=>{
		this.setState({s_MaxPerfilesPrimeV: e.target.value})
	}
	
	HandleChangeMaxPerfilesParamount =(e)=>{
		this.setState({s_MaxPerfilesParamount: e.target.value})
	}

    HandleChangeMaxPerfilesYoutube =(e)=>{
		this.setState({s_MaxPerfilesYoutube: e.target.value})
	}

	HandleChangeMaxPerfilesPornHub =(e)=>{
		this.setState({s_MaxPerfilesPornHub: e.target.value})
	}

	HandleChangeMaxPerfilesCrunchyroll =(e)=>{
		this.setState({s_MaxPerfilesCrunchyroll: e.target.value})
	}

	HandleChangeBusinessNamePerfilInfo =(e)=>{

		this.setState({CurrentMaxCharBusinessPerfil: 30 - e.target.value.length})
		if (e.target.value.length <= 30) {
			this.setState({s_BusinessNamePerfilInfo: e.target.value})

		} else {
			this.setState({CurrentMaxCharBusinessPerfil: 0})
		}
		
	}

	HandleChangePasswordPerfilInfo =(e)=>{
		this.setState({CurrentMaxCharPasswordPerfilInfo: 16 - e.target.value.length})
		if (e.target.value.length <= 16) {
			this.setState({s_PasswordPerfilInfo: e.target.value})

		} else {
			this.setState({CurrentMaxCharPasswordPerfilInfo: 0})
		}
	}

	VerDetallesDeAlertaSistema = (TipoCuentaCodigo, NombreContacto, Correo, Tipo)=>{
		const Data = {
			TipoCuentaCodigo : TipoCuentaCodigo, 
			NombreContacto : NombreContacto,
			Correo : Correo
		}
		this.setState({detallesalertasistema: Data})
		if(Tipo === 'perfil'){
		    this.setState({portal: false})
		    this.setState({miscontactos: false})
		    this.setState({mispinesnetflix: false})
		    this.setState({miscuentas: false})
		    this.setState({backup: false})
		    this.setState({misusuarios: false})
		    this.setState({miskeydeactivacion: false})
			this.setState({misperfiles: true})
			this.setState({portal: false})
			
		}else if(Tipo === 'cuenta'){
			this.setState({portal: false})
		    this.setState({miscontactos: false})
		    this.setState({mispinesnetflix: false})
		    this.setState({miscuentas: true})
		    this.setState({backup: false})
		    this.setState({misusuarios: false})
		    this.setState({miskeydeactivacion: false})
			this.setState({misperfiles: false})
			this.setState({portal: false})
		}
	}

	HandleVaciarDetallesAlertaSistema = (event)=>{
		this.setState({detallesalertasistema: {TipoCuentaCodigo: '', NombreContacto : '', Correo: ''}})
	}

	HandleHideMenuLeft = (event) => {
        this.setState({showmenu: !this.state.showmenu})
	}

	HandleCloseNuevoCombo = (event) => {
        this.setState({nuevocombo: false})
	}

	HandleActualizarPlataformasCombo = (event) => {
		this.setState({listaplataformascombo : event})
	}


render(){
	const { socket, user, logout, p_editarpasswordperfilinfo, p_editarbusinessperfilinfo, p_BloquearDesbloquearUsuario, esADMIN, p_CambiarImagenPerfil } = this.props
	const { showmenu } = this.state

	return(

		
		<div className="container">
			
			{
				!this.state.usuarioexpirado ? 
				user.state === 1 ? (
				<>
				<div className='div-menu'><IconButton color='primary' style={{position:'absolute', zIndex: 9999, overflow: 'none', marginTop: 15, marginLeft: 5}} onClick={this.HandleHideMenuLeft}><MdMenu color='#D5D8DC'></MdMenu></IconButton></div>
				<div className={showmenu ? 'show-main-left-false':'main-left'}>
				<MainLeft
				    s_showmenu={showmenu}
				    esADMIN={esADMIN}
				    socket={socket}
					user={user}
					logout={logout}
					NuevoContacto={this.HandleNuevoContacto}
					MisContactos={this.HandleMisContactos}
					p_Portal={this.HandlePortal}
					p_Mispinesnetflix={this.HandleMisPinesNetflix}
					p_Nuevopinnetflix={this.HandleNuevoPinNetflix}
					p_NuevaCuenta={this.HandleNuevaCuenta}
					p_MisCuentas={this.HandleMisCuentas}
					p_NuevoPerfil={this.HandleNuevoPerfil}
					p_MisPerfiles={this.HandleMisPerfiles}
					p_Backup={this.HandleBackup}
					p_NuevoCodigo={this.HandleNuevoCodigo}
					p_MisUsuarios={this.HandleMisUsuarios}
					p_AbrirPerfilInfo={this.HandleAbrirPerfilInfo}
					p_MisKeyDeActivacion={this.HandleMisKeyDeActivacion}
					p_Plataformas={this.HandlePlataformas}
					p_usuarioexpirado={this.HandleUsuarioExpirado}
					p_MensajeDelServidor={this.HandleMensajeDelServidor}
                    p_Reportar={this.HandleReportar}
					p_NuevoCombo={this.HandleNuevoCombo}
					/>
				</div>

				<div className={showmenu ? 'show-main-right-complete':'main-right'}>
					<MainRight
					/*ABRE DATA*/
					p_usuarioinfo={this.state.usuarioinfo}
					p_listausuarios={this.state.listaUsuarios}
                    p_listacontactos={this.state.listaContactos}
					p_borrarcontacto={this.EliminarContacto}
					p_editarcontacto={this.EditarContacto}
					p_listacuentas={this.state.listaCuentas}
					p_listaplataformas={this.state.listaPlataformas}
					p_listaperfiles={this.state.listaperfiles}
					p_listacodigos={this.state.listacodigos}
					p_listamensajesservidor={this.state.listamensajeservidor}
					p_borrarcuenta={this.EliminarCuenta}
					p_EditarCuenta={this.EditarCuenta}
					p_EditarCuentaEstadoUso={this.EditarCuentaEstadoUso}
					p_borrarperfil={this.EliminarPerfil}
					p_EditarPerfil={this.EditarPerfil}
					p_borrarkey={this.EliminarKey}
					p_editarplataformas={this.EditarPlataformas}
					p_editarpasswordperfilinfo={p_editarpasswordperfilinfo}
					p_editarbusinessperfilinfo={p_editarbusinessperfilinfo}
					p_NuevoCombo={this.state.nuevocombo}
					p_listaplataformascombo = {this.state.listaplataformascombo}
					s_SetPlataformasCombo={this.HandleActualizarPlataformasCombo}
					/*CIERRA DATA*/


					user={user}
					socket={socket}
					esADMIN={esADMIN}
					p_nuevocontacto={this.state.nuevocontacto}
					handleclosenuevocontacto={this.HandleCerrarNuevoContacto}
					p_miscontactos={this.state.miscontactos}
					handleclosemiscontactos={this.HandleCerrarMiscontactos}
					handleclosemiskeys={this.HandleCerrarMisKeys}
					p_mispinesnetflix={this.state.mispinesnetflix}
					p_handlecerrarmispinesnetflix={this.HandleCerrarMisPinesNetflix}
					p_nuevopinnetflix={this.state.nuevopinnetflix}
					p_handlecerrarnuevopinnetflix={this.HandleCerrarNuevoPinNetflix}
					p_miscuentas={this.state.miscuentas}
					p_nuevacuenta={this.state.nuevacuenta}
					p_handlecerrarmiscuentas={this.HandleCerrarMisCuentas}
					p_handlecerrarnuevacuenta={this.HandleCerrarNuevaCuenta}
					p_misperfiles={this.state.misperfiles}
					p_nuevoperfil={this.state.nuevoperfil}
					p_plataformas={this.state.plataformas}
					p_handlecerrarplataformas={this.HandleCerrarPlataformas}
					p_handlecerrarmisperfiles={this.HandleCerrarMisPerfiles}
					p_handlecerrarnuevoperfil={this.HandleCerrarNuevoPerfil}
					p_backup={this.state.backup}
					p_nuevocodigo={this.state.nuevocodigo}
					p_misusuarios={this.state.misusuarios}
					p_miskeydeactivacion={this.state.miskeydeactivacion}
					p_handlecerrarbackup={this.HandleCerrarBackup}
					p_handlecerrarnuevocodigo={this.HandleCerrarNuevoCodigo}
					p_handlecerrarmisusuarios={this.HandleCerrarMisUsuarios}
                    p_abrirperfilinfo={this.state.abrirperfilinfo}
					p_handlecerrarperfilinfo={this.HandleCerrarPerfilInfo}
					p_mensajedelservidor={this.state.mensajedelservidor}
					p_reportar={this.state.reportar}
					s_cerrarmensajedelservidor={this.HandleCerrarMensajeDelServidor}
					s_cerrarreportar={this.HandleCerrarReportar}
					p_CambiarImagenPerfil={p_CambiarImagenPerfil}

					s_MaxPerfilesNetflix={this.state.s_MaxPerfilesNetflix}
					s_MaxPerfilesMovPlay={this.state.s_MaxPerfilesMovPlay}
					s_MaxPerfilesDisney={this.state.s_MaxPerfilesDisney}
					s_MaxPerfilesHbo={this.state.s_MaxPerfilesHbo}
					s_MaxPerfilesPrimeV={this.state.s_MaxPerfilesPrimeV}
					s_MaxPerfilesParamount={this.state.s_MaxPerfilesParamount}
					s_MaxPerfilesPornHub={this.state.s_MaxPerfilesPornHub}
					s_MaxPerfilesYoutube={this.state.s_MaxPerfilesYoutube}
					s_MaxPerfilesCrunchyroll={this.state.s_MaxPerfilesCrunchyroll}
					p_handleabrirnuevocontacto={this.HandleNuevoContacto}

					p_HandleChangeMaxPerfilesNetflix={this.HandleChangeMaxPerfilesNetflix}
					p_HandleChangeMaxPerfilesMovPlay={this.HandleChangeMaxPerfilesMovPlay}
					p_HandleChangeMaxPerfilesDisney={this.HandleChangeMaxPerfilesDisney}
					p_HandleChangeMaxPerfilesHbo={this.HandleChangeMaxPerfilesHbo}
					p_HandleChangeMaxPerfilesPrimeV={this.HandleChangeMaxPerfilesPrimeV}
					p_HandleChangeMaxPerfilesParamount={this.HandleChangeMaxPerfilesParamount}
					p_HandleChangeMaxPerfilesPornHub={this.HandleChangeMaxPerfilesPornHub}
					p_HandleChangeMaxPerfilesYoutube={this.HandleChangeMaxPerfilesYoutube}
					p_HandleChangeMaxPerfilesCrunchyroll={this.HandleChangeMaxPerfilesCrunchyroll}

	                s_PasswordPerfilInfo={this.state.s_PasswordPerfilInfo}
	                s_BusinessNamePerfilInfo={this.state.s_BusinessNamePerfilInfo}
					s_CurrentMaxCharBusinessPerfil={this.state.CurrentMaxCharBusinessPerfil}
					s_CurrentMaxCharPasswordPerfilInfo={this.state.CurrentMaxCharPasswordPerfilInfo}

	                s_HandleChangePasswordPerfilInfo={this.HandleChangePasswordPerfilInfo}
	                s_HandleChangeBusinessNamePerfilInfo={this.HandleChangeBusinessNamePerfilInfo}

					s_Top1={this.state.Top1}
					s_Top2={this.state.Top2}
					s_Top3={this.state.Top3}

					p_HandleActualizarTop3Perfiles={this.HandleActualizarTop3Perfiles}
					s_gananciamesactual={this.state.s_gananciamesactual}
					s_cantidadperfiles={this.state.s_cantidadperfiles}
					s_portal={this.state.portal}
					s_PerfilesVencidos={this.state.PerfilesVencidos}
					ActualizarPerfilesVencidos={this.HandleActualizarPerfilesVencidos}
					ActualizarMensajesServidorVencidos={this.HandleActualizarMensajesServidorVencidos}
					p_VerDetallesDeAlertaSistema={this.VerDetallesDeAlertaSistema}
					s_detallesalertasistema={this.state.detallesalertasistema}
					s_isloadingalertasservidor={this.state.isloadingalertasservidor}
					s_isloadingcontactos={this.state.isloadingcontactos}
					s_isloadingcuentas={this.state.isloadingcuentas}
					s_isloadingperfiles={this.state.isloadingperfiles}
					s_vaciardetallesalertasistema={this.HandleVaciarDetallesAlertaSistema}
					p_closenuevocombo={this.HandleCloseNuevoCombo}
					/></div></>
				
					
				):(<div className='usuario-bloqueado'>
					<div className='sub-usuario-bloquedo'>
					<div className='info-head'><MdLock size={64} style={{marginRight: 5}}/> Usuario bloqueado!</div>
					<div className='info-body'>Si desconoces el motivo por el cual fuiste bloqueado, comuniquese por favor con el ADMINISTRADOR.</div>
					</div>
					</div>)
					:<div className='usuario-expirado'>
					<div className='sub-usuario-expirado'>
					<div className='info-head'><MdOutlineAccessTimeFilled size={64} style={{marginRight: 5}}/> Usuario expirado!</div>
					<div className='info-body'>Para renovar su usuario comuniquese por favor con el ADMINISTRADOR.</div>
					</div>
					</div>
			}
		</div>
		);
	}

}

