import React, { useState } from 'react';
import { VERIFY_USER } from '../../Events'
import { IoCloseSharp, IoEyeOff, IoFootsteps } from 'react-icons/io5';
import Button from '@mui/material/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import UserDataModel from '../../../src/models/UserDataModel';
const { v4: uuidv4 } = require('uuid');

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { MdAccountCircle, MdLock, MdVpnKey } from 'react-icons/md';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { IconButton } from '@mui/material';

export default function LoginForm(props) {

	const { 
        socket
    } = props
	const [LoginInicial, SetLoginInicial] = useState(true)
	const [ActivarCuenta, SetActivarCuenta] = useState(false)
	const [IniciarSesion, SetIniciarSesion] = useState(false)
	const [VerPasswordActivar, SetVerPasswordActivar] = useState(false)
	const [VerPasswordInicio, SetVerPasswordInicio] = useState(false)
	const [s_UsuarioSesion, SetUsuarioSesion] = useState("")
	const [s_PasswordSesion, SetPasswordSesion] = useState("")

	const [s_UsuarioActivarCuenta, SetUsuarioActivarCuenta] = useState("")
	const [s_PasswordActivarCuenta, SetPasswordActivarCuenta] = useState("")
	const [s_KeyActivarCuenta, SetKeyActivarCuenta] = useState("")

	const [s_CurrentMaxCharPasswordActivar, SetCurrentMaxCharPasswordActivar] = useState(16)
	const [s_CurrentMaxCharUsuarioActivar, SetCurrentMaxCharUsuarioActivar] = useState(10)

	const alertaAceptar = (titulo, texto, icon, boton) => {
		Swal.fire({
			title: titulo,
			html: texto,
			icon: icon,
			button: boton,
			confirmButtonColor : '#2C3E50',
			/*timer: 2000*/
		})/*.then(respuesta=>{
			if(respuesta)
			{
				smal({
					text: "El archivo se elimino correctamente",
					icon: "success",
					timer: 3000
				})
			}
		})*/
	}

	const setUser = ({ user, isUser }) => {
		if (isUser) {
			UserDataModel.VerificaSiCredencialesCorrecto( s_UsuarioSesion, s_PasswordSesion, res => {
				if(res === true){
					props.setUser(user)
				}else{
					Swal.fire({
						text: "Contraseña incorrecta, porfavor verifica otra vez!",
						icon: "warning",
						timer: 8000,
						confirmButtonColor : '#2C3E50',
					})
				}
			})
			/****/
		} else {
			alertaAceptar("Alerta de Hola APP", "El usuario no esta registrado", "warning", "Aceptar")
		}
	}

	const HandleSubmitIniciarSesion = (e) => {
		e.preventDefault()
			UserDataModel.GetUsers( (UsersRegistered, res) => {
				if (res === true) {
					UserDataModel.GetUsersByID(s_UsuarioSesion,  (userinfoByID) => {
						socket.emit(VERIFY_USER, s_UsuarioSesion, UsersRegistered, userinfoByID, setUser)
					})
				}
				else {
					alertaAceptar('Alerta de hola APP', 'Se ah producido un error al conectar con FIRESTORE', 'error', 'Aceptar')
				}
			})

	}

	const e_NuevoUsuarioRegisrado = (data) =>{
		socket.emit('NUEVOUSUARIOREGISTRADO', data)
	} 


	const HandleSubmitActivarCuenta = (e) => {
		e.preventDefault()
		if(s_KeyActivarCuenta.length < 1 || 
			s_UsuarioActivarCuenta.length < 1 || 
			s_PasswordActivarCuenta.length < 1){
				Swal.fire({
					text: "Uno o más de los campos requeridos están vacios.",
					icon: "warning",
					timer: 8000,
					confirmButtonColor : '#2C3E50',
				})
			}else if(s_PasswordActivarCuenta.length < 8){
				Swal.fire({
					text: "La contraseña debe tener como mínimo 8 caracteres.",
					icon: "warning",
					timer: 8000,
					confirmButtonColor : '#2C3E50',
				})
			}else if(s_UsuarioActivarCuenta.length < 5){
				Swal.fire({
					text: "El usuario debe tener como mínimo 5 caracteres.",
					icon: "warning",
					timer: 8000,
					confirmButtonColor : '#2C3E50',
				})
			}else{
				UserDataModel.VerificaSiKeyExiste( s_KeyActivarCuenta, (data, res) => {
					if(res === true){
						var HoyEs = Date.now()
						var TiempoExpiracion = (Math.floor(Number(HoyEs/1000) + Number(data.TiempoExpiracion * 24 * 3600)))*1000
						var UserId = uuidv4()
						UserDataModel.NuevoUsuario(
							TiempoExpiracion,
							s_UsuarioActivarCuenta,
							s_PasswordActivarCuenta, 
							UserId, 
							socket.id, (res1, res3) => {
								if(res1 === true){
									UserDataModel.ActualizarCodigoUsado(
										s_UsuarioActivarCuenta,
										s_KeyActivarCuenta, res2 =>{
										 if(res2 === true){
											const NuevoDato = {
												u_Key : s_KeyActivarCuenta,
												u_quienusa : s_UsuarioActivarCuenta
											}
											const finalResult = Object.assign(res3, NuevoDato);
											e_NuevoUsuarioRegisrado(finalResult)
											Swal.fire({
												html: "El Usuario se ah registrado correctamente. Ahora ya puedes iniciar sesión!",
												icon: "success",
												timer: 8000,
												confirmButtonColor : '#2C3E50',
											})
											SetIniciarSesion(true)
											SetLoginInicial(false)
											SetActivarCuenta(false)
											SetUsuarioActivarCuenta("")
											SetPasswordActivarCuenta("")
											SetKeyActivarCuenta("")
										 }else{
											Swal.fire({
												text: "Ocurrio un problema con FireStore.",
												icon: "warning",
												timer: 8000,
												confirmButtonColor : '#2C3E50',
											})
										 }
									})
								}else{
									Swal.fire({
										text: "Ocurrio un problema con FireStore.",
										icon: "warning",
										timer: 8000,
										confirmButtonColor : '#2C3E50',
									})
								}
	
								if(res1 === 2){
									Swal.fire({
										html: "El nombre de usuario " +  s_UsuarioActivarCuenta + " actualmente está registrado, porfavor intenta con otro nombre.",
										icon: "warning",
										timer: 8000,
										confirmButtonColor : '#2C3E50',
									})
								}
							})
						
					}else{
						Swal.fire({
							html: "El código <b>" + s_KeyActivarCuenta + "</b> es inválido!",
							icon: "warning",
							timer: 6000,
							confirmButtonColor : '#2C3E50',
						})
					}
				})
			}
	}

	const HandleActivarCuenta = (e) => {
		SetActivarCuenta(true)
		SetIniciarSesion(false)
		SetLoginInicial(false)
	}

	const HandleIniciarSesion = (e) => {
		SetActivarCuenta(false)
		SetIniciarSesion(true)
		SetLoginInicial(false)
	}

	const HandleCerrarActivarCuenta = (e) => {
		SetActivarCuenta(false)
		SetIniciarSesion(false)
		SetLoginInicial(true)
		SetVerPasswordActivar(false)
		SetVerPasswordInicio(false)
	}

	const HandleCerrarIniciarSesion = (e) => {
		SetActivarCuenta(false)
		SetIniciarSesion(false)
		SetLoginInicial(true)
		SetVerPasswordActivar(false)
		SetVerPasswordInicio(false)
	}

	const HandleVerPasswordActivar = (e) => {
		SetVerPasswordActivar(!VerPasswordActivar)
	}

	const HandleVerPasswordInicio = (e) => {
		SetVerPasswordInicio(!VerPasswordInicio)
	}

	const HandleChangeUsuarioSesion = (e) => {
		SetUsuarioSesion(e.target.value)
	}

	const HandleChangePasswordSesion = (e) => {
		SetPasswordSesion(e.target.value)
	}

	const HandleChangeUsuarioActivarCuenta = (e) => {
		SetCurrentMaxCharUsuarioActivar(10 - e.target.value.length)
		if (e.target.value.length <= 10) {
			SetUsuarioActivarCuenta(e.target.value)

		} else {
			SetCurrentMaxCharUsuarioActivar(0)
		}
	}

	const HandleChangePasswordActivarCuenta = (e) => {
		SetCurrentMaxCharPasswordActivar(16 - e.target.value.length)
		if (e.target.value.length <= 16) {
			SetPasswordActivarCuenta(e.target.value)

		} else {
			SetCurrentMaxCharPasswordActivar(0)
		}
	}

	const HandleChangeKeyActivarCuenta = (e) => {
		SetKeyActivarCuenta(e.target.value)
	}

	const useStyles = makeStyles((theme) => ({

		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},

		selectEmpty: {
			marginTop: theme.spacing(2),
		},
	}));
	const classes = useStyles;

	function Copyright() {
		return (
			<><Typography variant="body1" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="">
				Castle APP
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
			<div className='version'>version 1.0</div>
		</Typography></>
		);
	}

	return (

		<div className="login">
			<div className='logo-server'><IoFootsteps color='#091422' size={350}/> Castle APP</div>
			<div className="login-form" key="login_form">
				{
					LoginInicial && (
					<div className='login-tipos-iniciar'>
						<Button 
						style={{width:'100%', height:80, marginLeft: 'auto', marginRight: 'auto'}} 
						onClick={HandleActivarCuenta} 
						size='large' 
						variant="contained" 
						color="success" 
						>
						Activar Mi Cuenta
						</Button>

						<Button 
						style={{width:'100%', height:80, marginLeft: 'auto', marginRight: 'auto', marginTop:10}} 
						onClick={HandleIniciarSesion}
						size='large' 
						variant="contained" 
						color="info" 
						>
						Ingresar A Mi Cuenta
						</Button>
				    </div>
				)
				}
				
				{
					ActivarCuenta && (
						<div className='login-tipos'>
							<div className="title-pages-modal-login">
                                <div className="title_ico_pages_modal">
								<IconButton color='primary' style={{background:'#091422'}} onClick={HandleCerrarActivarCuenta}><IoCloseSharp color="#B4C0C8" size="32" /></IconButton>
                                </div>
                                <div className="div_title_pages_login">ACTIVAR CUENTA</div>
                            </div>
                        <form className='form-login' onSubmit={HandleSubmitActivarCuenta} noValidate>
						<TextField
						autoFocus
						style={{width:'100%', marginTop:10, marginBottom: 10}} id="filled-basic2" label="Codigo de activacion" variant="outlined" 
						helperText="Ingresa el código de activación proporcionado por el ADMINISTRADOR"
						InputProps={{startAdornment:<MdVpnKey size={32} style={{paddingRight:6}} />}}
						value={s_KeyActivarCuenta}
						onChange={HandleChangeKeyActivarCuenta}
						autoComplete="off"
						/>


                        <TextField 
						style={{width:'100%'}} id="filled-basic" label="Usuario" variant="outlined"
						helperText="Ingresa el usuario que registrarás"
						InputProps={{startAdornment:<MdAccountCircle size={32} style={{paddingRight:6}} />,
						endAdornment:<span style={{color:'#3A3A3A'}}>{s_CurrentMaxCharUsuarioActivar}</span>}}
						value={s_UsuarioActivarCuenta}
						onChange={HandleChangeUsuarioActivarCuenta}
						autoComplete="off"
						 />

						<TextField
						type={VerPasswordActivar ? 'text':'password'}
						style={{width:'100%', marginTop:10}} id="filled-basic1" label="Contraseña" variant="outlined"
						helperText="Ingresa la contraseña que registrarás (min : 8 caracteres)"
						InputProps={{startAdornment:<MdLock size={32} style={{paddingRight:6}} />,
						endAdornment:<><span style={{color:'#3A3A3A'}}>{s_CurrentMaxCharPasswordActivar}</span><IconButton color='primary' onClick={HandleVerPasswordActivar}>
						{VerPasswordActivar ? <IoMdEyeOff color='#3A3A3A'/>:<IoMdEye color='#3A3A3A'/>}
					    </IconButton></>}}
						value={s_PasswordActivarCuenta}
						onChange={HandleChangePasswordActivarCuenta}
						autoComplete="off"
						/>
						
						<Button
						disabled={s_PasswordActivarCuenta.length > 7 && s_UsuarioActivarCuenta.length > 4
							&& s_KeyActivarCuenta.length > 30 ? false:true} 
						style={{width:'100%', height:60, marginLeft: 'auto', marginRight: 'auto'}} 
						onClick={HandleActivarCuenta} 
						size='large' 
						variant="contained" 
						color="success"
						type='submit'
						>
						Activar Cuenta
						</Button>
					</form>
					</div>
					)
				}


				{
					IniciarSesion && (
                        <div className='login-tipos'>
							<div className="title-pages-modal-login">
                                <div className="title_ico_pages_modal">
									<IconButton color='primary' style={{background:'#091422'}} onClick={HandleCerrarIniciarSesion}><IoCloseSharp color="#B4C0C8" size="32" /></IconButton>
                                </div>
                                <div className="div_title_pages_login">INICIAR SESION</div>
                            </div>
                        <form className='form-login' onSubmit={HandleSubmitIniciarSesion} noValidate>
						<TextField
						autoFocus
						style={{width:'100%'}} id="filled-basic4" label="Usuario" variant="outlined" 
						helperText="Ingresar usuario"
						InputProps={{startAdornment:<MdAccountCircle size={32} style={{paddingRight:6}} />}}
						value={s_UsuarioSesion}
						onChange={HandleChangeUsuarioSesion}
						autoComplete="off"
						/>

						<TextField
						type={VerPasswordInicio ? 'text':'password'}
						style={{width:'100%', marginTop:10, marginBottom: 10}} id="filled-basic5" label="Contraseña" variant="outlined" 
						helperText="Ingresar contraseña"
						InputProps={{startAdornment:<MdLock size={32} style={{paddingRight:6}} />,
						endAdornment:<IconButton color='primary' onClick={HandleVerPasswordInicio}>
						{VerPasswordInicio ? <IoMdEyeOff color='#3A3A3A'/>:<IoMdEye color='#3A3A3A'/>}
					    </IconButton>}}
						value={s_PasswordSesion}
						onChange={HandleChangePasswordSesion}
						autoComplete="off"
						/>
    
						<br />
						<Button
						disabled={s_PasswordSesion.length > 7 && s_UsuarioSesion.length > 4 ? false:true} 
						style={{width:'100%', height:60, marginLeft: 'auto', marginRight: 'auto'}} 
						size='large' 
						variant="contained" 
						color="info"
						type='submit'
						>
						Iniciar Sesión
						</Button>
					</form>
					</div>

					)
				}
				<div className='login-footer'>
				<Box mt={2}>
					<Copyright />
				</Box>
				</div>
			</div>
		</div>

	);
}

