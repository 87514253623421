import { IconButton } from '@mui/material';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { IoMdContact, IoMdUnlock } from 'react-icons/io';
import { IoLockClosed, IoLockOpen } from 'react-icons/io5';
import { FaChessKing } from 'react-icons/fa';

import { MdAccountCircle, MdAdminPanelSettings, MdDelete, MdInfo, MdLock, MdModeEdit, MdSupervisorAccount, MdTimer } from 'react-icons/md';
import { CalcularExpiracion, setUnixToTimeFormat } from '../../../Funciones';


export default function ListaMisUsuarios(props) {
    const { 
        User,
        listausuarios,
        p_HandleAbrirEditarUsuario,
        p_HandleBloquearDesbloquearUsuario
    } = props
    return (
        <div className="container_info_contactos">
{listausuarios.length > 0 ? (
            <div className="group_items">
             
                    {
                        listausuarios.sort((a,b) => b.u_autority - a.u_autority || a.u_expiration - b.u_expiration)
                        .map((___u, index) => {
                            var id = ___u.u_id
                            var user = ___u.u_user
                            var password = ___u.u_password
                            var autority = ___u.u_autority
                            var businessname = ___u.u_businessName
                            var state = ___u.u_state
                            var expiration = ___u.u_expiration
                            var created_at = ___u.u_created_at
                            return (
                                <div
                                    key={id}
                                    className="item_container"
                                >
                                <div className="item-block">
                                    <div className='icon_left'>
                                            <MdAccountCircle size={45}/>
                                    </div>

                                    <div className='item-left-users'>
                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-correo">{user}</div>
                                            <div className="item_list-cuentas-tipo">{businessname}</div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className={CalcularExpiracion(expiration) == 'Caducado' ? "item_list-cuentas-fechaexp-caducado":"item_list-cuentas-fechaexp"}>
                                            <div className='icon-adorno'>
                                                <div><MdTimer size="24" /></div>
                                                <div className='text-exp'>{CalcularExpiracion(expiration)}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-created">{setUnixToTimeFormat('info_contacto', created_at)}</div>
                                            <div className="user-options">
                                                {autority === 10 && <div className='superadmin'>SUPER ADMIN <FaChessKing style={{marginLeft: 4}} size={20}/></div>}
                                                {autority === 5 && <div className='admin'>ADMINISTRADOR <MdAccountCircle style={{marginLeft: 4}} size={20}/></div>}
                                                {autority === 1 && <div className='registrado'>REGISTRADO <MdAccountCircle style={{marginLeft: 4}} size={20}/></div>}
                                                {state === 0 && <div className='locked'>BLOQUEADO <MdLock style={{marginRight: 0}} size={20}/></div>}
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className='icon_right_users'>
                                               { 
                                                state === 1 ? 
                                                    <IconButton className={(autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) &&  'disabled'} 
                                                        disabled={ (autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) ? true:false} 
                                                            onClick={() => p_HandleBloquearDesbloquearUsuario(id, user, 'Bloquear', autority)} 
                                                            color='primary' 
                                                            data-title='Bloquear'>
                                                                <IoLockClosed color="#C64141" size={32}/>
                                                    </IconButton>:
                                                    <IconButton className={(autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) &&  'disabled'} 
                                                    disabled={ (autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) ? true:false} 
                                                    onClick={() => p_HandleBloquearDesbloquearUsuario(id, user, 'Desbloquear', autority)} color='primary' data-title='Desbloquear'><IoLockOpen color="#41C681" size={32}/></IconButton>
                                               } 
                                               
                                                <>
                                                <IconButton 
                                                    className={(autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) & User.autority != 10 &&  'disabled'} 
                                                    disabled={ (autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) &  User.autority != 10 ? true:false}
                                                    onClick={() => p_HandleAbrirEditarUsuario(
                                                    id,
                                                    user,
                                                    password,
                                                    autority,
                                                    businessname,
                                                    state,
                                                    expiration,
                                                    created_at,
                                                    )} data-title='Editar' color='primary' style={{marginLeft: 5}}>
                                            <MdModeEdit
                                                color="#FFD75B"
                                                size={32}
                                            />
                                            </IconButton>
                                            <IconButton 
                                            className={(autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) &&  'disabled'} 
                                            disabled={ (autority === 5 || autority=== 10) & (User.autority === 5 || User.user === user) ? true:false}
                                            data-title='Eliminar' color='primary' style={{marginLeft: 5}}>
                                                <MdDelete
                                                    color="#C64141"
                                                    /* onClick={() => props.handleRemoveContact(index, Identificador)}**/
                                                    size={32}
                                                />
                                                </IconButton>
                                          
                                                </>
                                                
                                            
                                    </div>
                                </div>

                                </div>

                            )
                        })
                    }
            </div>
):(<div className='sin_datos'><div className='sin_datos_left'><MdInfo color='#F1C40F' size={64}/></div><div className='sin_datos_right'> No se ah encontrado ningún dato registrado!</div></div>)
}
        </div>
    )
}