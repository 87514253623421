import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import Grid from '@material-ui/core/Grid';
import UserDataModel from '../../../models/UserDataModel';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { MdSearch } from 'react-icons/md';
import ListaMisCodigos from './ListaMisCodigos';
import { AlertTitle, Autocomplete } from '@mui/material';
import { Alert } from '@mui/material';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function MisCodigos(props) {
    const {
        p_listakeys,
        p_borrarkey,
        esADMIN
    } = props

    const [loading, SeLoading] = useState(false)
    const [searchKey, SetSearchKey] = useState("")

    useEffect(async () => {
       await SeLoading(true)
    
    }, [])

    const alertaAceptar = (titulo, texto, icon, boton) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }
        
        const HandleEliminarKey = (id, EstadoUso, Identificador) => {
            event.preventDefault();
            if(EstadoUso === 1){
                Swal.fire({
                    text: "El código ya se usó y no se puede eliminar!",
                    icon: "warning",
                    timer: 4500,
                    confirmButtonColor : '#2C3E50',
                })
            }else{
                alertaConfirmar('Eliminar Código', 'Estas seguro que quieres eliminar este código?', 'question', (res_alert) => {
                
                    if (res_alert === true) {
                        p_listakeys.map((value, index) => {
                            
                            if (Identificador === value.Identificador) {
                                UserDataModel.BorrarKey( value.Identificador, (res) => {
                                    if (res === true) {
                                        const newListKeys = p_listakeys.filter((__c) => __c.Identificador !== value.Identificador);
                                        p_borrarkey(newListKeys)
                                       Swal.fire({
                                            html: "El código se eliminó correctamente",
                                            icon: "success",
                                            timer: 2500,
                                            confirmButtonColor : '#2C3E50',
                                        })
                                    }
                                    else {
                                        alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el código!', 'error', 'Aceptar')
                                    }
        
                                })
        
                            }
                        })
                    }
                    else {
                        alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el código!', 'error', 'Aceptar')
                    }
                })
            }
            
        }

        const handleclosemiskeys = (e) => {
            props.handleclosemiskeys(false)
        }
    

    const handleFilterKeys = p_listakeys.filter(_c => {
            return _c.Codigo.toLowerCase().includes(searchKey.toLowerCase())
    })
    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={handleclosemiskeys} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis códigos de activación <div className='contador-div'>{p_listakeys.length}</div></div>
            </div>
            { esADMIN ?
            <div className='main-right-info-container'>
                <div className="search_contact">
                <Grid container spacing={2}>
                    <MuiThemeProvider theme={theme}>
                        <TextField
                            type="text"
                            id="search_contact_contacts"
                            autoComplete="off"
                            variant='outlined'
                            required
                            fullWidth
                            placeholder="Buscar Código"
                            value={searchKey}
                            onChange={(e) => SetSearchKey(e.target.value)}
                            autoFocus
                            InputLabelProps={{ style: { color: '#ADB7BA', background: 'none', paddingLeft: 10 } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            /*onClick={this.handleSubmit}*/
                                            style={
                                                {
                                                    cursor: "pointer"
                                                }}
                                            color="rgb(132, 139, 144)"
                                            size="24"
                                        />

                                    </InputAdornment>
                                ),
                                style: ({
                                    border: 'none',
                                    outline: 'none',
                                    width: '97%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    background: '#061d33',
                                    borderRadius: 0,
                                    height: 55,
                                    position : 'relative'
                                })
                            }}
                            inputProps={{
                                style: {
                                    background: 'transparent',
                                    color: '#ADB7BA',
                                    fontSize: 15
                                },
                                maxLength: 50
                            }}
                            color='primary'
                        />
                    </MuiThemeProvider>
                </Grid>
              </div>
              { loading ? (
              <ListaMisCodigos
              listaKeys={handleFilterKeys}
              p_HandleEliminarKey={HandleEliminarKey}
              />
              ) :
                (
                    <div className="preloader" style={{ width: 75, height: 75, marginLeft: '41%', marginTop: 25 }}></div>
                )

            }
            </div> :<div className='sin-autoridad'>
				<Alert className='effect' 
				style={{marginBottom: 10}} 
				severity='warning'>
				<AlertTitle style={{fontWeight: 'bolder', fontSize: 18}}><b>Contenido no autorizado!</b></AlertTitle>
				<b>Alerta del sistema : </b>No tienes autoridad suficiente para poder ver este contenido!
				</Alert>
					</div>}
            
       </div>
    )
}


