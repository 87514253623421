import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import swal from 'sweetalert'
import UserDataModel from '../../../models/UserDataModel';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import { IconButton } from '@mui/material';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function pines_netflix(props) {
    const { user, p_handleclosepinesnetflix } = props
    const [] = useState(false)

    const handleclosepinesnetflix = (e) =>{
        p_handleclosepinesnetflix(false)
    }

    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={handleclosepinesnetflix} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis Pines Netflix</div>
            </div>
       </div>
    )
}


