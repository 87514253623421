import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import Grid from '@material-ui/core/Grid';
import UserDataModel from '../../../models/UserDataModel';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { MdSave, MdSearch, MdUpdate } from 'react-icons/md';
import ListaContactos from './ListaContactos';
import { PermiteSoloLetras, PermiteSoloNumeros } from '../../../Funciones';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function Contactos(props) {
    const { 
        user,
        p_listacontactos, 
        p_borrarcontacto,
        p_editarcontacto
    } = props
    const [OpenAddNewContact, SetOpenAddNewContact] = useState(false)
    const [OpenContactEdit, SetOpenContactEdit] = useState(false)
    const [CurrentMaxCharNombre, setCurrentMaxCharNombre] = useState("")
	const [CurrentMaxCharCelular, setCurrentMaxCharCelular] = useState("")
    
    /** EDIT */
    /**** NEW DATES */
    const [NumeroCelular, SetNumeroCelular] = useState("")
    const [NombreCompleto, SetNombre] = useState("")
    const [Identificador, SetIdentificador] = useState("")
    /****  OLD DATES */
    /** END EDIT */
    const [index, SetIndex] = useState("")
    const [loading, SeLoading] = useState(false)
    const [searchContact, SetSearchContact] = useState("")

    useEffect(async () => {
       await SeLoading(true)
    
    }, [])

    const alertaAceptar = (titulo, texto, icon, boton) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }

    const handleOpenAddNewContact = (event) => {
        event.preventDefault();
        SetOpenAddNewContact(!OpenAddNewContact)
    }
    const handleOpenEditContact = (index, Identificador, number, name) => {
        event.preventDefault();
        SetOpenContactEdit(true)
        SetIndex(index)
        SetNumeroCelular(number)
        SetNombre(name)
        SetIdentificador(Identificador)
        setCurrentMaxCharCelular(9 - number.length)
        setCurrentMaxCharNombre(35 - name.length)
    }
    const handleEliminarContacto = (id, Identificador, nombre) => {
        event.preventDefault();
        UserDataModel.VerificaSiContactoEstaEnUso(user, Identificador, resp =>{
            if(resp === true){
                Swal.fire({
                    html: "El contacto <b>" + nombre + "</b> está actualmente en uso y no se puede eliminar.",
                    icon: "warning",
                    timer: 6500,
                    confirmButtonColor : '#2C3E50'
                })
            }else{
                alertaConfirmar('Eliminar Contacto', 'Estas seguro que quieres eliminar este contacto?', 'question', (res_alert) => {
            
                    if (res_alert === true) {
                        p_listacontactos.map((value, index) => {
                            
                            if (value.Identificador === Identificador) {
                                UserDataModel.BorraContacto(user, value.Identificador, (res) => {
                                    if (res === true) {
                                        const newList = p_listacontactos.filter((__c) => __c.Identificador !== Identificador);
                                        p_borrarcontacto(newList)
                                       Swal.fire({
                                            text: "El contacto se eliminó correctamente",
                                            icon: "success",
                                            timer: 2500
                                        })
                                    }
                                    else {
                                        alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el contacto!', 'error', 'Aceptar')
                                    }
        
                                })
        
                            }
                        })
                    }
                    else {
                        alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar el contacto!', 'error', 'Aceptar')
                    }
                })
            }

        })
        
    }

    const handleOpenEditContactExit = (event) => {
        SetOpenContactEdit(false)
    }

    const handleSubmitEditContact = (e) => {
        const MyContacts = p_listacontactos
        e.preventDefault()

        if (NumeroCelular.length < 1) {

        }
        else if (NumeroCelular.length < 7 && NumeroCelular.length > 0) {
        }
        else {
            UserDataModel.ActualizarContacto(user, Identificador, NumeroCelular, NombreCompleto, (res) => {
                if (res === true) {
                    MyContacts.map((contact) => {
                        if (contact.Identificador === Identificador) {
                            contact.NumeroCelular = NumeroCelular,
                            contact.NombreCompleto = NombreCompleto
                        }
                        return contact
                    })

                    p_editarcontacto(MyContacts)

                    SetNumeroCelular("")
                    SetNombre("")
                    SetIdentificador("")
                    SetOpenContactEdit(false)
                    SetIndex("")
                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos', 'error', 'Aceptar')
                }
            })
        }
    }

    const HandleChangeNumeroCelular = (e) => {
        setCurrentMaxCharCelular(9 - e.target.value.length)
		if (e.target.value.length <= 9) {
			SetNumeroCelular(e.target.value)

		} else {
			setCurrentMaxCharCelular(0)
		}
    }
    const HandleChangeNombre = (e) => {
        setCurrentMaxCharNombre(35 - e.target.value.length)
		if (e.target.value.length <= 35) {
			SetNombre(e.target.value)

		} else {
			setCurrentMaxCharNombre(0)
		}
    }

    const handleclosemiscontactos = (e) => {
        props.handleclosemiscontactos(false)
    }

    const handleFilterContacts = p_listacontactos.filter(_c => {
        return _c.NombreCompleto.toLowerCase().includes(searchContact.toLowerCase())
    })
    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={handleclosemiscontactos} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis contactos <div className='contador-div'>{p_listacontactos.length}</div></div>
            </div>
            <div className='main-right-info-container'>
                <div className="search_contact">
                    <Grid container spacing={2}>
                      <MuiThemeProvider theme={theme}>
                            <TextField
                            type="text"
                            id="search_contact_contacts"
                            autoComplete="off"
                            variant='outlined'
                            required
                            fullWidth
                            placeholder="Buscar contacto"
                            value={searchContact}
                            onChange={(e) => SetSearchContact(e.target.value)}
                            autoFocus
                            InputLabelProps={{ style: { color: '#ADB7BA', background: 'none', paddingLeft: 10 } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            /*onClick={this.handleSubmit}*/
                                            style={
                                                {
                                                    cursor: "pointer"
                                                }}
                                            color="rgb(132, 139, 144)"
                                            size="24"
                                        />

                                    </InputAdornment>
                                ),
                                style: ({
                                    border: 'none',
                                    outline: 'none',
                                    width: '97%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    background: '#061d33',
                                    borderRadius: 0,
                                    height: 55,
                                    position : 'relative'
                                })
                            }}
                            inputProps={{
                                style: {
                                    background: 'transparent',
                                    color: '#ADB7BA',
                                    fontSize: 15
                                },
                                maxLength: 50
                            }}
                            color='primary'
                        />
                        </MuiThemeProvider>
                    </Grid>
                </div>
              { loading ? (
              <ListaContactos
              listaContactos={handleFilterContacts}
              handleOpenEditContact={handleOpenEditContact}
              handleRemoveContact={handleEliminarContacto}
              />
              ) :
                (
                    <div className="preloader" style={{ width: 75, height: 75, marginLeft: '41%', marginTop: 25 }}></div>
                )

            }
            </div>
        
            {OpenContactEdit && (
										
                                        <div className="modal">
                                        <div className="modal-contenido">
                                                <div className="title-pages-modal">
                                                    <div className="title_ico_pages_modal">
                                                        <IconButton color='primary' onClick={handleOpenEditContactExit} style={{background:'#051320', marginLeft: 15}}><IoCloseSharp color="#B4C0C8" size="24" /></IconButton>
                                                    </div>
                                                    <div className="div_title_pages_modal">Editar Contacto</div>
                                                </div>
                                                <form onSubmit={handleSubmitEditContact} className='form-modal'>
                                               

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Nombre Completo" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharNombre}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={NombreCompleto}
						onChange={HandleChangeNombre}
						onKeyPress={PermiteSoloLetras}
						/>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Telefono celular" variant="outlined" 
						InputProps={{endAdornment:<span className='max-length'>{CurrentMaxCharCelular}</span>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={NumeroCelular}
						onChange={HandleChangeNumeroCelular}
						onKeyPress={PermiteSoloNumeros}
						/>
                            
                            <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdUpdate size={28} />}>Actualizar</Button>
                                                </form>
                                                
                                            </div>
                                        </div>
                                                            )}
            
       </div>
    )
}


