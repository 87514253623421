import React, { useState, useEffect } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import Swal from 'sweetalert2'
import Grid from '@material-ui/core/Grid';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import { Button, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material';
import { MdEditOff, MdLock, MdSearch, MdUpdate } from 'react-icons/md';
import ListaMisUsuarios from './ListaMisUsuarios';
import { IoMdEye, IoMdEyeOff, IoMdLock } from 'react-icons/io';
import moment from 'moment';
import UserDataModel from '../../../models/UserDataModel';
import { AlertTitle, Autocomplete } from '@mui/material';
import { Alert } from '@mui/material';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function MisUsuarios(props) {
    const {
        esADMIN,
        user,
        socket,
        p_listausuarios,
        p_listacodigos,
        p_handleclosemisusuarios,
    } = props

    const [loading, SeLoading] = useState(false)
    const [searchUsuario, SetSearchUsuario] = useState("")

    const [AbrirUsuarioEditar, SetAbrirUsuarioEditar] = useState(false)

    const [s_IdUsuarioEditar, SetIdUsuarioEditar] = useState("")
    const [s_UsuarioEditar, SetUsuarioEditar] = useState("")
    const [s_PasswordUsuarioEditar, SetPasswordUsuarioEditar] = useState("")
    const [s_AutorityUsuarioEditar, SetAutorityUsuarioEditar] = useState("")
    const [s_BusinessNameUsuarioEditar, SetBusinessNameUsuarioEditar] = useState("")
    const [s_StateUsuarioEditar, SetStateUsuarioEditar] = useState("")
    const [s_ExpirationUsuarioEditar, SetExpirationUsuarioEditar] = useState("")
    const [s_CreatedUsuarioEditar, SetCreatedUsuarioEditar] = useState("")
    const [VerPasswordUsuario, SetVerPasswordUsuario] = useState(false)

    const [CurrentMaxCharBusinessUsuario, SetCurrentMaxCharBusinessUsuario] = useState(30)
    const [CurrentMaxCharPasswordUsuario, SetCurrentMaxCharPasswordUsuario] = useState(16)

    useEffect(async () => {
       await SeLoading(true)
    
    }, [])

    const alertaAceptar = (titulo, texto, icon, boton) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }
    
    const HandleAbrirEditarUsuario = (
            id,
            User,
            password,
            autority,
            businessname,
            state,
            expiration,
            created_at,) => {
            let FormatFechaExpiration = moment(new Date(expiration)).format("yyyy-MM-DDTHH:mm")
            let FormatFechaCreated = moment(new Date(created_at)).format("yyyy-MM-DDTHH:mm")
            if(autority === 10 & (user.autority === 5 || user.autority === 1)){
                Swal.fire({
                    text: "No tienes la autoridad suficiente para editar a un SUPER ADMIN",
                    icon: "warning",
                    timer: 5500,
                    confirmButtonColor : '#2C3E50',
                })
            }else if(autority === 5 & (user.autority === 5 || user.autority === 1)){
                Swal.fire({
                    text: "No tienes la autoridad suficiente para editar a un ADMINISTRADOR",
                    icon: "warning",
                    timer: 5500,
                    confirmButtonColor : '#2C3E50',
                })

            }else{
                event.preventDefault();
                SetAbrirUsuarioEditar(true)
                SetIdUsuarioEditar(id)
                SetUsuarioEditar(User)
                SetPasswordUsuarioEditar(password)
                SetAutorityUsuarioEditar(autority)
                SetBusinessNameUsuarioEditar(businessname)
                SetStateUsuarioEditar(state)
                SetExpirationUsuarioEditar(FormatFechaExpiration)
                SetCreatedUsuarioEditar(FormatFechaCreated)
                SetCurrentMaxCharBusinessUsuario(30 - businessname.length)
                SetCurrentMaxCharPasswordUsuario(16 - password.length)
            }
            
        }
    
    const HandleSubmitEditarUsuario = (e) => {
            e.preventDefault()
    
            var FormatHoy = Date.now()
            var FormatFechaFin = new Date(s_ExpirationUsuarioEditar).getTime()
    
            if (s_PasswordUsuarioEditar.length < 1 || 
                s_AutorityUsuarioEditar.length < 1 || 
                s_BusinessNameUsuarioEditar.length < 1 ||
                s_ExpirationUsuarioEditar.length < 1
                ) {
                Swal.fire({
                    text: "Uno o más de los campos requeridos están vacios!",
                    icon: "warning",
                    timer: 5500,
                    confirmButtonColor : '#2C3E50',
                })
            }else if(FormatFechaFin < FormatHoy){
                Swal.fire({
                    text: "La fecha de fin no puede ser menor a hoy!",
                    icon: "warning",
                    timer: 5500,
                    confirmButtonColor : '#2C3E50',
                })
            }else if(s_PasswordUsuarioEditar.length < 8){
                Swal.fire({
                    text: "La contraseña debe tener como mínimo 8 caracteres.",
                    icon: "warning",
                    timer: 5500,
                    confirmButtonColor : '#2C3E50',
                })
            }else if(!socket.connected){
                Swal.fire({
                    html: "<font color='#F8C471'>Ups! ah ocurrido un problema con el servidor, al parecer el servidor está fuera de línea en estos momentos o intenta iniciar sesión nuevamente.</font>",
                    icon: "warning",
                    timer: 13000,
                    confirmButtonColor : '#2C3E50',
                })
            }
            else {
                UserDataModel.ActualizarUsuario(s_IdUsuarioEditar,
                    s_PasswordUsuarioEditar,
                    s_AutorityUsuarioEditar,
                    s_BusinessNameUsuarioEditar,
                    s_ExpirationUsuarioEditar, (res) => {
                    if (res === true) {
                        const Data = {
                                id : s_IdUsuarioEditar,
                                password : s_PasswordUsuarioEditar,
                                autority : s_AutorityUsuarioEditar,
                                businessName : s_BusinessNameUsuarioEditar,
                                expiration : FormatFechaFin
                            }

                        const UsuarioEditado = p_listausuarios.map((usuario) => {
                                if (usuario.u_id === s_IdUsuarioEditar) {
                                    usuario.u_password = s_PasswordUsuarioEditar
                                    usuario.u_autority = s_AutorityUsuarioEditar
                                    usuario.u_businessName = s_BusinessNameUsuarioEditar
                                    usuario.u_expiration = FormatFechaFin
                                }
                                return usuario
                        })
    
                        socket.emit('EDITARUSUARIO', Data, p_listacodigos, UsuarioEditado)
                        SetIdUsuarioEditar('')
                        SetUsuarioEditar('')
                        SetPasswordUsuarioEditar('')
                        SetAutorityUsuarioEditar('')
                        SetBusinessNameUsuarioEditar('')
                        SetStateUsuarioEditar('')
                        SetExpirationUsuarioEditar('')
                        SetCreatedUsuarioEditar('')
                        SetVerPasswordUsuario(false)
                        SetAbrirUsuarioEditar(false)
                        alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
                    }
                    else {
                        alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos', 'error', 'Aceptar')
                    }
                })
            }
    }


    const HandleBloquearDesbloquearUsuario = (IdUsuario, Usuario, tipo, autority) => {
        var TipoAccion = null
        var TextAccion = ""
        var effect = ""
        if(tipo ===  'Bloquear'){
            TipoAccion = 0
            TextAccion = 'Bloqueado'
            effect = 'error'
        }else if(tipo ===  'Desbloquear'){
            TipoAccion = 1
            TextAccion = 'Desbloqueado'
            effect = 'success'
        }
        alertaConfirmar(tipo + ' Usuario', 'Estas seguro que quieres ' + tipo + ' a ' + Usuario + ' ?', 'question', (res_alert) => {
            if(res_alert === true){
                if(user.user === Usuario){
                    Swal.fire({
                        html: "No puedes "+ tipo +"te tu mismo",
                        icon: "warning",
                        timer: 13000,
                        confirmButtonColor : '#2C3E50',
                    })
                }else if(autority === 10){
                    Swal.fire({
                        html: "No tienes la autoridad suficiente para " + tipo + " a un SUPER ADMIN",
                        icon: "warning",
                        timer: 13000,
                        confirmButtonColor : '#2C3E50',
                    })
                }else if(autority === 5 & user.autority != 10){
                    Swal.fire({
                        html: "No tienes la autoridad suficiente para " + tipo + " a un ADMINISTRADOR",
                        icon: "warning",
                        timer: 13000,
                        confirmButtonColor : '#2C3E50',
                    })
                }else if(!socket.connected){
                    Swal.fire({
                        html: "<font color='#F8C471'>Ups! ah ocurrido un problema con el servidor, al parecer el servidor está fuera de línea en estos momentos o intenta iniciar sesión nuevamente.</font>",
                        icon: "warning",
                        timer: 13000,
                        confirmButtonColor : '#2C3E50',
                    })
                }else{
                    UserDataModel.BloquearDesbloquearUsuario(IdUsuario, TipoAccion, (res) => {
                        if (res === true) {
                            const NuevoEstado = {
                                remitente : user.user,
                                id : IdUsuario,
                                usuario: Usuario,
                                status : TipoAccion,
                                tipo : tipo,
                                textaccion : TextAccion,
                                effect : effect
                            }
                            socket.emit('BLOQUEARDESBLOQUEARUSUARIO', NuevoEstado)
                            alertaAceptar(textaccion, 'El usuario ' + usuario + ' se ah ' + textaccion + ' con éxito!', effect, 'Aceptar')
                        }
                        else {
                            alertaAceptar(textaccion, 'Hubo un error al actualizar los datos', 'error', 'Aceptar')
                        }
                    })
                }
            }
        })
    }

    const HandleCerrarEditarUsuario = (event) => {
            SetVerPasswordUsuario(false)
            SetAbrirUsuarioEditar(false)
        }

    const HandleVerPasswordUsuario = (e)=>{
        SetVerPasswordUsuario(!VerPasswordUsuario)
      }

    const HandleChangePasswordUsuarioEditar = (e) =>{
        SetCurrentMaxCharPasswordUsuario(16 - e.target.value.length)
		if (e.target.value.length <= 16) {
			SetPasswordUsuarioEditar(e.target.value)

		} else {
			SetCurrentMaxCharPasswordUsuario(0)
		}
    }

    const HandleChangeAutorityUsuarioEditar = (e) =>{
        SetAutorityUsuarioEditar(Number(e.target.value))
    }

    const HandleChangeBusinessNameUsuarioEditar = (e) =>{
        SetCurrentMaxCharBusinessUsuario(30 - e.target.value.length)
		if (e.target.value.length <= 30) {
			SetBusinessNameUsuarioEditar(e.target.value)

		} else {
			SetCurrentMaxCharBusinessUsuario(0)
		}
    }

    const HandleChangeExpirationUsuarioEditar = (e) =>{
        SetExpirationUsuarioEditar(e.target.value)
    }

    const handlefilterusuarios = p_listausuarios.filter(_c => {
        return _c.u_user.toLowerCase().includes(searchUsuario.toLowerCase())
    })
    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={p_handleclosemisusuarios} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis usuarios <div className='contador-div'>{p_listausuarios.length}</div></div>
            </div>
            { esADMIN ? 
            <div className='main-right-info-container'>
                <div className="search_contact">
                <Grid container spacing={2}>
                    <MuiThemeProvider theme={theme}>
                        <TextField
                            type="text"
                            id="search_contact_contacts"
                            autoComplete="off"
                            variant='outlined'
                            required
                            fullWidth
                            placeholder="Buscar contacto"
                            value={searchUsuario}
                            onChange={(e) => SetSearchUsuario(e.target.value)}
                            autoFocus
                            InputLabelProps={{ style: { color: '#ADB7BA', background: 'none', paddingLeft: 10 } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            /*onClick={this.handleSubmit}*/
                                            style={
                                                {
                                                    cursor: "pointer"
                                                }}
                                            color="rgb(132, 139, 144)"
                                            size="24"
                                        />

                                    </InputAdornment>
                                ),
                                style: ({
                                    border: 'none',
                                    outline: 'none',
                                    width: '97%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    background: '#061d33',
                                    borderRadius: 0,
                                    height: 55,
                                    position : 'relative'
                                })
                            }}
                            inputProps={{
                                style: {
                                    background: 'transparent',
                                    color: '#ADB7BA',
                                    fontSize: 15
                                },
                                maxLength: 50
                            }}
                            color='primary'
                        />
                    </MuiThemeProvider>
                </Grid>
              </div>
              { loading ? (
              <ListaMisUsuarios
              User={user}
              listausuarios={handlefilterusuarios}
              p_HandleAbrirEditarUsuario={HandleAbrirEditarUsuario}
              p_HandleBloquearDesbloquearUsuario={HandleBloquearDesbloquearUsuario}
              />
              ) :
                (
                    <div className="preloader" style={{ width: 75, height: 75, marginLeft: '41%', marginTop: 25 }}></div>
                )

            }
            </div>:<div className='sin-autoridad'>
				<Alert className='effect' 
				style={{marginBottom: 10}} 
				severity='warning'>
				<AlertTitle style={{fontWeight: 'bolder', fontSize: 18}}><b>Contenido no autorizado!</b></AlertTitle>
				<b>Alerta del sistema : </b>No tienes autoridad suficiente para poder ver este contenido!
				</Alert>
					</div>
            }

            {AbrirUsuarioEditar && (
										
                                        <div className="modal">
                                        <div className="modal-contenido">
                                                <div className="title-pages-modal">
                                                    <div className="title_ico_pages_modal">
                                                        <IconButton color='primary' onClick={HandleCerrarEditarUsuario} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                                                    </div>
                                                    <div className="div_title_pages_modal">Editar usuario</div>
                                                </div>
                { esADMIN ?
                <form onSubmit={HandleSubmitEditarUsuario} className='form-modal'>
		        <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Usuario no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Usuario" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
                        value={s_UsuarioEditar}
						/>
                </Tooltip>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Contraseña" variant="outlined" 
						autoComplete="off"
						color='primary'
                        InputProps={{endAdornment:<><span className='max-length'>{CurrentMaxCharPasswordUsuario}</span><IconButton onClick={HandleVerPasswordUsuario}>
                            {VerPasswordUsuario ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
                        </IconButton></>}}
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
                        value={s_PasswordUsuarioEditar}
                        type={VerPasswordUsuario ? 'text':'password'}
                        onChange={HandleChangePasswordUsuarioEditar}
						/>
                
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Autoridad" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
                        value={s_AutorityUsuarioEditar}
                        onChange={HandleChangeAutorityUsuarioEditar}
                        select
                        SelectProps={{style:{color:'#c2c2c2', background: '#10212D',
						fontFamily:"Maven Pro, sans-serif", fontSize:17}, readOnly:s_AutorityUsuarioEditar === 10 ? true:false, 
                        endAdornment: s_AutorityUsuarioEditar === 10 && <IoMdLock color='#E74C3C' size={28}/>}}
						>
                            {user.autority === 10 && <MenuItem disabled selected={s_AutorityUsuarioEditar == 10 && true} value={'10'}>SUPER ADMIN</MenuItem>}
                            {user.autority === 10 && <MenuItem selected={s_AutorityUsuarioEditar == 5 && true} value={'5'}>ADMINISTRADOR</MenuItem>}
                                <MenuItem selected={s_AutorityUsuarioEditar == 1 && true} value={'1'}>REGISTRADO</MenuItem>
                            
                        
                </TextField>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Nombre del negocio" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
                        InputProps={{endAdornment:
							<><span className='max-length'>{CurrentMaxCharBusinessUsuario}</span></>
                        }}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
                        value={s_BusinessNameUsuarioEditar}
                        onChange={HandleChangeBusinessNameUsuarioEditar}
						/>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Fecha de inicio no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
                        marginTop:10, marginBottom: 10, color:'#c2c2c2',
                        borderRadius:5, background: '#10212D'
                       }} 
                       sid="outlined-basic" label="Fecha de inicio" variant="outlined" 
                       autoComplete="off"
                       color='primary'
                       InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
                       inputProps={{style:{color:'#c2c2c2',
                       fontFamily:"Maven Pro, sans-serif",
                       fontSize:16
                       }, readOnly:true}}
                       InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
                       fontFamily:"Maven Pro, sans-serif"},shrink: true}}
                       type='datetime-local'
                       value={s_CreatedUsuarioEditar}
						/>
                </Tooltip>
                <TextField 
						style={{width:'100%',
                        marginTop:10, marginBottom: 10, color:'#c2c2c2',
                        borderRadius:5, background: '#10212D'
                       }} 
                       sid="outlined-basic" label="Fecha de expiracion" variant="outlined" 
                       autoComplete="off"
                       color='primary'
                       inputProps={{style:{color:'#c2c2c2',
                       fontFamily:"Maven Pro, sans-serif",
                       fontSize:16
                       }}}
                       InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
                       fontFamily:"Maven Pro, sans-serif"},shrink: true}}
                       type='datetime-local'
                       value={s_ExpirationUsuarioEditar}
                       onChange={HandleChangeExpirationUsuarioEditar}
						/>

               <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdUpdate size={28} />}>Actualizar</Button>
				 
				</form>:<div className='sin-autoridad'>
				<Alert className='effect' 
				style={{marginBottom: 10}} 
				severity='warning'>
				<AlertTitle style={{fontWeight: 'bolder', fontSize: 18}}><b>Contenido no autorizado!</b></AlertTitle>
				<b>Alerta del sistema : </b>No tienes autoridad suficiente para poder ver este contenido!
				</Alert>
					</div>
                }
                                                
                                            </div>
                                        </div>
                                                            )}
            
       </div>
    )
}


