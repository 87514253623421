import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoCloseSharp } from 'react-icons/io5';
import moment from 'moment'
import 'moment-timezone'
import UserDataModel from '../../../models/UserDataModel';

import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";
import { RiUserAddFill } from 'react-icons/ri';
import ListaMisCuentas from './ListaMisCuentas';
import { Grid, InputAdornment, TextField, Button, IconButton, Tooltip } from '@mui/material';
import { MdEditOff, MdLock, MdSave, MdSearch, MdUpdate } from 'react-icons/md';
import Swal from 'sweetalert2';
import { IoMdEye, IoMdEyeOff, IoMdLock } from 'react-icons/io';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0084a5"
        },
        secondary: {
            main: "#FFF"
        }
    }
});

export default function MisCuentas(props) {
    const { 
        user, 
        p_handleclosemiscuentas,
        p_listacuentas,
        p_borrarcuenta,
        p_EditarCuenta,
        p_usuarioinfo,
        s_detallesalertasistema,
        s_vaciardetallesalertasistema,
        p_listaPlataformas

    } = props
    const [] = useState(false)
    const [loading, SeLoading] = useState(false)
    const [searchCuenta, SetSearchCuenta] = useState("")
    const [AbrirCuentasEditar, SetAbrirCuentasEditar] = useState(false)
    const [CurrentCuentasSelectedCodigo, SetCurrentCuentasSelectedCodigo] = useState("")
    const [CurrentCuentasSelectedNombre, SetCurrentCuentasSelectedNombre] = useState("")
    const [VerPasswordCorreo, SetVerPasswordCorreo] = useState(false)
    const [VerPasswordCuenta, SetVerPasswordCuenta] = useState(false)

    /**** NEW DATES */
    const [s_CorreoCuentaEditar, SetCorreoCuentaEditar] = useState("")
	const [s_PasswordCorreoEditar, SetPasswordCorreoEditar] = useState("")
	const [s_PasswordCuentaEditar, SetPasswordCuentaEditar] = useState("")
	const [s_TipoCuentaNombreEditar, SetTipoCuentaNombreEditar] = useState("")
	const [s_FechaInicioCuentaEditar, SetFechaInicioCuentaEditar] = useState("")
	const [s_FechaFinCuentaEditar, SetFechaFinCuentaEditar] = useState("")
    const [s_EstadoUsoEditar, SetEstadoUsoEditar] = useState("")
    const [s_IdentificadorEditar, SetIdentificadorEditar] = useState("")
    const [index, SetIndex] = useState("")



    useEffect(async () => {
        await SeLoading(true)
        if(s_detallesalertasistema.TipoCuentaCodigo != "" &&
        s_detallesalertasistema.Correo != ""){
            SetCurrentCuentasSelectedCodigo(s_detallesalertasistema.TipoCuentaCodigo)
            SetSearchCuenta(s_detallesalertasistema.Correo)
         }
     }, [])

    

    const alertaAceptar = (titulo, texto, icon, boton) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            timer: 8000,
            confirmButtonColor : '#2C3E50',
        })
    }
     
    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor : '#2C3E50',
            cancelButtonColor : '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
              callback(result.isConfirmed)
            }
          })
        }

    const HandleEliminarCuenta = (id, Identificador, EstadoUso) => {
        event.preventDefault();
        if(EstadoUso > 0){
            var PerfilOperfiles = ""
            if(EstadoUso > 1){
                PerfilOperfiles = "perfiles en uso"
            }else{
                PerfilOperfiles = "perfil en uso"
            }
            Swal.fire({
                text: "Esta cuenta no se puede eliminar porque actualmente tiene " + EstadoUso + " " + PerfilOperfiles + ".",
                icon: "warning",
                timer: 8000,
                confirmButtonColor : '#2C3E50',
            })
        }else{
            alertaConfirmar('Eliminar Cuenta', 'Estas seguro que quieres eliminar esta cuenta?', 'question', (res_alert) => {
                if (res_alert === true) {
                    p_listacuentas.map((value, index) => {
                        if (index === id) {
                            UserDataModel.BorraCuenta(user, Identificador, (res) => {
                                if (res === true) {
                                    const NuevaListaCuentas = p_listacuentas.filter((__c) => __c.Identificador !== Identificador);
                                    p_borrarcuenta(NuevaListaCuentas)
                                    Swal.fire({
                                        text: "La cuenta se eliminó correctamente",
                                        icon: "success",
                                        timer: 2500,
                                        confirmButtonColor : '#2C3E50',
                                    })
                                   
                                }
                                else {
                                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar la cuenta!', 'error', 'Aceptar')
                                }
    
                            })
    
                        }
                    })
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al eliminar la cuenta!', 'error', 'Aceptar')
                }
            })
        }
        
    }

    const HandleCerrarEditarCuenta = (event) => {
        SetAbrirCuentasEditar(false)
        SetVerPasswordCorreo(false)
        SetVerPasswordCuenta(false)
    }

    const HandleSubmitEditarCuenta = (e) => {
        const MisCuentas = p_listacuentas
        e.preventDefault()

        var FormatHoy = Date.now()
        var FormatFechaInicio = new Date(s_FechaInicioCuentaEditar).getTime()
		var FormatFechaFin = new Date(s_FechaFinCuentaEditar).getTime()

        if (s_PasswordCorreoEditar.length < 1 || s_PasswordCuentaEditar.length < 1) {
            Swal.fire({
                text: "Uno o más de los campos requeridos están vacios!",
                icon: "warning",
                timer: 5500,
                confirmButtonColor : '#2C3E50',
            })
        }else if(FormatFechaFin < FormatHoy){
			Swal.fire({
				text: "La fecha de expiración no puede ser menor a hoy!",
				icon: "warning",
				timer: 5500,
                confirmButtonColor : '#2C3E50',
			})
		}
        else {
            UserDataModel.ActualizarCuenta(user, 
                s_IdentificadorEditar,
                s_FechaInicioCuentaEditar,
                s_FechaFinCuentaEditar,
                s_PasswordCorreoEditar,
                s_PasswordCuentaEditar, (res) => {
                if (res === true) {
                    MisCuentas.map((cuenta) => {
                        if(cuenta.Identificador === s_IdentificadorEditar) {
                            cuenta.Identificador = s_IdentificadorEditar
                            cuenta.PasswordCorreo = s_PasswordCorreoEditar
                            cuenta.PasswordCuenta = s_PasswordCuentaEditar
                            cuenta.FechaInicioCuenta = FormatFechaInicio
                            cuenta.FechaFinCuenta = FormatFechaFin
                        }
                        return cuenta
                    })

                    p_EditarCuenta(MisCuentas)
                    
                    SetCorreoCuentaEditar("")
                    SetPasswordCorreoEditar("")
                    SetPasswordCuentaEditar("")
                    SetTipoCuentaNombreEditar("")
                    SetFechaInicioCuentaEditar("")
                    SetFechaFinCuentaEditar("")
                    SetEstadoUsoEditar("")
                    SetIdentificadorEditar("")
                    SetIndex("")
                    SetAbrirCuentasEditar(false)
                    alertaAceptar('Actualizado Correcto', 'Los datos se actualizaron correctamente!', 'success', 'Aceptar')
                }
                else {
                    alertaAceptar('Alerta de CastleAPP', 'Hubo un error al actualizar los datos', 'error', 'Aceptar')
                }
            })
        }
    }


    const HandleEditarCuenta = (index, 
        Identificador, 
        CorreoCuenta,
        FechaInicioCuenta,
        FechaFinCuenta,
        TipoCuentaNombre,
        PasswordCorreo,
        PasswordCuenta,
        EstadoUso,
        created_at,) => {
        let FormatFechaInicio = moment(new Date(FechaInicioCuenta)).format("yyyy-MM-DDTHH:mm")
        let FormatFechaFin = moment(new Date(FechaFinCuenta)).format("yyyy-MM-DDTHH:mm")

        event.preventDefault();
        SetAbrirCuentasEditar(true)
        SetIndex(index)
        SetCorreoCuentaEditar(CorreoCuenta)
        SetPasswordCorreoEditar(PasswordCorreo)
        SetPasswordCuentaEditar(PasswordCuenta)
        SetTipoCuentaNombreEditar(TipoCuentaNombre)
        SetFechaInicioCuentaEditar(FormatFechaInicio)
        SetFechaFinCuentaEditar(FormatFechaFin)
        SetEstadoUsoEditar(EstadoUso)
        SetIdentificadorEditar(Identificador)
    }

    const handleclosemiscuentas = (e) =>{
        if(s_detallesalertasistema.TipoCuentaCodigo != "" && 
         s_detallesalertasistema.Correo != ""
         ){
            s_vaciardetallesalertasistema()
        }
        p_handleclosemiscuentas(false)
    }

    /**Handles Editar Inicio*/

const HandleChangePasswordCorreoEditar = (e) => {
    SetPasswordCorreoEditar(e.target.value)
}

const HandleChangePasswordCuentaEditar = (e) => {
    SetPasswordCuentaEditar(e.target.value)
}


const HandleChangeFechaInicioCuentaEditar = (e) => {
    SetFechaInicioCuentaEditar(e.target.value)
}

const HandleChangeFechaFinCuentaEditar = (e) => {
    SetFechaFinCuentaEditar(e.target.value)
}
    /**Handles Editar Cierre */ 

    /**Handles Listar abre*/

    const HandleChangeListarCuentas = (data, data1) => {
        SetCurrentCuentasSelectedCodigo(data)
        SetCurrentCuentasSelectedNombre(data1)
        SetSearchCuenta("")
    }
    
    /**Handles listar cierre */
    
    const handleFilterCuentas = p_listacuentas.filter(_c => {
        if(CurrentCuentasSelectedCodigo.length > 0)
            {
                return _c.TipoCuentaCodigo === CurrentCuentasSelectedCodigo && _c.CorreoCuenta.toLowerCase().includes(searchCuenta.toLowerCase())
            }else if(CurrentCuentasSelectedCodigo === ""){
                return _c.TipoCuentaCodigo && _c.CorreoCuenta.toLowerCase().includes(searchCuenta.toLowerCase())
            }
        })

    const HandleVerPasswordCorreo = (e)=>{
          SetVerPasswordCorreo(!VerPasswordCorreo)
    }

    const HandleVerPasswordCuenta = (e)=>{
        SetVerPasswordCuenta(!VerPasswordCuenta)
  }

  var image = new Image();

  image.onload = function () {
          // image exists and is loaded
  document.body.appendChild(image);
  }
  image.onerror = function () {
   // image did not load
  var err = new Image();
  err.src = '/icons/unknow.jpg';
  document.body.appendChild(err);
  }
           
    return (

       <div className='main-right-container'>
           <div className="title-pages">
                <div className="title_ico_pages">
                    <IconButton color='primary' onClick={handleclosemiscuentas} style={{background:'#051320', marginLeft: 15}}><IoArrowBack color="#B4C0C8" size="24" /></IconButton>
                </div>
                <div className="div_title_pages">Mis cuentas <div className='contador-div'>{p_listacuentas.length}</div></div>
            </div>
            
            <div className='main-right-info-container-cuentas'>
            <div className='cuentas-container-div'>
              <div className='cuentas-sub-container-div'>
                {
                     p_listaPlataformas.map((___p)=>{
                        
                        return <div onClick={()=> HandleChangeListarCuentas(___p.Identificador, ___p.NombrePlataforma)}
                        className={CurrentCuentasSelectedCodigo == ___p.Identificador ? 'cuentas-container-div-item-hover':'cuentas-container-div-item'} 
                        key={___p.Identificador}>
                            {___p.NombrePlataforma/*.replace(/[A-Za-z]+/g, function(match){ return (match.trim()[0]);})*/}
                        </div>
                     })
                }
                </div>
            </div>

              <div className="search_cuentas_perfiles">
                <Grid container spacing={2}>
                    <MuiThemeProvider theme={theme}>
                        <TextField
                            type="text"
                            id="search_contact_contacts"
                            autoComplete="off"
                            variant='outlined'
                            required
                            fullWidth
                            placeholder={"Buscar Cuentas " + CurrentCuentasSelectedNombre}
                            value={searchCuenta}
                            onChange={(e) => SetSearchCuenta(e.target.value)}
                            autoFocus
                            InputLabelProps={{ style: { color: '#ADB7BA', background: 'none', paddingLeft: 10 } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            /*onClick={this.handleSubmit}*/
                                            style={
                                                {
                                                    cursor: "pointer"
                                                }}
                                            color="rgb(132, 139, 144)"
                                            size="24"
                                        />

                                    </InputAdornment>
                                ),
                                style: ({
                                    border: 'none',
                                    outline: 'none',
                                    width: '98.5%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    background: '#061d33',
                                    borderRadius: 0,
                                    height: 55,
                                    position : 'relative'
                                })
                            }}
                            inputProps={{
                                style: {
                                    background: 'transparent',
                                    color: '#ADB7BA',
                                    fontSize: 15
                                },
                                maxLength: 50
                            }}
                            color='primary'
                        />
                    </MuiThemeProvider>
                </Grid>
              </div>
              
              { loading ? (
              <ListaMisCuentas
              p_ListaCuentas={handleFilterCuentas}
              p_HandleEliminarCuenta={HandleEliminarCuenta}
              p_HandleEditarCuenta={HandleEditarCuenta}
              p_usuarioinfo={p_usuarioinfo}
              p_listaPlataformas={p_listaPlataformas}
              />
              ) :
                (
                    <div className="preloader" style={{ width: 75, height: 75, marginLeft: '41%', marginTop: 25 }}></div>
                )

            }
            </div>

            {AbrirCuentasEditar && (
										
                                        <div className="modal">
                                        <div className="modal-contenido">
                                                <div className="title-pages-modal">
                                                    <div className="title_ico_pages_modal">
                                                        <IconButton color='primary' onClick={HandleCerrarEditarCuenta} 
                                                        style={{background:'#051320', marginLeft: 15}}>
                                                            <IoCloseSharp color="#B4C0C8" size="24" />
                                                        </IconButton>
                                                    </div>
                                                    <div className="div_title_pages_modal">Editar Cuenta</div>
                                                </div>
                                                <form onSubmit={HandleSubmitEditarCuenta} className='form-modal'>
                
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Correo no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>                                
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Correo" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_CorreoCuentaEditar}
						/>
                </Tooltip>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Contraseña del correo" variant="outlined" 
						InputProps={{endAdornment:<IconButton color='primary' onClick={HandleVerPasswordCorreo}>
                            {VerPasswordCorreo ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
                        </IconButton>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_PasswordCorreoEditar}
                        onChange={HandleChangePasswordCorreoEditar}
                        type={VerPasswordCorreo ? 'text':'password'}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Contraseña de la cuenta" variant="outlined" 
						InputProps={{endAdornment:<IconButton color='primary' onClick={HandleVerPasswordCuenta}>
                            {VerPasswordCuenta ? <IoMdEyeOff color='#c2c2c2'/>:<IoMdEye color='#c2c2c2'/>}
                        </IconButton>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_PasswordCuentaEditar}
                        onChange={HandleChangePasswordCuentaEditar}
                        type={VerPasswordCuenta ? 'text':'password'}
						/>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Tipo no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Tipo" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_TipoCuentaNombreEditar}
						/>
                </Tooltip>
                <Tooltip title={
				<div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
					<MdEditOff size={18} style={{marginRight: 5}}/> 
					Estado de uso no se puede editar
					</div>}
				
				PopperProps={{
					sx: {
					  "& .MuiTooltip-tooltip": {
						fontSize : 12.5,
					  }
					}
				  }}
				arrow>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Estado de uso" variant="outlined" 
						InputProps={{endAdornment:<IoMdLock color='#E74C3C' size={28}/>}}
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }, readOnly:true}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"}}}
						value={s_EstadoUsoEditar + ' perfiles en uso'}
						/>
                </Tooltip>
                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de inicio" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaInicioCuentaEditar}
						onChange={HandleChangeFechaInicioCuentaEditar}
						/>

                <TextField 
						style={{width:'100%',
						 marginTop:10, marginBottom: 10, color:'#c2c2c2',
						 borderRadius:5, background: '#10212D'
						}} 
						sid="outlined-basic" label="Fecha de expiración" variant="outlined" 
						autoComplete="off"
						color='primary'
						inputProps={{style:{color:'#c2c2c2',
						fontFamily:"Maven Pro, sans-serif",
						fontSize:16
					    }}}
						InputLabelProps={{style:{color:'#c2c2c2', fontSize:17, paddingBottom: 5,
						fontFamily:"Maven Pro, sans-serif"},shrink: true}}
						type='datetime-local'
						value={s_FechaFinCuentaEditar}
						onChange={HandleChangeFechaFinCuentaEditar}
						/>
                        
                        <Button type="submit" fullWidth style={{ height:55, marginLeft: 'auto', marginRight: 'auto'}} size='large' variant="contained" color="success" startIcon={<MdUpdate size={28} />}>Actualizar</Button>
                                             
                                                </form>
                                                
                                            </div>
                                        </div>
                                                            )}
            
       </div>
    )
}


