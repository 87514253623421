import { IconButton } from '@mui/material';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { FaCartPlus, FaHive, FaShopify } from 'react-icons/fa';
import { IoMdContact } from 'react-icons/io';

import { MdAccountBalanceWallet, MdAccountCircle, MdDelete, MdDevices, MdEmail, MdInfo, MdModeEdit, MdPhoneAndroid, MdSupervisorAccount, MdTimer, MdWatchLater } from 'react-icons/md';
import { CalcularExpiracion, setUnixToTimeFormat } from '../../../Funciones';


export default function ListaMisPerfiles(props) {
    const { 
        p_listaperfiles,
        p_listacontactos,
        p_HandleEliminarPerfil,
        p_HandleEditarPerfil
    } = props
    return (
        <div className="container_info">
{p_listaperfiles.length > 0 ? (
            <div className="group_items">
                    {
                        p_listaperfiles.sort((a,b) => a.FechaFinPerfil - b.FechaFinPerfil)
                        .map((perfil, index) => {
                            var NombreContacto = ""
                            p_listacontactos.find((_c) => {
                                if(_c.Identificador === perfil.IdContacto){
                                    NombreContacto = _c.NombreCompleto
                                   
                                }
                            })
                            var Identificador = perfil.Identificador
                            var IdContacto = perfil.IdContacto
                            var CorreoPerfil = perfil.CorreoPerfil
                            var TipoCuentaCodigo = perfil.TipoCuentaCodigo
                            var CantidadPerfiles = perfil.CantidadPerfiles
                            var FechaInicioPerfil = perfil.FechaInicioPerfil
                            var FechaFinPerfil = perfil.FechaFinPerfil
                            var Descripcion = perfil.DescripcionPerfil
                            var CostoPerfil = perfil.CostoPerfil
                            var created_at = perfil.created_at
                            var Tipo = perfil.Tipo
                            
                            return (
                                <div
                                    key={created_at}
                                    className="item_container"
                                >
                                <div className="item-block-cuentas">
                                    <div className='icon_left'>
                                            <MdDevices size={45}/>
                                    </div>

                                    <div className='item-left-cuentas'>
                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-correo">{NombreContacto}</div>
                                            <div className="item_list-cuentas-tipo">{CorreoPerfil}</div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className={
                                                CalcularExpiracion(FechaFinPerfil).substring(0, 2).trim().length === 1 & 
                                                Number(CalcularExpiracion(FechaFinPerfil).substring(0, 1)) >= 0 & 
                                                Number(CalcularExpiracion(FechaFinPerfil).substring(0, 1)) < 3 ?
                                                'item_list-cuentas-proximo':
                                                CalcularExpiracion(FechaFinPerfil) === 'Caducado' ?
                                                 "item_list-cuentas-fechaexp-caducado":
                                                 "item_list-cuentas-fechaexp"
                                                 }>
                                                <div className='icon-adorno'>
                                                <div><MdTimer size="24" /></div>
                                                <div>{CalcularExpiracion(FechaFinPerfil)}</div>
                                                </div>
                                                
                                                </div>
                                        </div>

                                        <div className="item_list_cuentas_2">
                                            <div className="item_list-cuentas-created">{setUnixToTimeFormat('info_contacto', created_at)}</div>
                                            <div className="item_list-cuentas-estadouso">
                                            {CantidadPerfiles === 1 && <div className='cuenta-usado'>Usa {CantidadPerfiles + ' perfil'} <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                            {CantidadPerfiles > 1 && <div className='cuenta-usado'>Usa {CantidadPerfiles + ' perfiles'} <MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                            
                                            {Tipo === 'combo' && <div className='tipo-perfil-combo'>Combo<FaShopify style={{marginLeft: 4}} size={20} /></div>}
                                            {Tipo === 'normal' && <div className='tipo-perfil-normal'>Normal<MdPhoneAndroid style={{marginLeft: 4}} size={20}/></div>}
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className='icon_right'>
                                        <IconButton onClick={() => p_HandleEditarPerfil(
                                                    index,
                                                    Identificador,
                                                    IdContacto,
                                                    TipoCuentaCodigo,
                                                    CorreoPerfil,
                                                    CantidadPerfiles,
                                                    Tipo,
                                                    Descripcion,
                                                    CostoPerfil,
                                                    FechaInicioPerfil,
                                                    FechaFinPerfil
                                                    )} color='primary' data-title='Editar'>
                                            <MdModeEdit
                                                color="#FFD75B"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Editar'
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => p_HandleEliminarPerfil(index, Identificador)} color='primary' data-title='Eliminar'>
                                            <MdDelete
                                                color="#C64141"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Eliminar'
                                            />
                                        </IconButton>
                                    </div>
                                </div>

                                </div>

                            )
                        })
                    }
            </div>
):(<div className='sin_datos'><div className='sin_datos_left'><MdInfo color='#F1C40F' size={64}/></div><div className='sin_datos_right'> No se ah encontrado ningún dato registrado!</div></div>)
}
        </div>
    )
}