import { firebase } from '../database/firebase'
import { setUnixToTimeFormat } from '../Funciones';
const db = firebase.firestore()
// add, get, update, del
export default class UserDataModel {
  static NuevoUsuario = async (TiempoExpiracion, usernew, password, userid, socketid, callback) => {
    const userExistRef = db.collection('userdata').doc(usernew);
    const doc = await userExistRef.get();
    /** Autoridad -> 1 = Registrado, 5 = Administrador */
    /** Estado -> 1 = Activo, 0 = Bloqueado */

    if(!doc.exists) {
      const data = {
        u_user: usernew,
        u_created_at: Date.now(),
        u_last_connection: Date.now(),
        u_MaxPerfilesNetflix : 5,
        u_MaxPerfilesMovPlay : 1,
        u_MaxPerfilesDisney : 5,
        u_MaxPerfilesHbo : 5,
        u_MaxPerfilesPrimeVideo : 5,
        u_MaxPerfilesParamount : 5,
        u_MaxPerfilesYoutubepremium : 1,
        u_MaxPerfilesPornHubPremium : 1,
        u_MaxPerfilesCrunchyroll : 3,
      };

      const AddUsers = {
        u_id: userid,
        u_user: usernew,
        u_socketid: socketid,
        u_businessName: 'Mi Negocio',
        u_autority : 1,
        u_state: 1,
        u_password: password,
        u_expiration : TiempoExpiracion,
        u_image: 'unknow',
        u_created_at : Date.now(),
      };

      try {
        db.collection('users').doc(usernew).set(AddUsers);
        db.collection('userdata').doc(usernew).set(data);
        callback(true, AddUsers)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }else{
      callback(2)
    }
  }

  static NuevoContacto = async (user, IdUnico, s_phonenumber, s_nombrecontacto, callback) => {
    const userExistRef = db.collection('userdata').doc(user).collection('contactos').where('NumeroCelular', '==', s_phonenumber);
    const doc = await userExistRef.get();

    if (doc.empty) {
      const data = {
        Identificador : IdUnico,
        NumeroCelular: s_phonenumber,
        NombreCompleto: s_nombrecontacto,
        created_at: Date.now()
      };
      try {
        db.collection('userdata').doc(user).collection('contactos').add(data);
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }
    else {
      callback(2)
    }
  }

  static NuevaPlataforma = async (user, IdUnico, s_NombrePlataforma, s_MaximoPerfiles, callback) => {
    const userExistRef = db.collection('users').doc(user).collection('plataformas').where('NombrePlataforma', '==', s_NombrePlataforma);
    const doc = await userExistRef.get();

    if (doc.empty) {
      const data = {
        Identificador : IdUnico,
        NombrePlataforma: s_NombrePlataforma,
        MaximoPerfiles: Number(s_MaximoPerfiles),
        Logo : 'unknow',
        created_at: Date.now()
      };
      try {
        db.collection('users').doc(user).collection('plataformas').add(data);
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }
    else {
      callback(2)
    }
  }


  static NuevaCuenta = async (user, 
    s_CorreoCuenta, 
    s_PasswordCorreo, 
    s_PasswordCuenta,
    s_TipoCuentaCodigo,
    s_FechaInicioCuenta, 
    s_FechaFinCuenta,
    IdUnico, 
    callback) => {
    const CorreoExistRef = db.collection('userdata').doc(user).collection('cuentas').where('CorreoCuenta', '==', s_CorreoCuenta).where('TipoCuentaCodigo', '==', s_TipoCuentaCodigo);
    const doc = await CorreoExistRef.get();

    if (doc.empty) {
      const data = {
        Identificador: IdUnico,
        CorreoCuenta: s_CorreoCuenta,
        PasswordCorreo: s_PasswordCorreo,
        PasswordCuenta: s_PasswordCuenta,
        TipoCuentaCodigo: s_TipoCuentaCodigo,
        EstadoUso: 0,
        FechaInicioCuenta: new Date(s_FechaInicioCuenta).getTime(),
        FechaFinCuenta: new Date(s_FechaFinCuenta).getTime(),
        created_at: Date.now()
      };
      try {
        db.collection('userdata').doc(user).collection('cuentas').add(data);
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }
    else {
      callback(2)
    }
  }

  static NuevoPerfil = async (user, 
    s_ContactoPerfil,
    s_TipoCuentaCodigo,
    s_CorreoPerfil,
    s_CantidadPerfiles,
    s_DescripcionPerfil,
    s_CostoPerfil,
    s_FechaInicioPerfil,
    s_FechaFinPerfil,
    IdUnico,
    tipo,
    callback) => {
    const PerfilExistRef = db.collection('userdata').doc(user).collection('perfiles')
    .where('IdContacto', '==', s_ContactoPerfil)
    .where('TipoCuentaCodigo', '==', s_TipoCuentaCodigo)
    .where('CorreoPerfil', '==', s_CorreoPerfil);
    const doc = await PerfilExistRef.get();

    if (doc.empty) {
      const data = {
        Identificador: IdUnico,
        IdContacto : s_ContactoPerfil,
        TipoCuentaCodigo: s_TipoCuentaCodigo,
        CorreoPerfil: s_CorreoPerfil,
        CantidadPerfiles: s_CantidadPerfiles,
        DescripcionPerfil: s_DescripcionPerfil,
        CostoPerfil: Number(s_CostoPerfil),
        FechaInicioPerfil: new Date(s_FechaInicioPerfil).getTime(),
        FechaFinPerfil: new Date(s_FechaFinPerfil).getTime(),
        Tipo : tipo,
        created_at: Date.now()
      };
      try {
        this.ActualizarEstadoDeUsoSumar(user, s_CantidadPerfiles, s_CorreoPerfil, s_TipoCuentaCodigo, (response)=>{
          if(response){
            db.collection('userdata').doc(user).collection('perfiles').add(data);
            callback(true)
          }else{
            callback(false)
          }
        })
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }
    else {
      callback(2)
    }
  }



  static NuevoCombo = async (user, 
    s_ContactoPerfil,
    s_TipoCuentaCodigo,
    s_CorreoPerfil,
    s_CantidadPerfiles,
    s_DescripcionPerfil,
    s_CostoPerfil,
    s_FechaInicioPerfil,
    s_FechaFinPerfil,
    IdUnico,
    tipo,
    callback) => {
    const PerfilExistRef = db.collection('userdata').doc(user).collection('perfiles')
    .where('IdContacto', '==', s_ContactoPerfil)
    .where('TipoCuentaCodigo', '==', s_TipoCuentaCodigo)
    .where('CorreoPerfil', '==', s_CorreoPerfil);
    const doc = await PerfilExistRef.get();

    if (doc.empty) {
      const data = {
        Identificador: IdUnico,
        IdContacto : s_ContactoPerfil,
        TipoCuentaCodigo: s_TipoCuentaCodigo,
        CorreoPerfil: s_CorreoPerfil,
        CantidadPerfiles: s_CantidadPerfiles,
        DescripcionPerfil: s_DescripcionPerfil,
        CostoPerfil: Number(s_CostoPerfil),
        FechaInicioPerfil: new Date(s_FechaInicioPerfil).getTime(),
        FechaFinPerfil: new Date(s_FechaFinPerfil).getTime(),
        Tipo : tipo,
        created_at: Date.now()
      };
      try {
        this.ActualizarEstadoDeUsoSumar(user, s_CantidadPerfiles, s_CorreoPerfil, s_TipoCuentaCodigo, (response)=>{
          if(response){
            db.collection('userdata').doc(user).collection('perfiles').add(data);
            callback(true)
          }else{
            callback(false)
          }
        })
      } catch (error) {
        console.log(error)
        callback(false)
      }
    }
    else {
      callback(2)
    }
  }

  static NuevoCodigo = async (s_TiempoExpCodigo, IdUnico, s_KeyGenerado, callback) => {
    const NuevoCodigo = {
      TiempoExpiracion: s_TiempoExpCodigo,
      Codigo: s_KeyGenerado,
      EstadoUso: 0,
      UsuarioDelCodigo : '',
      Identificador : IdUnico,
      created_at: Date.now()
    };

    try {
      db.collection('codigos').add(NuevoCodigo);
      callback(true)
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }

  static NuevoMensajeServidor = async (user, TiempoExpiracion, IdUnico, s_TituloMensajeServidor, s_CategoriaMensajeServidor, s_MensajeServidor, callback) => {
    const NuevoMensajeServidor = {
      Identificador : IdUnico,
			Remitente: user,
      TituloMensajeServidor: s_TituloMensajeServidor,
			CategoriaMensajeServidor : s_CategoriaMensajeServidor,
			MensajeServidor : s_MensajeServidor,
      TiempoExpiracion : TiempoExpiracion,
      created_at: Date.now()
    };

    try {
      db.collection('alertaservidor').add(NuevoMensajeServidor);
      callback(true)
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }



  static GetUserChats = async (user, callback) => {
    /**** Actualiza el chat en tiempo real segun se agregan a la DB */
    /*try {
      db.collection('userdata')
      .doc(user)
      .collection('chats')
      .onSnapshot(function(querySnapshot){
        querySnapshot.forEach(function (doc) {
          return callback(doc.data(), true)
        });
        
      });
    } catch (error) {
      return callback([], false)
    }*/
    /**** END Actualiza el chat en tiempo real segun se agregan a la DB */
    /*** Obtener datos una sola vez */
    //const u_chats = []
    await db.collection("userdata")
      .doc(user)
      .collection('chats')
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
    /*** End Obtener datos una sola vez */
  }

  static GetUsers = async (callback) => {
    let registeredUsers = {}
    /*** Obtener usuarios */
    try {
      await db.collection("users").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          const users = {
            id: doc.data().u_id,
            user: doc.data().u_user,
            socketId: doc.data().u_socketid,
            autority: doc.data().u_autority,
            businessName: doc.data().u_businessName,
            state: doc.data().u_state,

          }
          registeredUsers = addUser(registeredUsers, users)

        });
        return callback(registeredUsers, true)
      });
    } catch (error) {
      return callback(registeredUsers, false)
    }
    /*** End Obtener usuarios una sola vez */
  }

  static VerificaSiKeyExiste = async (s_KeyActivarCuenta, callback) => {
    const KeyExisteRef = db.collection('codigos')
    .where('Codigo', '==', s_KeyActivarCuenta)
    .where('EstadoUso', '==', 0);
    const doc = await KeyExisteRef.get()

    if(!doc.empty){
      KeyExisteRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const Expiracion = {
            TiempoExpiracion: doc.data().TiempoExpiracion,
          }
          return callback(Expiracion, true)
        });
      })
    }else{
      callback([], false)
    }
    
  }


  static VerificarSiExistePerfil = (user, s_CurrentPlataformasCombo, p_listacontactos, s_ContactoCombo, callback) => {
      
      const xD = s_CurrentPlataformasCombo.filter(async(item)=>{
        var contacto = ""

        p_listacontactos.find((c__)=>{
          if(c__.NombreCompleto === s_ContactoCombo){
            contacto = c__.Identificador
          }
        })
    //DatoExistente.push(newdata.map((i)=>{ return i}))
    const KeyExisteRef = db.collection('userdata').doc(user).collection('perfiles')
    .where('TipoCuentaCodigo', '==', item.TipoCuentaCodigo)
    .where('IdContacto', '==', contacto)
    .where('CorreoPerfil', '==', item.CorreoCuenta);
    const doc = await KeyExisteRef.get()

    if(!doc.empty){
      return item
    }else return false
      })

    
      callback(xD)
    
  }


  static VerificaSiContactoEstaEnUso = async (user, IdContacto, callback) => {
    const ContactoExisteRef = db.collection('userdata').doc(user).collection('perfiles')
    .where('IdContacto', '==', IdContacto)
    const doc = await ContactoExisteRef.get();
    if(!doc.empty){
      callback(true)
    }else{
      callback(false)
    }
    
  }


  static VerificaSiCredencialesCorrecto = async (s_UsuarioSesion, s_PasswordSesion, callback) => {
    const KeyExisteRef = db.collection('users')
    .where('u_user', '==', s_UsuarioSesion)
    .where('u_password', '==', s_PasswordSesion);
    const doc = await KeyExisteRef.get();
    if(!doc.empty){
      callback(true)
    }else{
      callback(false)
    }
    
  }


  static GetMyContactsRegistered = async(user, callback) => {
    /*** Obtener contactos registrados */
    try {
      await db.collection("userdata")
      .doc(user)
      .collection("contactos")
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const contacts = {
            code  : doc.data().code,
            created_at : doc.data().created_at,
            name : doc.data().name,
            number : doc.data().number,
          }
          return callback(contacts, true)
        });
      });
    } catch (error) {
      return callback({}, false)
    }
    /*** End Obtener contactos registrados*/

  }

  static GetUsersByID = async (user, callback) => {

    /*** Obtener usuarios */
    const GetUsersByIDRef = db.collection('users').doc(user);
    const doc = await GetUsersByIDRef.get();
    if (!doc.exists) {
      const usersbyid = {}
      return callback(usersbyid)
    } else {

      db.collection("users").where("u_user", "==", user)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const usersbyid = {
              id: doc.data().u_id,
              user: doc.data().u_user,
              socketId: doc.data().u_socketid,
              autority: doc.data().u_autority,
              businessName: doc.data().u_businessName,
              state: doc.data().u_state,
              password: doc.data().u_password,
              expiration : doc.data().u_expiration,
              created_at : doc.data().u_created_at,
              image : doc.data().u_image,
            }
            return callback(usersbyid)
          });

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }


    /*** End Obtener usuarios una sola vez */
  }

  static GetUserSocketID = async(user, callback)=>{
    const GetUsersByIDRef = db.collection('users').doc(user);
    const doc = await GetUsersByIDRef.get();
    if (!doc.exists) {
      const usersbyid = {}
      return callback(usersbyid)
    } else {
      db.collection("users").where("u_user", "==", user)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const usersbyid = doc.data().u_socketid
            return callback(usersbyid)
          });

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  static GetUserLastSeen = async (user, callback) => {

    /*** Obtener ultima vez de usuario */
    var docRef = await db.collection("userdata").doc(user);
    let UserLastTime = ""
    docRef.get().then((doc) => {
      if (doc.exists) {
        UserLastTime = setUnixToTimeFormat("LastSeen", doc.data().u_last_connection)
      } else {
        console.log("No such document!");
      }
      /**** Retorna ultima conexion */
      return callback(UserLastTime)
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
    /*** End Obtener ultima vez de usuario */
  }

  static ListarUsuarioInfo = async (user, callback) => {
    var docRef = await db.collection("userdata").doc(user);
    docRef.get().then((doc) => {
      if(doc.exists) {
        return callback(doc.data())
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
    
  }

  static ListarPerfilInfo = async (user, callback) => {
    var docRef = await db.collection("user").doc(user);
    docRef.get().then((doc) => {
      if(doc.exists) {
        return callback(doc.data())
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
    
  }


  static ListarMisContactos = async (user, callback) => {
    await db.collection("userdata")
      .doc(user)
      .collection('contactos')
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarMisCuentas = async (user, callback) => {
    await db.collection("userdata")
      .doc(user)
      .collection('cuentas')
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarPlataformas = async (user, callback) => {
    await db.collection("users")
      .doc(user)
      .collection('plataformas')
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarMisPerfiles = async (user, callback) => {
    await db.collection("userdata")
      .doc(user)
      .collection('perfiles')
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarCodigos = async (callback) => {
    await db.collection("codigos")
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarMensajesServidor = async (callback) => {
    var Hoy = Date.now()
    await db.collection("alertaservidor").where('TiempoExpiracion', '>' , Hoy)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  static ListarUsuarios = async (callback) => {
    await db.collection("users")
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          return callback(doc.data())
        });
      });
  }

  /***** UPDATE FUNCTIONS */

  static UpdateUserInfo = async (type, user, data) => {
    if (type === 'update_nombre') {
      try {
        await db.collection('userdata').doc(user).update({
          u_nickname: data
        })
      } catch (error) {
        console.log(error)
      }
    } else if (type === 'update_estado') {
      try {
        await db.collection('userdata').doc(user).update({
          u_state: data
        })
      } catch (error) {
        console.log(error)
      }
    } else if (type === 'update_last_connection') {
      try {
        await db.collection('userdata').doc(user).update({
          u_last_connection: data
        })
      } catch (error) {
        console.log(error)
      }
    } else if (type === 'update_image_perfil') {
      try {
        await db.collection('users').doc(user).update({
          u_image: data
        })
      } catch (error) {
        console.log(error)
      }
    }

  }

  static ActualizarContacto = async (user, Identificador, number, name, callback) => {

    const docRef = db.collection('userdata').doc(user).collection('contactos').where('Identificador', '==', Identificador)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('userdata').doc(user).collection('contactos').doc(doc.id)
      try {
        docref2.update({
          NumeroCelular: number,
          NombreCompleto: name
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static ActualizarCuenta = async (user, 
    s_IdentificadorEditar,
    s_FechaInicioCuentaEditar,
    s_FechaFinCuentaEditar,
    s_PasswordCorreoEditar,
    s_PasswordCuentaEditar, callback) => {

    const docRef = db.collection('userdata').doc(user).collection('cuentas').where('Identificador', '==', s_IdentificadorEditar)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('userdata').doc(user).collection('cuentas').doc(doc.id)
      try {
        docref2.update({
          PasswordCorreo: s_PasswordCorreoEditar,
          PasswordCuenta: s_PasswordCuentaEditar,
          FechaInicioCuenta : new Date(s_FechaInicioCuentaEditar).getTime(),
          FechaFinCuenta : new Date(s_FechaFinCuentaEditar).getTime()
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static ActualizarPerfil = async (user, 
    s_IdentificadorPerfilEditar,
    s_DescripcionPerfilEditar,
    s_CostoPerfilEditar,
    s_FechaInicioPerfilEditar,
    s_FechaFinPerfilEditar, callback) => {

    const docRef = db.collection('userdata').doc(user).collection('perfiles').where('Identificador', '==', s_IdentificadorPerfilEditar)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('userdata').doc(user).collection('perfiles').doc(doc.id)
      try {
        docref2.update({
          DescripcionPerfil: s_DescripcionPerfilEditar,
          CostoPerfil: s_CostoPerfilEditar,
          FechaInicioPerfil : new Date(s_FechaInicioPerfilEditar).getTime(),
          FechaFinPerfil : new Date(s_FechaFinPerfilEditar).getTime()
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static ActualizarUsuario = async (
    s_IdUsuarioEditar,
    s_PasswordUsuarioEditar,
    s_AutorityUsuarioEditar,
    s_BusinessNameUsuarioEditar,
    s_ExpirationUsuarioEditar, callback) => {

    const docRef = db.collection('users').where('u_id', '==', s_IdUsuarioEditar)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(doc.id)
      try {
        docref2.update({
          u_id: s_IdUsuarioEditar,
          u_password: s_PasswordUsuarioEditar,
          u_autority: s_AutorityUsuarioEditar,
          u_businessName: s_BusinessNameUsuarioEditar,
          u_expiration : new Date(s_ExpirationUsuarioEditar).getTime()
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static BloquearDesbloquearUsuario = async (
    IdUsuario, TipoAccion,  callback) => {

    const docRef = db.collection('users').where('u_id', '==', IdUsuario)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(doc.id)
      try {
        docref2.update({
          u_state : TipoAccion
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }

  static DesbloquearUsuario = async (
    IdUsuario, callback) => {

    const docRef = db.collection('users').where('u_id', '==', IdUsuario)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(doc.id)
      try {
        docref2.update({
          u_state : 1
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static ActualizarEstadoDeUsoSumar = async (user, s_CantidadPerfiles, s_CorreoPerfil, s_TipoCuentaCodigo, callback) => {
        const docRef = db.collection('userdata').doc(user).collection('cuentas')
        .where('CorreoCuenta', '==', s_CorreoPerfil)
        .where('TipoCuentaCodigo', '==', s_TipoCuentaCodigo)
        const snapshot = await docRef.get()
        snapshot.forEach(doc => {
         const docref2 = db.collection('userdata').doc(user).collection('cuentas').doc(doc.id)
        try {
          var NewEstadoUso = Number(doc.data().EstadoUso) + Number(s_CantidadPerfiles)
        docref2.update({
          EstadoUso : NewEstadoUso,
        })
          callback(true)
        } catch (error) {
          callback(false)
        }
        });
  }


  static ActualizarEstadoDeUsoRestar = async (user, CantidadPerfiles, CorreoPerfil, TipoCuentaCodigo, callback) => {
    const docRef = db.collection('userdata').doc(user).collection('cuentas')
    .where('CorreoCuenta', '==', CorreoPerfil)
    .where('TipoCuentaCodigo', '==', TipoCuentaCodigo)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
     const docref2 = db.collection('userdata').doc(user).collection('cuentas').doc(doc.id)
    try {
      var NewEstadoUso = Number(doc.data().EstadoUso) - Number(CantidadPerfiles)
    docref2.update({
      EstadoUso : NewEstadoUso,
    })
      callback(true)
    } catch (error) {
      callback(false)
    }
    });
  }


  static ActualizarCodigoUsado = async (user, s_KeyActivarCuenta, callback) => {

    const docRef = db.collection('codigos').where('Codigo', '==', s_KeyActivarCuenta)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('codigos').doc(doc.id)
      try {
        docref2.update({
          EstadoUso : 1,
          UsuarioDelCodigo : user
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(false)
      }
    });
  }


  static ActualizarPlataformas = async (user, 
    s_Identificador,
    s_NombrePlataforma, 
    s_MaxPerfilesPlataforma, callback) => {

    const docRef = db.collection('users').doc(user).collection('plataformas').where('Identificador', '==', s_Identificador)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(user).collection('plataformas').doc(doc.id)
      try {
        docref2.update({
        NombrePlataforma: s_NombrePlataforma,
		    MaximoPerfiles: Number(s_MaxPerfilesPlataforma),
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(error)
      }
    });
  }


  static ActualizarPasswordPerfilInfo = async (user, 
    s_PasswordPerfilInfo, callback) => {

    const docRef = db.collection('users').where('u_user', '==', user)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(doc.id)
      try {
        docref2.update({
        u_password: s_PasswordPerfilInfo
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(error)
      }
    });
  }

  static ActualizarBusinessPerfilInfo = async (user, 
    s_BusinessNamePerfilInfo, callback) => {

    const docRef = db.collection('users').where('u_user', '==', user)
    const snapshot = await docRef.get()
    snapshot.forEach(doc => {
      const docref2 = db.collection('users').doc(doc.id)
      try {
        docref2.update({
        u_businessName: s_BusinessNamePerfilInfo
        })
        callback(true)
      } catch (error) {
        console.log(error)
        callback(error)
      }
    });
  }


  /****** END UPDATE FUNCTIONS */

  /****** DELETE FUNCTIONS */

  static BorraContacto = async (user, Identificador, callback) => {
    try {
      const docRef = db.collection('userdata').doc(user).collection('contactos').where('Identificador', '==', Identificador)
      const snapshot = await docRef.get()
      snapshot.forEach(doc => {
        db.collection('userdata').doc(user).collection('contactos').doc(doc.id).delete();
        callback(true)
      });
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }


  static BorraCuenta = async (user, Identificador, callback) => {
    try {
      const docRef = db.collection('userdata').doc(user).collection('cuentas').where('Identificador', '==', Identificador)
      const snapshot = await docRef.get()
      snapshot.forEach(doc => {
        db.collection('userdata').doc(user).collection('cuentas').doc(doc.id).delete();
        callback(true)
      });
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }


  static BorraPerfil = async (user, Identificador, CantidadPerfiles, CorreoPerfil, TipoCuentaCodigo, callback) => {
   
      const docRef = db.collection('userdata').doc(user).collection('perfiles').where('Identificador', '==', Identificador)
      const snapshot = await docRef.get()
      this.ActualizarEstadoDeUsoRestar(user, CantidadPerfiles, CorreoPerfil, TipoCuentaCodigo, (response)=>{
        try{
          if(response){
            snapshot.forEach(doc => {
                db.collection('userdata').doc(user).collection('perfiles').doc(doc.id).delete();
            })}
            callback(true)
        }catch (error){
          callback(error)
        }
      });
  }

  static BorrarKey = async (Identificador, callback) => {
    try {
      const docRef = db.collection('codigos').where('Identificador', '==', Identificador)
      const snapshot = await docRef.get()
      snapshot.forEach(doc => {
        db.collection('codigos').doc(doc.id).delete();
        callback(true)
      });
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }

  static BorrarMensajeServidor = async (Identificador, callback) => {
    try {
      const docRef = db.collection('alertaservidor').where('Identificador', '==', Identificador)
      const snapshot = await docRef.get()
      snapshot.forEach(doc => {
        db.collection('alertaservidor').doc(doc.id).delete();
        callback(true)
      });
    } catch (error) {
      console.log(error)
      callback(false)
    }
  }

  /****** END DELETE FUNCTIONS */
}

function addUser(userList, user) {
  let newList = Object.assign({}, userList)
  newList[user.user] = user
  return newList
}

