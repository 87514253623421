import { IconButton } from '@mui/material';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { IoMdContact } from 'react-icons/io';

import { MdAccountCircle, MdDelete, MdInfo, MdModeEdit, MdSupervisorAccount, MdTimer, MdVpnKey } from 'react-icons/md';
import { setUnixToTimeFormat } from '../../../Funciones';


export default function ListaMisCodigos(props) {

    const {
        listaKeys,
        p_HandleEliminarKey,
    } = props
    return (
        <div className="container_info_contactos">
{listaKeys.length > 0 ? (
            <div className="group_items">
             
                    {
                        listaKeys.sort((a,b) => a.EstadoUso - b.EstadoUso)
                        .map((key, index) => {
                            var EstadoUso = key.EstadoUso
                            var Identificador = key.Identificador
                            var Codigo = key.Codigo
                            var TiempoExpiracion = key.TiempoExpiracion
                            var UsuarioDelCodigo = key.UsuarioDelCodigo
                            var created_at = key.created_at
                            return (
                                <div
                                    key={Identificador}
                                    className="item_container"
                                >
                                <div className="item-block-cuentas">
                                    <div className='icon_left'>
                                            <MdVpnKey size={45}/>
                                    </div>

                                    <div className='item-left-keys'>
                                        <div className="item_list_cuentas_3">
                                            <div className="item_list-cuentas-key">{Codigo}</div>
                                            <div className="item_list-cuentas-tipo">{UsuarioDelCodigo}</div>
                                        </div>

                                        <div className="item_list_cuentas_4">
                                            <div className="item_list-cuentas-fechaexp">
                                                <div className='icon-adorno'>
                                                <div><MdTimer size="24" /></div>
                                                <div>{TiempoExpiracion} días</div>
                                                </div>
                                                
                                                </div>
                                        </div>

                                        <div className="item_list_cuentas_3">
                                            <div className="item_list-cuentas-created">{setUnixToTimeFormat('info_contacto', created_at)}</div>
                                            <div className="item_list-cuentas-estadouso-2">
                                            {EstadoUso === 1 ? <div className='cuenta-full'>Usado <MdVpnKey style={{marginLeft: 4}} size={20}/></div>:
                                            <div className='cuenta-libre'>Disponible <MdVpnKey style={{marginLeft: 4}} size={20}/></div>}
                                                </div>
                                        </div>
                                    </div>
                                        
                                    <div className='icon_right_keys'>
                                        <IconButton onClick={() => p_HandleEliminarKey(index, EstadoUso, Identificador)} color='primary' data-title='Eliminar'>
                                            <MdDelete
                                                color="#C64141"
                                                size={32}
                                                style={{cursor:'pointer'}}
                                                title='Eliminar'
                                            />
                                        </IconButton>
                                    </div>
                                </div>

                                </div>

                            )
                        })
                    }
            </div>
):(<div className='sin_datos'><div className='sin_datos_left'><MdInfo color='#F1C40F' size={64}/></div><div className='sin_datos_right'> No se ah encontrado ningún dato registrado!</div></div>)
}
        </div>
    )
}