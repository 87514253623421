import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoMdAddCircle, IoMdLogOut } from 'react-icons/io';
import { IoBagAdd, IoBagCheckSharp, IoBagHandle, IoMegaphone, IoTv, IoWarning } from 'react-icons/io5';
import { FcHighPriority, FcOk } from "react-icons/fc";
import { MdAccountBalance, MdAccountCircle, MdAddBox, MdAssignmentTurnedIn, MdBackup, MdFiberNew, MdFiberPin, MdGroup, MdHome, MdMenu, MdNoteAdd, MdPersonAdd, MdSettings, MdSettingsApplications, MdSettingsOverscan, MdSupervisorAccount, MdTimer, MdViewList, MdVpnKey, MdWork } from 'react-icons/md';
import { CalcularExpiracionUser } from '../Funciones';
import { FaShopify } from 'react-icons/fa';

export default function MainLeft(props) {

    const {
        socket,
        user,
        esADMIN,
        NuevoContacto, 
        logout,
        MisContactos,
        p_Portal,
        p_Mispinesnetflix, 
        p_Nuevopinnetflix, 
        p_NuevaCuenta,
        p_MisCuentas,
        p_NuevoPerfil,
        p_MisPerfiles,
        p_Backup,
        p_NuevoCodigo,
        p_MisUsuarios,
        p_AbrirPerfilInfo,
        p_MisKeyDeActivacion,
        p_Plataformas,
        p_usuarioexpirado,
        p_MensajeDelServidor,
        p_Reportar,
        s_showmenu,
        p_NuevoCombo
    } = props

    const [curTime, SetCurTime] = useState(null)

    useEffect(async () => {
        setInterval(function(){
            SetCurTime(new  Date().toLocaleString());
        }.bind(this), 1000);
     }, [])

    const handlenuevocontacto = (event) => {
        event.preventDefault();
        NuevoContacto(true)
    }

    const handlelogout = (event) => {
        event.preventDefault();
        logout(true)
    }

    const handlemiscontactos = (event) => {
        event.preventDefault();
        MisContactos(true)
    }

    const handlemispinesnetflix = (event) => {
        event.preventDefault();
        p_Mispinesnetflix(true)
    }

    const handlenuevopinnetflix = (event) => {
        event.preventDefault();
        p_Nuevopinnetflix(true)
    }


    const handlenuevacuenta = (event) => {
        event.preventDefault();
        p_NuevaCuenta(true)
    }

    const handlemiscuentas = (event) => {
        event.preventDefault();
        p_MisCuentas(true)
    }


    const handlenuevoperfil = (event) => {
        event.preventDefault();
        p_NuevoPerfil(true)
    }

    const handlemisperfiles = (event) => {
        event.preventDefault();
        p_MisPerfiles(true)
    }

    const handlebackup = (event) => {
        event.preventDefault();
        p_Backup(true)
    }

    const handlenuevocodigo = (event) => {
        event.preventDefault();
        p_NuevoCodigo(true)
    }

    const handlemisusuarios = (event) => {
        event.preventDefault();
        p_MisUsuarios(true)
    }

    const handlemiskeydeactivacion = (event) => {
        event.preventDefault();
        p_MisKeyDeActivacion(true)
    }

    const handleabrirperfilninfo = (event) => {
        event.preventDefault();
        p_AbrirPerfilInfo(true)
    }

    const handleplataformas = (event) => {
        event.preventDefault();
        p_Plataformas(true)
    }

    const handleportal = (event) => {
        event.preventDefault();
        p_Portal(true)
    }

    const handlemensajedelservidor = (event) => {
        event.preventDefault();
        p_MensajeDelServidor(true)
    }

    const handlereportar = (event) => {
        event.preventDefault();
        p_Reportar(true)
    }

    const handlenuevocombo = (event) => {
        event.preventDefault();
        p_NuevoCombo(true)
    } 

	return (
		<>
		 <div id={s_showmenu ? "menu-hide":"menu"}>
			<div className='head-menu'>
			<div className='empresa-title-name'><div className='logo-image'><img className='img-logo-image' src={`/images_perfil/${user.image}.jpg`}></img></div>{user.businessName}</div>
			<div className='logout'><IconButton  style={{marginLeft: 10, marginTop: 5}} color='primary' onClick={handlelogout} data-title={s_showmenu ? '':'Cerrar sesión'}><IoMdLogOut size={26} color={'#EC7063'} style={{cursor:'pointer'}}/></IconButton></div>
			</div>
    {
        s_showmenu ? 
    <>
    <ul>
        
        <li class="has-sub">
            <a title="" href="#" onClick={handleportal}>
            <i><MdHome size={26} style={{marginTop:5}}/></i>
            
            <span className='text-span-2'></span>
            </a>
        </li>
		<li class="has-sub"><a title="" href="#"><i><MdSupervisorAccount size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevocontacto}><a title="" href="#"><i><MdPersonAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
				<li class="has-sub" onClick={handlemiscontactos}><a title="" href="#"><i><MdGroup size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
             </ul>
        </li>

		<li class="has-sub"><a title="" href="#"><i><MdFiberPin size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevopinnetflix}><a title="" href="#"><i><MdFiberNew size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
				<li class="has-sub" onClick={handlemispinesnetflix}><a title="" href="#"><i><MdWork size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
             </ul>
        </li>

		<li class="has-sub"><a title="" href="#"><i><MdAssignmentTurnedIn size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevacuenta}><a title="" href="#"><i><MdAddBox size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
				<li class="has-sub" onClick={handlemiscuentas}><a title="" href="#"><i><MdAccountBalance size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
             </ul>
        </li>
        
		<li class="has-sub"><a title="" href="#"><i><IoTv size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevoperfil}><a title="" href="#"><i><MdNoteAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
				<li class="has-sub" onClick={handlemisperfiles}><a title="" href="#"><i><MdViewList size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
                <li class="has-sub" onClick={handlenuevocombo}><a title="" href="#"><i><FaShopify size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
             </ul>
        </li>
        
            <li class="has-sub"><a title="" href="#"><i><MdSettings size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
                {
                  esADMIN && <li class="has-sub" onClick={handlebackup}><a title="" href="#"><i><MdBackup size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
                }
                <li class="has-sub" onClick={handleplataformas}><a title="" href="#"><i><MdSettingsOverscan size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
				{
                  esADMIN && <li class="has-sub" onClick={handlemisusuarios}><a title="" href="#"><i><MdSupervisorAccount size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
                }

                {
                  esADMIN && <li class="has-sub" onClick={handlemensajedelservidor}><a title="" href="#"><i><IoMegaphone size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
                }
				
				
            </ul>
        </li>
        {
                   esADMIN &&
        <li class="has-sub"><a title="" href="#"><i><IoBagCheckSharp size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            <ul>
            <li className="has-sub2" onClick={handlenuevocodigo}>
                    <a title="" href="#"><i><IoBagAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
            <li className="has-sub2" onClick={handlemiskeydeactivacion}>
                    <a title="" href="#"><i><IoBagHandle size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a>
            </li>
            
            </ul>
        </li>
        }
		
        <li class="has-sub" onClick={handleabrirperfilninfo}><a title="" href="#"><i><MdAccountCircle size={26} style={{marginTop:5}}/></i><span className='text-span-2'></span></a></li>
        
        <li class="has-sub" onClick={handlereportar}><a title="" href="#"><i><IoWarning color='#EC7063' size={26} style={{marginTop:5}}/></i><span style={{color:'#EC7063'}} className='text-span-2'></span></a></li>
    </ul></>:<ul className='menu'>
        <li class="has-sub">
            <a title="" href="#" onClick={handleportal}>
            <i><MdHome size={26} style={{marginTop:5}}/></i>
            
            <span className='text-span-2'>Inicio</span>
            </a>
        </li>
		<li class="has-sub"><a title="" href="#"><i><MdSupervisorAccount size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Contactos</span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevocontacto}><a title="" href="#"><i><MdPersonAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Nuevo Contacto</span></a></li>
				<li class="has-sub" onClick={handlemiscontactos}><a title="" href="#"><i><MdGroup size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis Contactos</span></a></li>
             </ul>
        </li>

		<li class="has-sub"><a title="" href="#"><i><MdFiberPin size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Pines netflix</span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevopinnetflix}><a title="" href="#"><i><MdFiberNew size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Nuevo pin</span></a></li>
				<li class="has-sub" onClick={handlemispinesnetflix}><a title="" href="#"><i><MdWork size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis pines</span></a></li>
             </ul>
        </li>

		<li class="has-sub"><a title="" href="#"><i><MdAssignmentTurnedIn size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Cuentas</span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevacuenta}><a title="" href="#"><i><MdAddBox size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Nueva cuenta</span></a></li>
				<li class="has-sub" onClick={handlemiscuentas}><a title="" href="#"><i><MdAccountBalance size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis cuentas</span></a></li>
             </ul>
        </li>
        
		<li class="has-sub"><a title="" href="#"><i><IoTv size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Perfiles</span></a>
            <ul>
                <li class="has-sub" onClick={handlenuevoperfil}><a title="" href="#"><i><MdNoteAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Nuevo perfil</span></a></li>
				<li class="has-sub" onClick={handlemisperfiles}><a title="" href="#"><i><MdViewList size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis perfiles</span></a></li>
                <li class="has-sub" onClick={handlenuevocombo}><a title="" href="#"><i><FaShopify size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Crear combo</span></a></li>
             </ul>
        </li>
        
            <li class="has-sub"><a title="" href="#"><i><MdSettings size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Configuración</span></a>
            <ul>
                {
                  esADMIN && <li class="has-sub" onClick={handlebackup}><a title="" href="#"><i><MdBackup size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Crear backup</span></a></li>
                }
                <li class="has-sub" onClick={handleplataformas}><a title="" href="#"><i><MdSettingsOverscan size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Plataformas</span></a></li>
				{
                  esADMIN && <li class="has-sub" onClick={handlemisusuarios}><a title="" href="#"><i><MdSupervisorAccount size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis usuarios</span></a></li>
                }

                {
                  esADMIN && <li class="has-sub" onClick={handlemensajedelservidor}><a title="" href="#"><i><IoMegaphone size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Crear mensaje del servidor</span></a></li>
                }
				
				
            </ul>
        </li>
        {
                   esADMIN &&
        <li class="has-sub"><a title="" href="#"><i><IoBagCheckSharp size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Codigos de activación</span></a>
            <ul>
            <li className="has-sub2" onClick={handlenuevocodigo}>
                    <a title="" href="#"><i><IoBagAdd size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Crear código de activación</span></a></li>
            <li className="has-sub2" onClick={handlemiskeydeactivacion}>
                    <a title="" href="#"><i><IoBagHandle size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mis códigos de activación</span></a>
            </li>
            
            </ul>
        </li>
        }
		
        <li class="has-sub" onClick={handleabrirperfilninfo}><a title="" href="#"><i><MdAccountCircle size={26} style={{marginTop:5}}/></i><span className='text-span-2'>Mi perfil</span></a></li>
        
        <li class="has-sub" onClick={handlereportar}><a title="" href="#"><i><IoWarning color='#EC7063' size={26} style={{marginTop:5}}/></i><span style={{color:'#EC7063'}} className='text-span-2'>Reportar problema</span></a></li>
    </ul>
    }
    {
        s_showmenu ? <></>:<div className='user-expiration'>
        <div className='icon-adorno-exp'>
           <div><MdTimer size="24" /></div>
           <div className='text-exp'>{CalcularExpiracionUser(user.expiration) === 'Caducado' ? p_usuarioexpirado(true):CalcularExpiracionUser(user.expiration)}</div>
           <div className='server-status'>{socket.connected ? <><div>Servidor en linea </div> <FcOk style={{marginLeft:5}} size={26}/></>:<><div>Servidor fuera de linea </div> <FcHighPriority style={{marginLeft:5}} size={26}/></>}</div>
        </div>

</div>
    }

</div>


		</>
	)
}