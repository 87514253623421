import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAQDjX9HKluZg-ydyk9hCTRTuV8i5gY5lU",
    authDomain: "hola-app-3093b.firebaseapp.com",
    projectId: "hola-app-3093b",
    storageBucket: "hola-app-3093b.appspot.com",
    messagingSenderId: "459298176333",
    appId: "1:459298176333:web:485ffc9360030e91c64b04",
    measurementId: "G-MT62B1W7HD"
  };

firebase.initializeApp(firebaseConfig);

export {firebase};